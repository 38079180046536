import { ActionResult } from './Common';
import { UserDoc, SchoolClaims, SchoolClaimsDictionary } from '../types';
import { LocationChangeAction } from 'connected-react-router';
import _ from 'lodash';

export const USER_SIGNED_IN = 'USER_SIGNED_IN';
export type USER_SIGNED_IN = typeof USER_SIGNED_IN;

export const USER_SIGNED_OUT = 'USER_SIGNED_OUT';
export type USER_SIGNED_OUT = typeof USER_SIGNED_OUT;

export const USER_TOKEN_UPDATED = 'USER_TOKEN_UPDATED';
export type USER_TOKEN_UPDATED = typeof USER_TOKEN_UPDATED;

export const USER_DOC_UPDATED = 'USER_DOC_UPDATED';
export type USER_DOC_UPDATED = typeof USER_DOC_UPDATED;

export const SCHOOL_PERMISSIONS_UPDATED = 'SCHOOL_PERMISSIONS_UPDATED';
export type SCHOOL_PERMISSIONS_UPDATED = typeof SCHOOL_PERMISSIONS_UPDATED;

export const SIGN_OUT = 'SIGN_OUT';
export type SIGN_OUT = typeof SIGN_OUT;

export interface UserSignedIn {
    type: USER_SIGNED_IN;
    value: {
        user: firebase.User;
        providerId: string;
    };
}

export interface UserSignedOut {
    type: USER_SIGNED_OUT;
    value: null;
}

export interface UserTokenUpdated {
    type: USER_TOKEN_UPDATED;
    value: firebase.auth.IdTokenResult;
}

export interface UserDocUpdated {
    type: USER_DOC_UPDATED;
    value: UserDoc;
}

export interface SchoolPermissionsUpdated {
    type: SCHOOL_PERMISSIONS_UPDATED;
    value: SchoolClaimsDictionary;
}

export interface SignOut {
    type: SIGN_OUT;
    value: {};
}

export type AuthAction =
    | LocationChangeAction
    | SignOut
    | UserSignedIn
    | UserSignedOut
    | UserTokenUpdated
    | UserDocUpdated
    | SchoolPermissionsUpdated
    | ActionResult;

export function userSignedIn(user: firebase.User, providerId: string): UserSignedIn {
    return {
        type: USER_SIGNED_IN,
        value: {
            user,
            providerId
        }
    };
}

export function userSignedOut(): UserSignedOut {
    return {
        type: USER_SIGNED_OUT,
        value: null
    };
}

export function userTokenUpdated(token: firebase.auth.IdTokenResult): UserTokenUpdated {
    return {
        type: USER_TOKEN_UPDATED,
        value: token
    };
}

export function schoolPermissionsUpdated(docs: firebase.firestore.DocumentSnapshot[]): SchoolPermissionsUpdated {
    return {
        type: SCHOOL_PERMISSIONS_UPDATED,
        value: _.mapValues(_.keyBy(docs, doc => doc.id), doc => doc.data() as SchoolClaims)
    };
}

export function userDocUpdated(snapshot: firebase.firestore.DocumentSnapshot): UserDocUpdated {
    return {
        type: USER_DOC_UPDATED,
        value: snapshot.data() as UserDoc
    };
}

export const signOut = (): SignOut => ({ type: SIGN_OUT, value: {} });
