/// <reference path="../../node_modules/firebase/index.d.ts" />

import { SearchSchoolDocument, SearchSchoolDictionary } from './SearchTypes';
import {
    SchoolBookingForms,
    UserBookingDictionary,
    BookingItineraryDictionary,
    NewBookingForm,
    SchoolSettingsDictionary
} from './BookingTypes';
import {
    SchoolUserForm,
    School,
    SchoolSpotsDictionary,
    UserDoc,
    SchoolSessionsDictionary,
    SchoolClaimsDictionary,
    ActivityDictionary,
    UserSessionDictionary,
    SchoolUsersDictionary,
    Instructor
} from '.';
import { CreateBookingResult, GetBookingResult, GetInstructorProfileResult } from '../api/Commands';
import { SpotForm } from './SchoolSpotTypes';
import { DestinationDictionary } from './DestinationTypes';
import { RouterState } from 'connected-react-router';
import { LoadBooking } from '../actions/BookingActions';
import { SignInEpicName, SignInTabName } from './SignInTypes';
import { GetInstructorProfile } from '../actions/InstructorActions';
import { InstructorKudos, InstructorExperienceToVerifyDictionary, InstructorProfile, InstructorActivateAccountResult, InstructorSession } from './InstructorTypes';

export interface SignInPageState {
    email: string;
    password: string;
    name: string;
    resetPassword: boolean;
    emailMessage: string;
    socialMessage: string;
    passwordInvalid: boolean;
    createAccountButtonEnabled: boolean;
    signInButtonEnabled: boolean;
    socialSigninButtonsEnabled: boolean;
    magicLinkAndResetButtonsEnabled: boolean;
    passwordVisible: boolean;
    currentTab: SignInTabName;
    epicInProgress: SignInEpicName | null;
}

export interface SearchStoreState {
    text: string;
    results: SearchSchoolDocument[];
    schools: SearchSchoolDictionary;
}

export interface SchoolStoreState {
    loadSchoolId: string;
    loadError: Error | null;
    loadedSchools: SearchSchoolDictionary;
}

export interface HasUser {
    user: firebase.UserInfo | null;
}

export interface HasUserDoc {
    userDoc?: UserDoc;
}

export interface AuthStoreState extends HasUser {
    tokenResult: firebase.auth.IdTokenResult | null;
    schools: SchoolClaimsDictionary;
    userSessionId: string | null;
    userDoc: UserDoc | null;

    // if on a /manage/school/:schoolId part of the site this will reflect the current schoolId otherwise null
    currentlyManagingSchoolId: string | null;

    // firebase module has loaded and authentication has been attempted
    // this is not the same as being signed in as the authentication may fail
    // this is useful to know whether permissions can be evaluated yet, if false
    // a component might prefer to show 'loading' rather than 'forbidden'
    firebaseAuthLoaded: boolean;

    // true if user is signed in and their token and schools permissions values have been loaded
    permissionsLoaded: boolean;

    // used privately by AuthReducer
    _schoolIdFromUrl: string | null;
    _schoolPermsLoaded: boolean;
}

export const spreadUser = <T extends object>(mapProps: (state: StoreState, ownProps?: any) => T): ((state: StoreState, ownProps?: any) => T & HasUser & HasUserDoc) => {
    return (state: StoreState, ownProps?: any) => ({ ...(mapProps(state, ownProps) as any), user: state.auth.user, userDoc: state.auth.userDoc }) as T & HasUser & HasUserDoc;
};

export interface AnalyticsStoreState {
    gaLoaded: boolean;
    cookiesAccepted: boolean;
}

export interface LocationStoreState {
    name: string;
    schools: SearchSchoolDocument[];
    loadError: Error | null;
}

export interface BookingStoreState {
    newBooking: NewBookingForm;
    newBookingResult: CreateBookingResult | null;
    getBookingResult: GetBookingResult | null;
    getBookingRequest: LoadBooking | null;
    bookings: UserBookingDictionary;
    acceptingBookingId: string;
    itineraries: BookingItineraryDictionary;
}

export type SchoolId = string;
export type BookingId = string;

export type SchoolsBookingsDictionary = { [schoolId: string]: SchoolBookingsDictionary };
export type SchoolBookingsDictionary = { [bookingId: string]: SchoolBookingForms };
export type SchoolDictionary = { [schoolId: string]: School };

export interface SchoolBookingStoreState {
    bookings: SchoolsBookingsDictionary;
    schools: SchoolDictionary;
}

export interface SchoolUsersStoreState {
    newUserForm: SchoolUserForm;
    users: SchoolUsersDictionary;
}

export interface SchoolSpotsStoreState {
    newSpotForm: SpotForm;
    spots: SchoolSpotsDictionary;
}

export interface SchoolSessionsStoreState {
    sessions: SchoolSessionsDictionary;
}

export interface SchoolJobsStoreState {
    instructorExperience: { [schoolId: string]: InstructorExperienceToVerifyDictionary };
    instructorProfiles: { [instructorId: string]: InstructorProfile };
}

export interface SchoolSettingsStoreState {
    settings: SchoolSettingsDictionary;
}

export interface DestinationStoreState {
    loadDestinationId: string;
    destinations: DestinationDictionary;
    destinationSchools: { [destinationId: string]: SearchSchoolDocument[] };
    destinationLoadErrors: { [destinationId: string]: Error | null };
}

export interface ActivityStoreState {
    activities: ActivityDictionary;
}

export interface UserSessionStoreState {
    sessions: UserSessionDictionary;
}

export interface InstructorStoreState {
    getInstructorProfileResult: GetInstructorProfileResult | null;
    getInstructorProfileAction: GetInstructorProfile | null;
    instructor: Instructor | null;
    kudos: { [id: string]: InstructorKudos };
    sessions: { [id: string]: InstructorSession };
    activateAccountResult: InstructorActivateAccountResult | null;
}

export interface StoreState {
    router: RouterState;
    search: SearchStoreState;
    school: SchoolStoreState;
    booking: BookingStoreState;
    auth: AuthStoreState;
    analytics: AnalyticsStoreState;
    destination: DestinationStoreState;
    activity: ActivityStoreState;
    schoolBooking: SchoolBookingStoreState;
    schoolUsers: SchoolUsersStoreState;
    schoolSettings: SchoolSettingsStoreState;
    schoolSpots: SchoolSpotsStoreState;
    schoolSessions: SchoolSessionsStoreState;
    schoolJobs: SchoolJobsStoreState;
    signin: SignInPageState;
    sessions: UserSessionStoreState;
    instructors: InstructorStoreState;
}