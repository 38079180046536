import {
    SCHOOL_BOOKING_UPDATED,
    SchoolBookingAction,
    OFFER_FORM_FIELD_CHANGED,
    DECLINE_FORM_FIELD_CHANGED,
    OfferFormFieldChanged,
    DeclineFormFieldChanged,
    SchoolBookingUpdated,
    SchoolUpdated,
    SCHOOL_UPDATED
} from '../actions/SchoolBookingActions';
import { SchoolBookingStoreState, SchoolBookingForms, calcMinDeposit, BookingCommissionModel, OfferForm, BOOKING_COMMISSION_PAYMENT_STRIPE_CONNECT, getNumInstructors } from '../types';
import { FIRESTORE_REMOVED } from '../constants';
import _ from 'lodash';

const initialState = {
    bookings: {},
    schools: {}
};

export default (state: SchoolBookingStoreState = initialState, action: SchoolBookingAction): SchoolBookingStoreState => {
    switch (action.type) {
        case SCHOOL_BOOKING_UPDATED:
            return updateBooking(action, state);
        case SCHOOL_UPDATED:
            return updateSchool(action, state);
        case OFFER_FORM_FIELD_CHANGED:
            return updateOfferForm(action, state);
        case DECLINE_FORM_FIELD_CHANGED:
            return updateDeclineForm(action, state);
        default:
            return state;
    }
};

const updateSchool = (action: SchoolUpdated, state: SchoolBookingStoreState): SchoolBookingStoreState => {
    const { id } = action.value;

    if (!action.value.data) {
        return {
            ...state,
            schools: _.omit(state.schools, id)
        };
    } else {
        return {
            ...state,
            schools: { ...state.schools, [id]: action.value.data }
        };
    }
};

const updateBooking = (action: SchoolBookingUpdated, state: SchoolBookingStoreState): SchoolBookingStoreState => {
    const { bookingId, schoolId } = action.value.data;

    if (action.value.change === FIRESTORE_REMOVED) {
        return {
            ...state,
            bookings: {
                ...state.bookings,
                [schoolId]: _.omit(state.bookings[schoolId], bookingId)
            }
        };
    } else {
        const newBooking: SchoolBookingForms = {
            ...action.value.data,
            offerForm: {
                currency: '',
                comments: '',
                price: 0,
                deposit: 0,
                availableDays: [],
                dayOption: 'any',
                totalDays: 1,
                hoursPerDay: 1,
                payableBy: 'any',
                lessonFormat: '',
                people: [],
                instructors: 0,
                included: []
            },
            declineForm: { reason: '' }
        };
        return {
            ...state,
            bookings: {
                ...state.bookings,
                [schoolId]: {
                    ...state.bookings[schoolId],
                    [bookingId]: newBooking
                }
            }
        };
    }
};

const updateOfferForm = (action: OfferFormFieldChanged, state: SchoolBookingStoreState): SchoolBookingStoreState => {
    const { bookingId, schoolId } = action.value;

    const newState: SchoolBookingStoreState = {
        ...state,
        bookings: {
            ...state.bookings,
            [schoolId]: {
                ...state.bookings[schoolId],
                [bookingId]: {
                    ...state.bookings[schoolId][bookingId],
                    offerForm: {
                        ...state.bookings[schoolId][bookingId].offerForm,
                        [action.value.field]: action.value.value
                    },
                }
            }
        }
    };

    const booking = newState.bookings[schoolId][bookingId];
    const form = booking.offerForm;

    // set number of instructors based on lesson format and number of people
    form.instructors = getNumInstructors(form);

    // ensure deposit can cover our commission and stripe charges
    if (action.value.field === 'price') {
        form.deposit = calcDeposit(booking);
    } else if (action.value.field === 'deposit') {
        form.deposit = calcDeposit(booking);
        form.price = Math.max(form.deposit, form.price);
    }

    return newState;
};

const roundUpToTen = (n: number) => Math.ceil(n / 10) * 10;

const calcDeposit = ({ offerForm, commissionModel }: { offerForm: OfferForm, commissionModel: BookingCommissionModel }) => {
    const minDeposit = calcMinDeposit(offerForm.price, commissionModel);

    if (commissionModel.commissionPayment === BOOKING_COMMISSION_PAYMENT_STRIPE_CONNECT) {
        // for schools with their own Stripe account, allow them to specify deposit greater than minimum
        const newDeposit = Math.max(Math.min(offerForm.price, offerForm.deposit), minDeposit);
        return roundUpToTen(newDeposit);
    }
    
    return minDeposit;
};

const updateDeclineForm = (action: DeclineFormFieldChanged, state: SchoolBookingStoreState): SchoolBookingStoreState => {
    const { bookingId, schoolId } = action.value;

    return {
        ...state,
        bookings: {
            ...state.bookings,
            [schoolId]: {
                ...state.bookings[schoolId],
                [bookingId]: {
                    ...state.bookings[schoolId][bookingId],
                    declineForm: {
                        ...state.bookings[schoolId][bookingId].declineForm,
                        [action.value.field]: action.value.value
                    },
                }
            }
        }
    };
};
