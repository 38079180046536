import * as React from 'react';
import './Loading.css';
import Spinner from './Spinner';

interface LoadingProps {
    dark?: boolean;
}

export const Loading = ({ dark }: LoadingProps) => {
    return (
        <div className={`loading ${!dark || 'dark'}`}>
            <Spinner />
        </div>
    );
};

export const LoadingPage = (props: LoadingProps) => 
    <div className="pageBody"><Loading {...props} /></div>;
