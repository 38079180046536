import {
    CHANGE_SEARCH_TEXT,
    SEARCH_RESULTS_UPDATED,
    SEARCH_SCHOOLS_UPDATED,
    SearchAction,
    SearchSchoolsUpdated
} from '../actions/SearchActions';
import { SearchStoreState, SearchSchoolDictionary } from '../types';
import _ from 'lodash';

const initialState = {
    text: '',
    results: [],
    schools: {}
} as SearchStoreState;

const getUpdatedSchools = (state: SearchStoreState, action: SearchSchoolsUpdated) => {
    const schools = _.omitBy(state.schools, s =>
        !action.value.removes.find(r => r.id === s.id)) as SearchSchoolDictionary;
    action.value.adds.forEach(a => schools[a.id] = a);
    return schools;
};

export default (state: SearchStoreState = initialState, action: SearchAction): SearchStoreState => {
    switch (action.type) {
        case CHANGE_SEARCH_TEXT:
            return { ...state, text: action.value };
        case SEARCH_RESULTS_UPDATED:
            return { ...state, results: action.value };
        case SEARCH_SCHOOLS_UPDATED:
            return { ...state, schools: getUpdatedSchools(state, action) };
        default:
            return state;
    }
};
