import { Instructor, InstructorExperience, InstructorCertification, InstructorKudos, InstructorStatus, InstructorGallery, InstructorJobOffer, InstructorInvitation, InstructorSession, InstructorSocial } from '../types';
import { ActionSuccess, ActionFailure } from './Common';
import { FirestoreChangeType } from '../constants';

export const GET_INSTRUCTOR_PROFILE = 'GET_INSTRUCTOR_PROFILE';
export type GET_INSTRUCTOR_PROFILE = typeof GET_INSTRUCTOR_PROFILE;

export const INSTRUCTOR_UPDATED = 'INSTRUCTOR_UPDATED';
export type INSTRUCTOR_UPDATED = typeof INSTRUCTOR_UPDATED;

export const INSTRUCTOR_SESSION_UPDATED = 'INSTRUCTOR_SESSION_UPDATED';
export type INSTRUCTOR_SESSION_UPDATED = typeof INSTRUCTOR_SESSION_UPDATED;

export const INSTRUCTOR_KUDOS_UPDATED = 'INSTRUCTOR_KUDOS_UPDATED';
export type INSTRUCTOR_KUDOS_UPDATED = typeof INSTRUCTOR_KUDOS_UPDATED;

export const UPDATE_INSTRUCTOR_PROFILE = 'UPDATE_INSTRUCTOR_PROFILE';
export type UPDATE_INSTRUCTOR_PROFILE = typeof UPDATE_INSTRUCTOR_PROFILE;

export const ADD_INSTRUCTOR_EXPERIENCE = 'ADD_INSTRUCTOR_EXPERIENCE';
export type ADD_INSTRUCTOR_EXPERIENCE = typeof ADD_INSTRUCTOR_EXPERIENCE;

export const ADD_INSTRUCTOR_CERTIFICATIONS = 'ADD_INSTRUCTOR_CERTIFICATIONS';
export type ADD_INSTRUCTOR_CERTIFICATIONS = typeof ADD_INSTRUCTOR_CERTIFICATIONS;

export const GIVE_INSTRUCTOR_KUDOS = 'GIVE_INSTRUCTOR_KUDOS';
export type GIVE_INSTRUCTOR_KUDOS = typeof GIVE_INSTRUCTOR_KUDOS;

export const OFFER_INSTRUCTOR_JOB = 'OFFER_INSTRUCTOR_JOB';
export type OFFER_INSTRUCTOR_JOB = typeof OFFER_INSTRUCTOR_JOB;

export const INVITE_INSTRUCTOR = 'INVITE_INSTRUCTOR';
export type INVITE_INSTRUCTOR = typeof INVITE_INSTRUCTOR;

export const ACTIVATE_INSTRUCTOR_ACCOUNT = 'ACTIVATE_INSTRUCTOR_ACCOUNT';
export type ACTIVATE_INSTRUCTOR_ACCOUNT = typeof ACTIVATE_INSTRUCTOR_ACCOUNT;

export interface ActivateInstructorAccount {
    type: ACTIVATE_INSTRUCTOR_ACCOUNT;
    sensitive: boolean;
    value: {
        email: string;
        password: string;
        href: string;
    };
}

export interface InstructorUpdated {
    type: INSTRUCTOR_UPDATED;
    value: {
        id: string;
        data: Instructor;
    };
}

export interface InstructorSessionUpdated {
    type: INSTRUCTOR_SESSION_UPDATED;
    value: {
        id: string;
        session: InstructorSession;
        change: FirestoreChangeType;
    };
}

export interface InstructorKudosUpdated {
    type: INSTRUCTOR_KUDOS_UPDATED;
    value: {
        id: string;
        kudos: InstructorKudos;
        change: FirestoreChangeType;
    };
}

export interface GetInstructorProfile {
    type: GET_INSTRUCTOR_PROFILE;
    value: {
        accountName: string;
    };
}

export interface InstructorProfileFields {
    name?: string;
    photoPreviewFile?: any;
    backgroundPreviewFile?: any;
    languages?: string[];
    activities?: string[];
    bio?: string;
    social?: InstructorSocial;
    kudos?: InstructorKudos[];
    status?: InstructorStatus;
    gallery?: InstructorGallery;
}

export interface UpdateInstructorProfile {
    type: UPDATE_INSTRUCTOR_PROFILE;
    value: InstructorProfileFields;
}

export interface AddInstructorExperience {
    type: ADD_INSTRUCTOR_EXPERIENCE;
    value: InstructorExperience[];
}

export interface AddInstructorCertifications {
    type: ADD_INSTRUCTOR_CERTIFICATIONS;
    value: InstructorCertification[];
}

export interface GiveInstructorKudos {
    type: GIVE_INSTRUCTOR_KUDOS;
    value: InstructorKudos;
}

export interface OfferInstructorJob {
    type: OFFER_INSTRUCTOR_JOB;
    value: InstructorJobOffer;
}

export interface InviteInstructor {
    type: INVITE_INSTRUCTOR;
    value: InstructorInvitation;
}

export type InstructorAction =
    | GetInstructorProfile
    | InstructorUpdated
    | InstructorSessionUpdated
    | InstructorKudosUpdated
    | AddInstructorExperience
    | AddInstructorCertifications
    | GiveInstructorKudos
    | UpdateInstructorProfile
    | OfferInstructorJob
    | InviteInstructor
    | ActivateInstructorAccount
    | ActionSuccess
    | ActionFailure;

export function getInstructorProfile(accountName: string): GetInstructorProfile {
    return {
        type: GET_INSTRUCTOR_PROFILE,
        value: {
            accountName
        }
    };
}

export function instructorUpdated(doc: firebase.firestore.DocumentSnapshot): InstructorUpdated {
    return {
        type: INSTRUCTOR_UPDATED,
        value: {
            id: doc.id,
            data: doc.data() as Instructor
        }
    };
}

export function instructorSessionUpdated(change: firebase.firestore.DocumentChange): InstructorSessionUpdated {
    return {
        type: INSTRUCTOR_SESSION_UPDATED,
        value: { 
            id: change.doc.id, 
            session: change.doc.data() as InstructorSession,
            change: change.type
        }
    };
}

export function instructorKudosUpdated(change: firebase.firestore.DocumentChange): InstructorKudosUpdated {
    return {
        type: INSTRUCTOR_KUDOS_UPDATED,
        value: { 
            id: change.doc.id, 
            kudos: {
                ...(change.doc.data() as InstructorKudos),
                id: change.doc.id
            },
            change: change.type
        }
    };
}

export function updateInstructorProfile(value: InstructorProfileFields): UpdateInstructorProfile {
    return {
        type: UPDATE_INSTRUCTOR_PROFILE,
        value: value
    };
}

export function addInstructorExperience(experience: InstructorExperience[]): AddInstructorExperience {
    return {
        type: ADD_INSTRUCTOR_EXPERIENCE,
        value: experience
    };
}

export function addInstructorCertifications(certifications: InstructorCertification[]): AddInstructorCertifications {
    return {
        type: ADD_INSTRUCTOR_CERTIFICATIONS,
        value: certifications
    };
}

export function giveInstructorKudos(kudos: InstructorKudos): GiveInstructorKudos {
    return {
        type: GIVE_INSTRUCTOR_KUDOS,
        value: kudos
    };
}

export function offerInstructorJob(jobOffer: InstructorJobOffer): OfferInstructorJob {
    return {
        type: OFFER_INSTRUCTOR_JOB,
        value: jobOffer
    };
}

export function inviteInstructor(invitation: InstructorInvitation): InviteInstructor {
    return {
        type: INVITE_INSTRUCTOR,
        value: invitation
    };
}

export function activateInstructorAccount(email: string, password: string, href: string): ActivateInstructorAccount {
    return {
        type: ACTIVATE_INSTRUCTOR_ACCOUNT,
        sensitive: true,
        value: {
            email,
            password,
            href
        }
    };
}
