import _ from 'lodash';
import { 
    SchoolJobsAction, 
    SCHOOL_INSTRUCTOR_EXPERIENCE_UPDATED, 
    INSTRUCTOR_PROFILES_UPDATED, 
    InstructorProfilesUpdated
} from '../actions/SchoolJobsActions';
import { SchoolJobsStoreState, InstructorProfile } from '../types';
import { FIRESTORE_REMOVED } from '../constants';

const initialState = {
    instructorExperience: {},
    instructorProfiles: {},

} as SchoolJobsStoreState;

const getUpdatedProfileState = (state: SchoolJobsStoreState, action: InstructorProfilesUpdated) => {
    const profiles = _.omitBy(state.instructorProfiles, p =>
        !action.value.removes.find(rem => 
            rem.instructorId === p.instructorId)) as { [instructorId: string]: InstructorProfile };
    action.value.adds.forEach(add => profiles[add.instructorId] = add);
    return profiles;
};

export default (state: SchoolJobsStoreState = initialState, action: SchoolJobsAction): SchoolJobsStoreState => {
    switch (action.type) {
        case INSTRUCTOR_PROFILES_UPDATED:
        return {
            ...state,
            instructorProfiles: getUpdatedProfileState(state, action)
        };
        case SCHOOL_INSTRUCTOR_EXPERIENCE_UPDATED:
            if (action.value.change === FIRESTORE_REMOVED) {
                return {
                    ...state,
                    instructorExperience: {
                        ...state.instructorExperience,
                        [action.value.schoolId]: _.omit(state.instructorExperience[action.value.schoolId], action.value.id)
                    }
                };
            } else {
                if (!action.value.data) {
                    return state;
                }
                return {
                    ...state,
                    instructorExperience: {
                        ...state.instructorExperience,
                        [action.value.schoolId]: {
                            ...state.instructorExperience[action.value.schoolId],
                            [action.value.id]: action.value.data
                        }
                    }
                };
            }
        default:
            return state;
    }
};