import _ from 'lodash';
import { USER_SESSION_UPDATED, UserSessionAction } from '../actions/SessionActions';
import { UserSessionStoreState } from '../types';
import { FIRESTORE_REMOVED } from '../constants';
import { LOAD_BOOKING } from '../actions/BookingActions';
import { ACTION_SUCCESS } from '../actions/Common';
import { GetBookingResult } from '../api/Commands';

const initialState = {
    sessions: {}
} as UserSessionStoreState;

export default (state: UserSessionStoreState = initialState, action: UserSessionAction): UserSessionStoreState => {
    switch (action.type) {
        case ACTION_SUCCESS:
            // when not signed in, the /public/getBooking API call will also return user sessions for booking,
            // which we can then pick up and store here
            if (action.action.type === LOAD_BOOKING) {
                const result = action.value as GetBookingResult;
                if (result.sessions) {
                    const newState = {
                        ...state,
                        sessions: {
                            ...state.sessions
                        }
                    };
                    result.sessions.forEach(s => newState.sessions[s.sessionId] = s);
                    return newState;
                }
            }
            return state;
        case USER_SESSION_UPDATED:
            if (action.value.change === FIRESTORE_REMOVED) {
                return { ...state, sessions: _.omit(state.sessions, action.value.id) };
            } else {
                if (!action.value.data) {
                    return state;
                }
                return {
                    ...state,
                    sessions: {
                        ...state.sessions,
                        [action.value.id]: {
                            ...action.value.data,
                        }
                    }
                };
            }
        default:
            return state;
    }
};