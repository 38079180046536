import { ActionResult } from './Common';
import { FirestoreChangeType, FIRESTORE_ADDED, FIRESTORE_MODIFIED, FIRESTORE_REMOVED } from '../constants';
import { InstructorExperienceToVerifyDoc, InstructorExperienceToVerify, InstructorProfile } from '../types';

export const VERIFY_EXPERIENCE = 'VERIFY_EXPERIENCE';
export type VERIFY_EXPERIENCE = typeof VERIFY_EXPERIENCE;

export const SCHOOL_INSTRUCTOR_EXPERIENCE_UPDATED = 'SCHOOL_INSTRUCTOR_EXPERIENCE_UPDATED';
export type SCHOOL_INSTRUCTOR_EXPERIENCE_UPDATED = typeof SCHOOL_INSTRUCTOR_EXPERIENCE_UPDATED;

export const INSTRUCTOR_PROFILES_UPDATED = 'INSTRUCTOR_PROFILES_UPDATED';
export type INSTRUCTOR_PROFILES_UPDATED = typeof INSTRUCTOR_PROFILES_UPDATED;

export interface VerifyExperience {
    type: VERIFY_EXPERIENCE;
    value: InstructorExperienceToVerify;
}

export interface SchoolInstructorExperienceUpdated {
    type: SCHOOL_INSTRUCTOR_EXPERIENCE_UPDATED;
    value: {
        schoolId: string;
        id: string;
        change: FirestoreChangeType;
        data: InstructorExperienceToVerifyDoc;
    };
}

export interface InstructorProfilesUpdated {
    type: INSTRUCTOR_PROFILES_UPDATED;
    value: {
        adds: InstructorProfile[];
        removes: InstructorProfile[];
    };
}

export type SchoolJobsAction = VerifyExperience | InstructorProfilesUpdated | SchoolInstructorExperienceUpdated | ActionResult;

export const verifyExperience = (exp: InstructorExperienceToVerify): VerifyExperience => {
    return {
        type: VERIFY_EXPERIENCE,
        value: exp
    };
};

export const schoolInstructorExperienceUpdated = (change: firebase.firestore.DocumentChange): SchoolInstructorExperienceUpdated => {
    const parentDoc = change.doc.ref.parent.parent;
    if (!parentDoc) {
        throw new Error('Expected parent doc');
    }

    const schoolId = parentDoc.id;
    return {
        type: SCHOOL_INSTRUCTOR_EXPERIENCE_UPDATED,
        value: {
            schoolId: schoolId,
            id: change.doc.id,
            change: change.type,
            data: change.doc.data() as InstructorExperienceToVerifyDoc
        }
    };
};

export const instructorProfilesUpdated = (changes: firebase.firestore.DocumentChange[]): InstructorProfilesUpdated => {
    const adds = changes
        .filter(c => c.type === FIRESTORE_ADDED || c.type === FIRESTORE_MODIFIED)
        .map(c => c.doc.data() as InstructorProfile);

    const removes = changes
        .filter(c => c.type === FIRESTORE_REMOVED)
        .map(c => c.doc.data() as InstructorProfile);

    return {
        type: INSTRUCTOR_PROFILES_UPDATED,
        value: {
            adds,
            removes
        }
    };
};