import * as React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

export const Footer = () => {
    return (
        <footer className="flex-col footerContainer">
            <div className="flex-row flex-between footer">
                <div className="flex-col">
                    <b>Destinations</b>
                    <Link to="/destination/tarifa-andalucia-spain">Tarifa, Andalucia, Spain</Link>
                    <Link to="/destination/cape-town-south-africa">Cape Town, South Africa</Link>
                    <Link to="/destination/red-sea-egypt">Red Sea, Egypt</Link>
                    <Link to="/destination/salento-puglia-italy">Salento, Puglia, Italy</Link>
                    <Link to="/destination/cornwall-uk">Cornwall, UK</Link>
                </div>
                <div className="flex-col">
                    <b>Activities</b>
                    <Link to="/activity/kitesurfing">Kitesurfing</Link>
                    <Link to="/activity/windsurfing">Windsurfing</Link>
                    <Link to="/activity/paddleboarding">Paddle Boarding</Link>
                    <Link to="/activity/scubadiving">Scuba Diving</Link>
                </div>
                <div className="flex-col">
                    <b>Company</b>
                    <Link to="/about">About</Link>
                    <a href="https://www.instagram.com/outdr.app/">Instagram</a>
                    <a href="https://www.facebook.com/outdrapp/">Facebook</a>
                    <a href="https://twitter.com/outdr_com/">Twitter</a>
                </div>
                <div className="flex-col">
                    <b>Policy</b>
                    <Link to="/policy/terms">Terms</Link>
                    <Link to="/policy/privacy">Privacy</Link>
                    <Link to="/policy/cookies">Cookies</Link>
                </div>
            </div>
        </footer>
    );
};