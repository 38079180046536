import * as React from 'react';
import { SearchSchoolDocument } from '../../types';
import LocationIcon from './icons/LocationIcon';

const iconSize = 24;

const MapLocationSchool = ({ school, lat, lng, highlighted }: { school: SearchSchoolDocument, lat: number, lng: number, highlighted: boolean }) => {
    return (
        <div
            className={`${highlighted ? 'locationIconHighlighted' : ''}`}
            style={{
                position: 'absolute',
                display: 'inline-block',
                height: `${iconSize}px`,
                width: `${iconSize}px`,
                left: `-${iconSize / 2}px`,
                top: `-${iconSize}px`
            }}
        >
            <LocationIcon />
        </div>
    );
};

export default MapLocationSchool;