import _ from 'lodash';
import { SCHOOL_SESSION_UPDATED, SchoolSessionAction } from '../actions/SchoolSessionActions';
import { SchoolSessionsStoreState } from '../types';
import { FIRESTORE_REMOVED } from '../constants';

const initialState = {
    sessions: {}
} as SchoolSessionsStoreState;

export default (state: SchoolSessionsStoreState = initialState, action: SchoolSessionAction): SchoolSessionsStoreState => {
    switch (action.type) {
        case SCHOOL_SESSION_UPDATED:
            if (action.value.change === FIRESTORE_REMOVED) {
                return {
                    ...state,
                    sessions: {
                        ...state.sessions,
                        [action.value.data.schoolId]: _.omit(state.sessions[action.value.data.schoolId], action.value.id)
                    }
                };
            } else {
                if (!action.value.data) {
                    return state;
                }
                return {
                    ...state,
                    sessions: {
                        ...state.sessions,
                        [action.value.data.schoolId]: {
                            ...state.sessions[action.value.data.schoolId],
                            [action.value.id]: {
                                ...action.value.data,
                            }
                        }
                    }
                };
            }
        default:
            return state;
    }
};