import { AuthenticatedRoute, AuthenticatedRouteStoreProps } from '../../components/common/AuthenticatedRoute';
import { StoreState } from '../../types';
import { connect } from 'react-redux';

const mapStateToProps = (storeState: StoreState): AuthenticatedRouteStoreProps => (
    {
        authenticated: !!storeState.auth.user,
        authenticationLoaded: !!(storeState.auth.firebaseAuthLoaded),
        permissionsLoaded: !!(storeState.auth.permissionsLoaded),
        isSchoolUser: !!storeState.auth.currentlyManagingSchoolId
    }
);

export default connect(mapStateToProps)(AuthenticatedRoute);