import { Epic, combineEpics, ofType } from 'redux-observable';

import { CHANGE_SEARCH_TEXT, SearchAction, searchResultsUpdated, ChangeSearchText } from '../actions/SearchActions';
import { StoreState } from '../types';
import { map } from 'rxjs/operators';
import _ from 'lodash';

const searchSchoolsEpic: Epic<SearchAction, SearchAction, StoreState> =
    (action$, store$) =>
        action$
            .pipe(
                ofType<SearchAction, ChangeSearchText>(CHANGE_SEARCH_TEXT),
                map(action => {
                    const searchText = action.value;
                    const lowerCaseSearch = searchText.toLowerCase();

                    const results =
                        _.filter(
                            store$.value.search.schools,
                            school => {
                                if (!searchText || school.bookingDisabled) {
                                    return false;
                                }

                                if (!!school.name &&
                                    school.name.toLowerCase().includes(lowerCaseSearch)) {
                                    return true;
                                }

                                const destination = store$.value.destination.destinations[school.destinationId];

                                if (!!destination &&
                                    !!destination.name &&
                                    destination.name.toLowerCase().includes(lowerCaseSearch)) {
                                    return true;
                                }

                                return false;
                            }

                        );

                    return searchResultsUpdated(results);
                }
                ));

export default combineEpics(
    searchSchoolsEpic
);
