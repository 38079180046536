import { SearchSchoolDocument, SearchSchoolSpotDocument } from '../types';

export const LOAD_SCHOOL = 'LOAD_SCHOOL';
export type LOAD_SCHOOL = typeof LOAD_SCHOOL;

export const SCHOOL_LOAD_SUCCESS = 'SCHOOL_LOAD_SUCCESS';
export type SCHOOL_LOAD_SUCCESS = typeof SCHOOL_LOAD_SUCCESS;

export const SCHOOL_LOAD_FAILURE = 'SCHOOL_LOAD_FAILURE';
export type SCHOOL_LOAD_FAILURE = typeof SCHOOL_LOAD_FAILURE;

export interface SchoolLoadSuccess {
    type: SCHOOL_LOAD_SUCCESS;
    value: SearchSchoolDocument;
}

export interface SchoolLoadFailure {
    type: SCHOOL_LOAD_FAILURE;
    value: Error;
}

export interface LoadSchool {
    type: LOAD_SCHOOL;
    value: string;
}

export type SchoolAction = LoadSchool | SchoolLoadSuccess | SchoolLoadFailure;

export function loadSchool(schoolId: string): LoadSchool {
    return {
        type: LOAD_SCHOOL,
        value: schoolId
    };
}

export function schoolLoadSuccess(snapshot: firebase.firestore.DocumentSnapshot, spotsSnapshot: firebase.firestore.QuerySnapshot): SchoolLoadSuccess {
    return {
        type: SCHOOL_LOAD_SUCCESS,
        value: { 
            id: snapshot.id, 
            ...snapshot.data(),
            spots: spotsSnapshot.docs.map(d => ({ id: d.id,  ...d.data() } as SearchSchoolSpotDocument))    
        } as SearchSchoolDocument
    };
}

export function schoolLoadFailure(err: Error): SchoolLoadFailure {
    return {
        type: SCHOOL_LOAD_FAILURE,
        value: err
    };
}
