import { SearchSchoolDocument } from '../types';
import { FIRESTORE_REMOVED, FIRESTORE_ADDED, FIRESTORE_MODIFIED } from '../constants';

export const CHANGE_SEARCH_TEXT = 'CHANGE_SEARCH_TEXT';
export type CHANGE_SEARCH_TEXT = typeof CHANGE_SEARCH_TEXT;

export const SEARCH_RESULTS_UPDATED = 'SEARCH_RESULTS_UPDATED';
export type SEARCH_RESULTS_UPDATED = typeof SEARCH_RESULTS_UPDATED;

export const SEARCH_SCHOOLS_UPDATED = 'SEARCH_SCHOOLS_UPDATED';
export type SEARCH_SCHOOLS_UPDATED = typeof SEARCH_SCHOOLS_UPDATED;

export interface ChangeSearchText {
    type: CHANGE_SEARCH_TEXT;
    value: string;
}

export interface SearchResultsUpdated {
    type: SEARCH_RESULTS_UPDATED;
    value: SearchSchoolDocument[];
}

export interface SearchSchoolsUpdated {
    type: SEARCH_SCHOOLS_UPDATED;
    value: {
        adds: SearchSchoolDocument[];
        removes: SearchSchoolDocument[];
    };
}

export type SearchAction = ChangeSearchText | SearchResultsUpdated | SearchSchoolsUpdated;

export function changeSearchText(searchText: string): ChangeSearchText {
    return {
        type: CHANGE_SEARCH_TEXT,
        value: searchText
    };
}

export function searchResultsUpdated(searchResults: SearchSchoolDocument[]): SearchResultsUpdated {
    return {
        type: SEARCH_RESULTS_UPDATED,
        value: searchResults
    };
}

export function searchSchoolsUpdated(changes: firebase.firestore.DocumentChange[]): SearchSchoolsUpdated {
    const adds = changes
        .filter(c => c.type === FIRESTORE_ADDED || c.type === FIRESTORE_MODIFIED)
        .map(c => ({ id: c.doc.id, ...c.doc.data() } as SearchSchoolDocument));

    const removes = changes
        .filter(c => c.type === FIRESTORE_REMOVED)
        .map(c => ({ id: c.doc.id, ...c.doc.data() } as SearchSchoolDocument));

    return {
        type: SEARCH_SCHOOLS_UPDATED,
        value: {
            adds,
            removes
        }
    };
}