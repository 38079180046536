import { Action } from 'redux';

export const ACTION_SUCCESS = 'ACTION_SUCCESS';
export type ACTION_SUCCESS = typeof ACTION_SUCCESS;

export const ACTION_FAILURE = 'ACTION_FAILURE';
export type ACTION_FAILURE = typeof ACTION_FAILURE;

export interface ActionSuccess extends Action {
    type: ACTION_SUCCESS;
    action: any;
    value: any | undefined;
}

export interface ActionFailure extends Action {
    type: ACTION_FAILURE;
    action: any;
    value: any | undefined;
    error: Error | undefined;
}

export type ActionResult = ActionSuccess | ActionFailure;

export const actionSuccess = <T>(action: T, value?: any): ActionSuccess => {
    return {
        type: ACTION_SUCCESS,
        action,
        value
    };
};

export const actionFailure = <T>(action: T, error: Error, value?: any): ActionFailure => {
    return {
        type: ACTION_FAILURE,
        action,
        value,
        error
    };
};