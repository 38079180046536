export const COOKIE_BANNER_OK_CLICKED = 'COOKIE_BANNER_OK_CLICKED';
export type COOKIE_BANNER_OK_CLICKED = typeof COOKIE_BANNER_OK_CLICKED;

export const COOKIE_POLICY_ACCEPTED = 'COOKIE_POLICY_ACCEPTED';
export type COOKIE_POLICY_ACCEPTED = typeof COOKIE_POLICY_ACCEPTED;

export const GOOGLE_ANALYTICS_LOADED = 'GOOGLE_ANALYTICS_LOADED';
export type GOOGLE_ANALYTICS_LOADED = typeof GOOGLE_ANALYTICS_LOADED;

export const LOAD_GOOGLE_ANALYTICS = 'LOAD_GOOGLE_ANALYTICS';
export type LOAD_GOOGLE_ANALYTICS = typeof LOAD_GOOGLE_ANALYTICS;

export const GA_COMMAND = 'GA_COMMAND';
export type GA_COMMAND = typeof GA_COMMAND;

export type GoogleAnalyticsCommand = {
    type: GA_COMMAND;
    command: string;
    fields: any[];
};

export type GoogleAnalyticsLoaded = {
    type: GOOGLE_ANALYTICS_LOADED;
};

export type CookiePolicyAccepted = {
    type: COOKIE_POLICY_ACCEPTED;
};

export const gaCommand = (command: string, ...fields: any[]): GoogleAnalyticsCommand => ({
    type: GA_COMMAND,
    command,
    fields
});

export type AnalyticsAction =
    | {type: COOKIE_BANNER_OK_CLICKED}
    | CookiePolicyAccepted
    | GoogleAnalyticsLoaded
    | GoogleAnalyticsCommand
    | {type: LOAD_GOOGLE_ANALYTICS}
    ;