import _ from 'lodash';
import { SchoolSettingsStoreState } from '../types';
import { FIRESTORE_REMOVED } from '../constants';
import { SchoolSettingsAction, SCHOOL_SETTINGS_UPDATED } from '../actions/SchoolSettingsActions';

const initialState = {
    settings: {}
} as SchoolSettingsStoreState;

export default (state: SchoolSettingsStoreState = initialState, action: SchoolSettingsAction): SchoolSettingsStoreState => {
    if (!action.value || 
        (action.value.id !== 'offer' && 
        action.value.id !== 'color' &&
        action.value.id !== 'calendar')) {
        return state;
    }
    switch (action.type) {
        case SCHOOL_SETTINGS_UPDATED:
            if (action.value.change === FIRESTORE_REMOVED) {
                return { ...state, settings: _.omit(state.settings, action.value.schoolId) };
            } else {
                return { 
                    ...state, 
                    settings: { 
                        ...state.settings, 
                        [action.value.schoolId]: {
                            ...state.settings[action.value.schoolId],
                            [action.value.id]: action.value.data
                        }
                    } 
                };
            }
        default:
            return state;
    }
};