import * as React from 'react';
import { Route, Redirect, RouteProps, RouteComponentProps } from 'react-router-dom';
import { Loading } from '.';
import { StaticContext } from 'react-router';
import Forbidden from './Forbidden';

export interface AuthenticatedRouteStoreProps {
    authenticated: boolean;
    authenticationLoaded: boolean;
    permissionsLoaded: boolean;
    isSchoolUser: boolean;
}

export interface AuthenticatedRouteOwnProps {
    schoolsOnly?: boolean;
}

export const AuthenticatedRoute =
    (props: AuthenticatedRouteStoreProps & AuthenticatedRouteOwnProps & RouteProps) => {
        const { 
            component: WrappedComponent, 
            authenticated,
            authenticationLoaded, 
            permissionsLoaded, 
            schoolsOnly, 
            ...rest 
        } = props;
        
        if (!WrappedComponent) {
            return null;
        }

        const renderRoute = (routeProps: RouteComponentProps<any, StaticContext, any>) => {

            if (!authenticationLoaded) {
                return <Loading />;
            }

            if (!authenticated) {
                return <Redirect to={{ pathname: '/signin', state: { from: rest.location } }} />;
            }

            if (schoolsOnly && !props.isSchoolUser) {                
                return permissionsLoaded ? <Forbidden /> : <Loading />;
            }

            return <WrappedComponent {...routeProps} {...props} />;                
        };

        return <Route {...rest} render={renderRoute} />;
    };