import * as React from 'react';
import { PoweredByStripeLogo } from './SvgImports';

const PoweredByStripe = () => {
    return (
        <a className="poweredByStripe" target="_blank" rel="noopener noreferrer" href="http://stripe.com">
            <img className="poweredByStripeLogo" src={PoweredByStripeLogo} alt="Powered by Stripe" />
        </a>
    );
};

export default PoweredByStripe;