import { ActionResult } from './Common';
import { SchoolSession } from '../types';
import { FirestoreChangeType } from '../constants';
import { MoveSessions } from '../api/Commands';

export const SAVE_SESSION = 'SAVE_SESSION';
export type SAVE_SESSION = typeof SAVE_SESSION;

export const MOVE_SESSIONS = 'MOVE_SESSIONS';
export type MOVE_SESSIONS = typeof MOVE_SESSIONS;

export const SCHOOL_SESSION_UPDATED = 'SCHOOL_SESSION_UPDATED';
export type SCHOOL_SESSION_UPDATED = typeof SCHOOL_SESSION_UPDATED;

export type SchoolSessionAction =
    SaveSession |
    SchoolSessionUpdated |
    { type: MOVE_SESSIONS; value: MoveSessions } |
    ActionResult;

export interface SaveSession {
    type: SAVE_SESSION;
    value: SchoolSession;
}

export interface SchoolSessionUpdated {
    type: SCHOOL_SESSION_UPDATED;
    value: {
        id: string;
        change: FirestoreChangeType;
        data: SchoolSession;
    };
}

export function schoolSessionUpdated(change: firebase.firestore.DocumentChange): SchoolSessionUpdated {
    return {
        type: SCHOOL_SESSION_UPDATED,
        value: {
            id: change.doc.id,
            change: change.type,
            data: change.doc.data() as SchoolSession
        }
    };
}

export const saveSession = (session: SchoolSession): SaveSession => {
    return {
        type: SAVE_SESSION,
        value: session
    };
};

export const moveSessions = (command: MoveSessions) => {
    return {
        type: MOVE_SESSIONS,
        value: {
            ...command
        }
    };
};