import { DestinationDocument, SearchSchoolDocument } from '../types';
import { FIRESTORE_REMOVED, FIRESTORE_ADDED, FIRESTORE_MODIFIED } from '../constants';

export const DESTINATION_UPDATED = 'DESTINATION_UPDATED';
export type DESTINATION_UPDATED = typeof DESTINATION_UPDATED;

export const LOAD_DESTINATION_SCHOOLS = 'LOAD_DESTINATION_SCHOOLS';
export type LOAD_DESTINATION_SCHOOLS = typeof LOAD_DESTINATION_SCHOOLS;

export const LOAD_DESTINATION_SCHOOLS_SUCCESS = 'LOAD_DESTINATION_SCHOOLS_SUCCESS';
export type LOAD_DESTINATION_SCHOOLS_SUCCESS = typeof LOAD_DESTINATION_SCHOOLS_SUCCESS;

export const LOAD_DESTINATION_SCHOOLS_FAILURE = 'LOAD_DESTINATION_SCHOOLS_FAILURE';
export type LOAD_DESTINATION_SCHOOLS_FAILURE = typeof LOAD_DESTINATION_SCHOOLS_FAILURE;

export interface LoadDestinationSchoolsSuccess {
    type: LOAD_DESTINATION_SCHOOLS_SUCCESS;
    value: {
        destinationId: string;
        schools: SearchSchoolDocument[];
    };
}

export interface LoadDestinationSchoolsFailure {
    type: LOAD_DESTINATION_SCHOOLS_FAILURE;
    value: {
        destinationId: string;
        error: Error;
    };
}

export interface LoadDestinationSchools {
    type: LOAD_DESTINATION_SCHOOLS;
    value: string;
}

export interface DestinationsUpdated {
    type: DESTINATION_UPDATED;
    value: {
        adds: DestinationDocument[];
        removes: DestinationDocument[];
    };
}

export type DestinationAction = DestinationsUpdated | LoadDestinationSchools | LoadDestinationSchoolsSuccess | LoadDestinationSchoolsFailure;

export function destinationsUpdated(changes: firebase.firestore.DocumentChange[]): DestinationsUpdated {
    const adds = changes
        .filter(c => c.type === FIRESTORE_ADDED || c.type === FIRESTORE_MODIFIED)
        .map(c => c.doc.data() as DestinationDocument);

    const removes = changes
        .filter(c => c.type === FIRESTORE_REMOVED)
        .map(c => c.doc.data() as DestinationDocument);

    return {
        type: DESTINATION_UPDATED,
        value: {
            adds,
            removes
        }
    };
}

export function loadDestinationSchools(destinationId: string): LoadDestinationSchools {
    return {
        type: LOAD_DESTINATION_SCHOOLS,
        value: destinationId
    };
}

export function loadDestinationSchoolsSuccess(destinationId: string, snapshot: firebase.firestore.QuerySnapshot): LoadDestinationSchoolsSuccess {
    return {
        type: LOAD_DESTINATION_SCHOOLS_SUCCESS,
        value: {
            destinationId,
            schools: snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as SearchSchoolDocument))
        }
    };
}

export function loadDestinationSchoolsFailure(destinationId: string, err: Error): LoadDestinationSchoolsFailure {
    return {
        type: LOAD_DESTINATION_SCHOOLS_FAILURE,
        value: {
            destinationId,
            error: err
        }
    };
}
