import * as React from 'react';
import { PolicyPage } from './PolicyPage';

export const CookiePolicyPage = () => {
    return (
        <PolicyPage title={`Cookie Policy`}>
            <iframe
                src="https://www.iubenda.com/privacy-policy/44924335/cookie-policy"
                style={{ flex: 1 }}
                frameBorder="0"
                scrolling="yes"
                title="Privacy Policy"
            />
        </PolicyPage>
    );
};