import { SocialAuthProvider } from '../types/AuthTypes';
import { SignInEpicName, SignInTabName, SignInFieldName } from '../types/SignInTypes';

export const SIGNIN_FIELD_UPDATED = 'SIGNIN_FIELD_UPDATED';
export type SIGNIN_FIELD_UPDATED = typeof SIGNIN_FIELD_UPDATED;

export const SIGNIN_SOCIAL_BUTTON_CLICKED = 'SIGNIN_SOCIAL_BUTTON_CLICKED';
export type SIGNIN_SOCIAL_BUTTON_CLICKED = typeof SIGNIN_SOCIAL_BUTTON_CLICKED;

export const SIGNIN_EMAIL_BUTTON_CLICKED = 'SIGNIN_EMAIL_BUTTON_CLICKED';
export type SIGNIN_EMAIL_BUTTON_CLICKED = typeof SIGNIN_EMAIL_BUTTON_CLICKED;

export const SIGNIN_TOGGLE_PASSWORD_VISIBLE = 'SIGNIN_TOGGLE_PASSWORD_VISIBLE';
export type SIGNIN_TOGGLE_PASSWORD_VISIBLE = typeof SIGNIN_TOGGLE_PASSWORD_VISIBLE;

export const SIGNIN_FORGOT_PASSWORD_CLICKED = 'SIGNIN_FORGOT_PASSWORD_CLICKED';
export type SIGNIN_FORGOT_PASSWORD_CLICKED = typeof SIGNIN_FORGOT_PASSWORD_CLICKED;

export const SIGNIN_RESET_PASSWORD_BUTTON_CLICKED = 'SIGNIN_RESET_PASSWORD_BUTTON_CLICKED';
export type SIGNIN_RESET_PASSWORD_BUTTON_CLICKED = typeof SIGNIN_RESET_PASSWORD_BUTTON_CLICKED;

export const SIGNIN_CREATE_ACCOUNT_BUTTON_CLICKED = 'SIGNIN_CREATE_ACCOUNT_BUTTON_CLICKED';
export type SIGNIN_CREATE_ACCOUNT_BUTTON_CLICKED = typeof SIGNIN_CREATE_ACCOUNT_BUTTON_CLICKED;

export const SIGNIN_EMAIL_LINK_BUTTON_CLICKED = 'SIGNIN_EMAIL_LINK_BUTTON_CLICKED';
export type SIGNIN_EMAIL_LINK_BUTTON_CLICKED = typeof SIGNIN_EMAIL_LINK_BUTTON_CLICKED;

export const SIGNIN_TAB_CLICKED = 'SIGNIN_TAB_CLICKED';
export type SIGNIN_TAB_CLICKED = typeof SIGNIN_TAB_CLICKED;

export type SignInSocialButtonClickedAction = { type: SIGNIN_SOCIAL_BUTTON_CLICKED; provider: SocialAuthProvider };

export type SignInPageUserAction =
    | { type: SIGNIN_FIELD_UPDATED; field: SignInFieldName; value: string; sensitive: boolean }
    | { type: SIGNIN_FORGOT_PASSWORD_CLICKED }
    | { type: SIGNIN_RESET_PASSWORD_BUTTON_CLICKED }
    | { type: SIGNIN_CREATE_ACCOUNT_BUTTON_CLICKED }
    | { type: SIGNIN_EMAIL_LINK_BUTTON_CLICKED }
    | { type: SIGNIN_EMAIL_BUTTON_CLICKED }
    | { type: SIGNIN_TAB_CLICKED; tabName: SignInTabName }
    | { type: SIGNIN_TOGGLE_PASSWORD_VISIBLE }
    | SignInSocialButtonClickedAction
    ;

export const SIGNIN_RESET_PASSWORD_STARTED = 'SIGNIN_RESET_PASSWORD_STARTED';
export type SIGNIN_RESET_PASSWORD_STARTED = typeof SIGNIN_RESET_PASSWORD_STARTED;

export const SIGNIN_RESET_PASSWORD_SUCCESS = 'SIGNIN_RESET_PASSWORD_SUCCESS';
export type SIGNIN_RESET_PASSWORD_SUCCESS = typeof SIGNIN_RESET_PASSWORD_SUCCESS;

export const SIGNIN_RESET_PASSWORD_FAILED = 'SIGNIN_RESET_PASSWORD_FAILED';
export type SIGNIN_RESET_PASSWORD_FAILED = typeof SIGNIN_RESET_PASSWORD_FAILED;

export const SIGNIN_SIGNING_IN = 'SIGNIN_SIGNING_IN';
export type SIGNIN_SIGNING_IN = typeof SIGNIN_SIGNING_IN;

export const SIGNIN_EPIC_STARTED = 'SIGNIN_EPIC_STARTED';
export type SIGNIN_EPIC_STARTED = typeof SIGNIN_EPIC_STARTED;

export const SIGNIN_EPIC_FINISHED = 'SIGNIN_EPIC_FINISHED';
export type SIGNIN_EPIC_FINISHED = typeof SIGNIN_EPIC_FINISHED;

export const SIGNIN_SIGNED_IN = 'SIGNIN_SIGNED_IN';
export type SIGNIN_SIGNED_IN = typeof SIGNIN_SIGNED_IN;

export const SIGNIN_EMAIL_SIGNIN_FAILED = 'SIGNIN_EMAIL_SIGNIN_FAILED';
export type SIGNIN_EMAIL_SIGNIN_FAILED = typeof SIGNIN_EMAIL_SIGNIN_FAILED;

export const SIGNIN_SOCIAL_SIGNIN_FAILED_WRONG_PROVIDER = 'SIGNIN_SOCIAL_SIGNIN_FAILED_WRONG_PROVIDER';
export type SIGNIN_SOCIAL_SIGNIN_FAILED_WRONG_PROVIDER = typeof SIGNIN_SOCIAL_SIGNIN_FAILED_WRONG_PROVIDER;

export const SIGNIN_SOCIAL_SIGNIN_FAILED = 'SIGNIN_SOCIAL_SIGNIN_FAILED';
export type SIGNIN_SOCIAL_SIGNIN_FAILED = typeof SIGNIN_SOCIAL_SIGNIN_FAILED;

export const SIGNIN_CREATE_ACCOUNT_FAILED = 'SIGNIN_CREATE_ACCOUNT_FAILED';
export type SIGNIN_CREATE_ACCOUNT_FAILED = typeof SIGNIN_CREATE_ACCOUNT_FAILED;

export const SIGNIN_SIGNIN_EMAIL_SENT = 'SIGNIN_SIGNIN_EMAIL_SENT';
export type SIGNIN_SIGNIN_EMAIL_SENT = typeof SIGNIN_SIGNIN_EMAIL_SENT;

export const SIGNIN_SIGNIN_EMAIL_SEND_FAILED = 'SIGNIN_SIGNIN_EMAIL_SEND_FAILED';
export type SIGNIN_SIGNIN_EMAIL_SEND_FAILED = typeof SIGNIN_SIGNIN_EMAIL_SEND_FAILED;

export const SIGNIN_VERIFY_EMAIL_LINK_FAILED = 'SIGNIN_VERIFY_EMAIL_LINK_FAILED';
export type SIGNIN_VERIFY_EMAIL_LINK_FAILED = typeof SIGNIN_VERIFY_EMAIL_LINK_FAILED;

export type SignInPageAction =
    | SignInPageUserAction
    | { type: SIGNIN_RESET_PASSWORD_STARTED }
    | { type: SIGNIN_RESET_PASSWORD_SUCCESS }
    | { type: SIGNIN_RESET_PASSWORD_FAILED; message: string }
    | { type: SIGNIN_SIGNING_IN }
    | { type: SIGNIN_EPIC_STARTED; epic: SignInEpicName }
    | { type: SIGNIN_EPIC_FINISHED }
    | { type: SIGNIN_SIGNED_IN }
    | { type: SIGNIN_EMAIL_SIGNIN_FAILED; code: string; error: string }
    | { type: SIGNIN_SOCIAL_SIGNIN_FAILED_WRONG_PROVIDER; providers: string[]; email: string }
    | { type: SIGNIN_SOCIAL_SIGNIN_FAILED; message: string; code: string }
    | { type: SIGNIN_CREATE_ACCOUNT_FAILED; message: string }
    | { type: SIGNIN_SIGNIN_EMAIL_SENT }
    | { type: SIGNIN_SIGNIN_EMAIL_SEND_FAILED; message: string }
    | { type: SIGNIN_VERIFY_EMAIL_LINK_FAILED; message: string }
    ;