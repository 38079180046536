import { StoreState } from '../types/index';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AnalyticsAction, COOKIE_BANNER_OK_CLICKED } from '../actions/AnalyticsActions';
import CookieBanner, { CookieBannerStateProps } from '../components/CookieBanner';

const mapStateToProps = ({ analytics }: StoreState): CookieBannerStateProps => ({
    cookiesAccepted: analytics.cookiesAccepted
});

const mapDispatchToProps = (dispatch: Dispatch<AnalyticsAction>) => ({
    acceptCookies: () => { dispatch({ type: COOKIE_BANNER_OK_CLICKED }); },
});

export default connect(mapStateToProps, mapDispatchToProps)(CookieBanner);