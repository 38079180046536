import * as React from 'react';
import './Forbidden.css';

const Forbidden = ({ children }: { children?: any }) => {
    return (
        <div className="forbidden flex-col">
            {children ? children : <div>Forbidden</div>}
        </div>
    );
};

export default Forbidden;
