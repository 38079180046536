import { ActionResult } from './Common';
import { SchoolUserDocument } from '../types/SchoolUserTypes';
import { FirestoreChangeType } from '../constants';

export const SCHOOL_USER_FORM_FIELD_CHANGED = 'SCHOOL_USER_FORM_FIELD_CHANGED';
export type SCHOOL_USER_FORM_FIELD_CHANGED = typeof SCHOOL_USER_FORM_FIELD_CHANGED;

export const ADD_SCHOOL_USER = 'ADD_SCHOOL_USER';
export type ADD_SCHOOL_USER = typeof ADD_SCHOOL_USER;

export const SCHOOL_USER_UPDATED = 'SCHOOL_USER_UPDATED';
export type SCHOOL_USER_UPDATED = typeof SCHOOL_USER_UPDATED;

export interface SchoolUserFormFieldChanged {
    type: SCHOOL_USER_FORM_FIELD_CHANGED;
    value: {
        schoolId: string;
        field: string;
        value: any;
    };
}

export interface AddSchoolUser {
    type: ADD_SCHOOL_USER;
    value: {
        schoolId: string;
    };
}

export interface SchoolUserUpdated {
    type: SCHOOL_USER_UPDATED;
    value: {
        id: string;
        change: FirestoreChangeType;
        data: SchoolUserDocument;
    };
}

export type SchoolUserAction = SchoolUserFormFieldChanged | SchoolUserUpdated | ActionResult;

export const schoolUserFormFieldChanged = (schoolId: string, field: string, value: any): SchoolUserFormFieldChanged => {
    return {
        type: SCHOOL_USER_FORM_FIELD_CHANGED,
        value: {
            schoolId,
            field,
            value
        }
    };
};

export const addSchoolUser = (schoolId: string): AddSchoolUser => {
    return {
        type: ADD_SCHOOL_USER,
        value: {
            schoolId
        }
    };
};

export const schoolUserUpdated = (change: firebase.firestore.DocumentChange): SchoolUserUpdated => {
    const parentDoc = change.doc.ref.parent.parent;
    if (!parentDoc) {
        throw new Error('Expected parent doc');
    }
    const schoolId = parentDoc.id;
    return {
        type: SCHOOL_USER_UPDATED,
        value: {
            id: change.doc.id,
            change: change.type,
            data: {
                id: change.doc.id,
                schoolId: schoolId,
                ...change.doc.data() 
            } as SchoolUserDocument
        }
    };
};