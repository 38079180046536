import {
    SignInPageAction,
    SIGNIN_RESET_PASSWORD_STARTED,
    SIGNIN_RESET_PASSWORD_SUCCESS,
    SIGNIN_RESET_PASSWORD_FAILED,
    SIGNIN_SIGNING_IN,
    SIGNIN_SIGNED_IN,
    SIGNIN_EMAIL_SIGNIN_FAILED,
    SIGNIN_SOCIAL_SIGNIN_FAILED_WRONG_PROVIDER,
    SIGNIN_SOCIAL_SIGNIN_FAILED,
    SIGNIN_CREATE_ACCOUNT_FAILED,
    SIGNIN_SIGNIN_EMAIL_SENT,
    SIGNIN_SIGNIN_EMAIL_SEND_FAILED,
    SIGNIN_VERIFY_EMAIL_LINK_FAILED,
    SIGNIN_TOGGLE_PASSWORD_VISIBLE,
    SIGNIN_TAB_CLICKED,
    SIGNIN_FORGOT_PASSWORD_CLICKED,
    SIGNIN_FIELD_UPDATED,
    SIGNIN_EPIC_STARTED,
    SIGNIN_EPIC_FINISHED
} from '../actions/SignInActions';
import { SignInPageState } from '../types';
import { t } from '../i18n';

export const initialState: SignInPageState = {
    email: '',
    password: '',
    name: '',
    resetPassword: false,
    emailMessage: '',
    socialMessage: '',
    passwordInvalid: false,
    createAccountButtonEnabled: false,
    signInButtonEnabled: false,
    socialSigninButtonsEnabled: true,
    magicLinkAndResetButtonsEnabled: false,
    passwordVisible: false,
    currentTab: 'signin',
    epicInProgress: null
};

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const passwordRegex = /^.{8,}/;

const applyAction = (state: SignInPageState, action: SignInPageAction): SignInPageState => {

    switch (action.type) {
        case SIGNIN_FIELD_UPDATED:
            return { ...state, [`${action.field}`]: action.value };
        case SIGNIN_TOGGLE_PASSWORD_VISIBLE:
            return { ...state, passwordVisible: !state.passwordVisible };
        case SIGNIN_FORGOT_PASSWORD_CLICKED:
            return { ...state, resetPassword: true };
        case SIGNIN_RESET_PASSWORD_STARTED:
            return {
                ...state,
                emailMessage: t('signin.resetting-password')
            };
        case SIGNIN_RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                emailMessage: t('signin.check-email'),
                socialMessage: '',
                resetPassword: false,
                password: ''
            };
        case SIGNIN_SIGNING_IN:
            return {
                ...state,
                emailMessage: '',
                socialMessage: ''
            };
        case SIGNIN_SIGNED_IN:
            return {
                ...state,
                password: ''
            };
        case SIGNIN_EMAIL_SIGNIN_FAILED:
            switch (action.code) {
                case 'auth/user-not-found':
                    return {
                        ...state,
                        emailMessage: t('signin.account-doesnt-exist')
                    };
                case 'auth/wrong-password':
                    return {
                        ...state,
                        emailMessage: t('signin.password-incorrect')
                    };
                default:
                    return {
                        ...state,
                        emailMessage: `${t('signin.unknown-failure')}: ${action.error}`
                    };
            }
        case SIGNIN_SOCIAL_SIGNIN_FAILED_WRONG_PROVIDER:
            return {
                ...state,
                socialMessage:  `${t('signin.please-sign-in-with')}: ${action.providers.join(t('signin.provider-concatenation-or-seperator'))}`, 
                email: action.providers.indexOf('password') >= 0 ? action.email : state.email
            };
        case SIGNIN_SOCIAL_SIGNIN_FAILED:
            return {
                ...state,
                socialMessage: action.message
            };
        case SIGNIN_SIGNIN_EMAIL_SENT:
            return {
                ...state,
                emailMessage: t('signin.check-email'),
                socialMessage: '',
                resetPassword: false,
                password: ''
            };
        case SIGNIN_TAB_CLICKED:
            return {
                ...state,
                resetPassword: false,
                emailMessage: '',
                currentTab: action.tabName
            };
        case SIGNIN_SIGNIN_EMAIL_SEND_FAILED:
        case SIGNIN_VERIFY_EMAIL_LINK_FAILED:
        case SIGNIN_CREATE_ACCOUNT_FAILED:
        case SIGNIN_RESET_PASSWORD_FAILED:
            return {
                ...state,
                emailMessage: `${t('signin.unknown-failure')}: ${action.message}`
            };
        case SIGNIN_EPIC_STARTED:
            return {
                ...state,
                epicInProgress: action.epic,
                socialSigninButtonsEnabled: false
            };
        case SIGNIN_EPIC_FINISHED:
            return {
                ...state,
                epicInProgress: null,
                socialSigninButtonsEnabled: true
            };            
        default: return state;
    }
};

export default (state: SignInPageState = initialState, action: SignInPageAction): SignInPageState => {

    const deriveFlags = (s: SignInPageState): SignInPageState => {
        const { email, password, name } = s;
        return {
            ...s,
            createAccountButtonEnabled: !!(emailRegex.test(email) && passwordRegex.test(password) && name && name.length > 6) && !s.epicInProgress,
            signInButtonEnabled: emailRegex.test(email) && passwordRegex.test(password) && !s.epicInProgress,
            magicLinkAndResetButtonsEnabled: emailRegex.test(email) && !s.epicInProgress,
            passwordInvalid: !!password && !passwordRegex.test(password)
        };
    };

    const newState = applyAction(state, action);

    return (state !== newState) ? deriveFlags(newState) : newState;
};
