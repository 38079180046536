import moment from 'moment';
import { ActionResult } from './Common';
import { UserBooking, BookingItineraryItem, NewBookingFormSchool, SchoolInstantBookingSettings, InstantOffer, BookingCommissionModel } from '../types';
import { FirestoreChangeType } from '../constants';
import { SendBookingMessage } from '../api/Commands';

export const BOOKING_REQUEST_FIELD_CHANGED = 'BOOKING_REQUEST_FIELD_CHANGED';
export type BOOKING_REQUEST_FIELD_CHANGED = typeof BOOKING_REQUEST_FIELD_CHANGED;

export const NEW_BOOKING_SCHOOLS_CHANGED = 'NEW_BOOKING_SCHOOLS_CHANGED';
export type NEW_BOOKING_SCHOOLS_CHANGED = typeof NEW_BOOKING_SCHOOLS_CHANGED;

export const NEW_BOOKING_INSTANT_SETTINGS_CHANGED = 'NEW_BOOKING_INSTANT_SETTINGS_CHANGED';
export type NEW_BOOKING_INSTANT_SETTINGS_CHANGED = typeof NEW_BOOKING_INSTANT_SETTINGS_CHANGED;

export const NEW_BOOKING_DATES_CHANGED = 'NEW_BOOKING_DATES_CHANGED';
export type NEW_BOOKING_DATES_CHANGED = typeof NEW_BOOKING_DATES_CHANGED;

export const NEW_BOOKING_INSTANT_OFFER_CHANGED = 'NEW_BOOKING_INSTANT_OFFER_CHANGED';
export type NEW_BOOKING_INSTANT_OFFER_CHANGED = typeof NEW_BOOKING_INSTANT_OFFER_CHANGED;

export const ADD_NEW_BOOKING_SCHOOL_ACTIVITY = 'ADD_NEW_BOOKING_SCHOOL_ACTIVITY';
export type ADD_NEW_BOOKING_SCHOOL_ACTIVITY = typeof ADD_NEW_BOOKING_SCHOOL_ACTIVITY;

export const ADD_NEW_BOOKING_ACTIVITY = 'ADD_NEW_BOOKING_ACTIVITY';
export type ADD_NEW_BOOKING_ACTIVITY = typeof ADD_NEW_BOOKING_ACTIVITY;

export const CHANGE_NEW_BOOKING_ACTIVITY_SKILL = 'CHANGE_NEW_BOOKING_ACTIVITY_SKILL';
export type CHANGE_NEW_BOOKING_ACTIVITY_SKILL = typeof CHANGE_NEW_BOOKING_ACTIVITY_SKILL;

export const REMOVE_NEW_BOOKING_ACTIVITY = 'REMOVE_NEW_BOOKING_ACTIVITY';
export type REMOVE_NEW_BOOKING_ACTIVITY = typeof REMOVE_NEW_BOOKING_ACTIVITY;

export const NEW_BOOKING_PEOPLE_CHANGED = 'NEW_BOOKING_PEOPLE_CHANGED';
export type NEW_BOOKING_PEOPLE_CHANGED = typeof NEW_BOOKING_PEOPLE_CHANGED;

export const CREATE_BOOKING = 'CREATE_BOOKING';
export type CREATE_BOOKING = typeof CREATE_BOOKING;

export const CREATE_INSTANT_BOOKING = 'CREATE_INSTANT_BOOKING';
export type CREATE_INSTANT_BOOKING = typeof CREATE_INSTANT_BOOKING;

export const LOAD_BOOKING = 'LOAD_BOOKING';
export type LOAD_BOOKING = typeof LOAD_BOOKING;

export const USER_BOOKING_UPDATED = 'USER_BOOKING_UPDATED';
export type USER_BOOKING_UPDATED = typeof USER_BOOKING_UPDATED;

export const CANCEL_BOOKING = 'CANCEL_BOOKING';
export type CANCEL_BOOKING = typeof CANCEL_BOOKING;

export const ACCEPT_BOOKING_OFFERS = 'ACCEPT_BOOKING_OFFERS';
export type ACCEPT_BOOKING_OFFERS = typeof ACCEPT_BOOKING_OFFERS;

export const REJECT_BOOKING_OFFER = 'REJECT_BOOKING_OFFER';
export type REJECT_BOOKING_OFFER = typeof REJECT_BOOKING_OFFER;

export const ADD_ITINERARY_ITEM = 'ADD_ITINERARY_ITEM';
export type ADD_ITINERARY_ITEM = typeof ADD_ITINERARY_ITEM;

export const REMOVE_ITINERARY_ITEM = 'REMOVE_ITINERARY_ITEM';
export type REMOVE_ITINERARY_ITEM = typeof REMOVE_ITINERARY_ITEM;

export const PAYMENT_DETAILS_ENTERED = 'PAYMENT_DETAILS_ENTERED';
export type PAYMENT_DETAILS_ENTERED = typeof PAYMENT_DETAILS_ENTERED;

export const SEND_USER_BOOKING_MESSAGE = 'SEND_USER_BOOKING_MESSAGE';
export type SEND_USER_BOOKING_MESSAGE = typeof SEND_USER_BOOKING_MESSAGE;

export const SEND_SCHOOL_BOOKING_MESSAGE = 'SEND_SCHOOL_BOOKING_MESSAGE';
export type SEND_SCHOOL_BOOKING_MESSAGE = typeof SEND_SCHOOL_BOOKING_MESSAGE;

export interface BookingRequestFieldChanged {
    type: BOOKING_REQUEST_FIELD_CHANGED;
    value: {
        activity?: string;
        field: string;
        value: any;
    };
}

export interface NewBookingSchoolsChanged {
    type: NEW_BOOKING_SCHOOLS_CHANGED;
    value: {
        schools: NewBookingFormSchool[];
    };
}

export interface NewBookingDatesChanged {
    type: NEW_BOOKING_DATES_CHANGED;
    value: {
        startDate: moment.Moment | null;
        endDate: moment.Moment | null;
    };
}

export interface NewBookingInstantSettingsChanged {
    type: NEW_BOOKING_INSTANT_SETTINGS_CHANGED;
    value: {
        settings: { [schoolId: string]: SchoolInstantBookingSettings };
        commissionModels: { [schoolId: string]: BookingCommissionModel };
    };
}

export interface NewBookingInstantOfferChanged {
    type: NEW_BOOKING_INSTANT_OFFER_CHANGED;
    value: {
        offer: InstantOffer;
    };
}

export interface AddNewBookingSchoolActivity {
    type: ADD_NEW_BOOKING_SCHOOL_ACTIVITY;
    value: {
        schoolId: string;
        activity: string;
    };
}

export interface AddNewBookingActivity {
    type: ADD_NEW_BOOKING_ACTIVITY;
    value: {
        activity: string;
    };
}

export interface ChangeNewBookingActivitySkill {
    type: CHANGE_NEW_BOOKING_ACTIVITY_SKILL;
    value: {
        activity: string;
        skill: string;
    };
}

export interface RemoveNewBookingActivity {
    type: REMOVE_NEW_BOOKING_ACTIVITY;
    value: {
        activity: string;
    };
}

export interface NewBookingPeopleChanged {
    type: NEW_BOOKING_PEOPLE_CHANGED;
    value: {
        people: {
            name: string;
        }[];
    };
}

export interface CreateBooking {
    type: CREATE_BOOKING;
    value: {
        recaptchaResponse: string;
    };
}

export interface CreateInstantBooking {
    type: CREATE_INSTANT_BOOKING;
    value: {
        stripeToken: string;
        name: string;
        email: string;
    };
}

export interface LoadBooking {
    type: LOAD_BOOKING;
    value: {
        bookingId?: string;
        bookingRef?: string;
        userEmail: string;
    };
}

export interface CancelBooking {
    type: CANCEL_BOOKING;
    value: string;
}

export interface AcceptBookingOffers {
    type: ACCEPT_BOOKING_OFFERS;
    value: {
        bookingId: string;
    };
}

export interface RejectBookingOffer {
    type: REJECT_BOOKING_OFFER;
    value: {
        bookingId: string;
        offerId: string;
        schoolId: string;
        reason: string;
    };
}

export interface UserBookingUpdated {
    type: USER_BOOKING_UPDATED;
    value: {
        id: string;
        change: FirestoreChangeType;
        data: UserBooking;
    };
}

export interface PaymentDetailsEntered {
    type: PAYMENT_DETAILS_ENTERED;
    value: {
        bookingId: string;
        stripeToken: string;
        schoolId?: string;
    };
}

export interface AddItineraryItem {
    type: ADD_ITINERARY_ITEM;
    value: BookingItineraryItem;
}

export interface RemoveItineraryItem {
    type: REMOVE_ITINERARY_ITEM;
    value: {
        bookingId: string;
        offerId: string;
        schoolId: string;
        day: string;
    };
}

export interface SendUserBookingMessage {
    type: SEND_USER_BOOKING_MESSAGE;
    value: SendBookingMessage;
}

export interface SendSchoolBookingMessage {
    type: SEND_SCHOOL_BOOKING_MESSAGE;
    value: SendBookingMessage;
}

export type BookingAction =
    BookingRequestFieldChanged |
    NewBookingSchoolsChanged |
    NewBookingInstantSettingsChanged |
    NewBookingInstantOfferChanged |
    NewBookingDatesChanged |
    AddNewBookingSchoolActivity |
    AddNewBookingActivity |
    RemoveNewBookingActivity |
    ChangeNewBookingActivitySkill |
    NewBookingPeopleChanged |
    CreateBooking |
    LoadBooking |
    CancelBooking |
    UserBookingUpdated |
    PaymentDetailsEntered |
    AcceptBookingOffers |
    RejectBookingOffer |
    AddItineraryItem |
    RemoveItineraryItem |
    SendUserBookingMessage |
    CreateInstantBooking |
    ActionResult;

export function bookingRequestFieldChanged(field: string, value: any, activity?: string): BookingRequestFieldChanged {
    return {
        type: BOOKING_REQUEST_FIELD_CHANGED,
        value: {
            activity,
            field,
            value
        }
    };
}

export function newBookingSchoolsChanged(schools: NewBookingFormSchool[]): NewBookingSchoolsChanged {
    return {
        type: NEW_BOOKING_SCHOOLS_CHANGED,
        value: {
            schools
        }
    };
}

export function newBookingInstantOfferChanged(offer: InstantOffer): NewBookingInstantOfferChanged {
    return {
        type: NEW_BOOKING_INSTANT_OFFER_CHANGED,
        value: {
            offer
        }
    };
}

export function addNewBookingActivity(activity: string): AddNewBookingActivity {
    return {
        type: ADD_NEW_BOOKING_ACTIVITY,
        value: {
            activity
        }
    };
}

export function changeNewBookingActivitySkill(activity: string, skill: string): ChangeNewBookingActivitySkill {
    return {
        type: CHANGE_NEW_BOOKING_ACTIVITY_SKILL,
        value: {
            activity,
            skill,
        }
    };
}

export function removeNewBookingActivity(activity: string): RemoveNewBookingActivity {
    return {
        type: REMOVE_NEW_BOOKING_ACTIVITY,
        value: {
            activity
        }
    };
}

export function addNewBookingSchoolActivity(item: { schoolId: string; activity: string }): AddNewBookingSchoolActivity {
    return {
        type: ADD_NEW_BOOKING_SCHOOL_ACTIVITY,
        value: item
    };
}

export function newBookingPeopleChanged(people: { name: string }[]): NewBookingPeopleChanged {
    return {
        type: NEW_BOOKING_PEOPLE_CHANGED,
        value: { people }
    };
}

export function newBookingDatesChanged(value: { startDate: moment.Moment | null; endDate: moment.Moment | null }): NewBookingDatesChanged {
    return {
        type: NEW_BOOKING_DATES_CHANGED,
        value
    };
}

export function userBookingUpdated(change: firebase.firestore.DocumentChange): UserBookingUpdated {
    return {
        type: USER_BOOKING_UPDATED,
        value: {
            id: change.doc.id,
            change: change.type,
            data: change.doc.data() as UserBooking
        }
    };
}

export function createBooking(recaptchaResponse: string): CreateBooking {
    return {
        type: CREATE_BOOKING,
        value: {
            recaptchaResponse
        }
    };
}

export function createInstantBooking(stripeToken: string, name: string, email: string): CreateInstantBooking {
    return {
        type: CREATE_INSTANT_BOOKING,
        value: {
            stripeToken,
            name,
            email
        }
    };
}

export function loadBooking(userEmail: string, bookingId?: string, bookingRef?: string): LoadBooking {
    return {
        type: LOAD_BOOKING,
        value: {
            bookingId,
            bookingRef,
            userEmail
        }
    };
}

export const cancelBooking = (bookingId: string): CancelBooking => {
    return {
        type: CANCEL_BOOKING,
        value: bookingId
    };
};

export const acceptBookingOffers = (bookingId: string): AcceptBookingOffers => {
    return {
        type: ACCEPT_BOOKING_OFFERS,
        value: {
            bookingId
        }
    };
};

export const rejectBookingOffer = (bookingId: string, schoolId: string, offerId: string, reason: string): RejectBookingOffer => {
    return {
        type: REJECT_BOOKING_OFFER,
        value: {
            bookingId,
            offerId,
            schoolId,
            reason
        }
    };
};

export const paymentDetailsEntered = (bookingId: string, stripeToken: string, schoolId?: string): PaymentDetailsEntered => {
    return {
        type: PAYMENT_DETAILS_ENTERED,
        value: {
            bookingId,
            schoolId,
            stripeToken
        }
    };
};

export const addItineraryItem = (item: BookingItineraryItem): AddItineraryItem => {
    return {
        type: ADD_ITINERARY_ITEM,
        value: item
    };
};

export const removeItineraryItem = (bookingId: string, schoolId: string, offerId: string, day: string): RemoveItineraryItem => {
    return {
        type: REMOVE_ITINERARY_ITEM,
        value: {
            bookingId,
            offerId,
            schoolId,
            day
        }
    };
};

export const sendUserBookingMessage = (message: SendBookingMessage): SendUserBookingMessage => {
    return {
        type: SEND_USER_BOOKING_MESSAGE,
        value: message
    };
};

export const sendSchoolBookingMessage = (message: SendBookingMessage): SendSchoolBookingMessage => {
    return {
        type: SEND_SCHOOL_BOOKING_MESSAGE,
        value: message
    };
};
