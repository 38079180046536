import * as React from 'react';

interface FloatContainerProps {
    className?: string;
    visible: boolean;
    children?: any;
}

export const FloatContainer = ({ children, visible, className }: FloatContainerProps) => {
    if (!visible) {
        return null;
    }
    return (
        <div
            className={className}
            style={{
                background: 'rgba(0, 0, 0, 0.2)',
                position: 'absolute',
                height: '100%',
                width: '100%',
                zIndex: 999
            }}
        >
            {children}
        </div>
    );
};