import {
    InstructorAction,
    INSTRUCTOR_UPDATED,
    GET_INSTRUCTOR_PROFILE,
    INSTRUCTOR_KUDOS_UPDATED,
    ACTIVATE_INSTRUCTOR_ACCOUNT,
    INSTRUCTOR_SESSION_UPDATED
} from '../actions/InstructorActions';
import { InstructorStoreState } from '../types';
import _ from 'lodash';
import { ACTION_SUCCESS, ACTION_FAILURE } from '../actions/Common';
import { FIRESTORE_REMOVED } from '../constants';

const initialState: InstructorStoreState = {
    getInstructorProfileAction: null,
    getInstructorProfileResult: null,
    instructor: null,
    sessions: {},
    activateAccountResult: null,
    kudos: {},
};

export default (state: InstructorStoreState = initialState, action: InstructorAction): InstructorStoreState => {
    switch (action.type) {
        case INSTRUCTOR_UPDATED:
            return {
                ...state,
                instructor: action.value.data
            };
        case INSTRUCTOR_KUDOS_UPDATED:
            if (action.value.change === FIRESTORE_REMOVED) {
                return {
                    ...state,
                    kudos: _.omit(state.kudos, action.value.id)
                };
            }
            return {
                ...state,
                kudos: {
                    ...state.kudos,
                    [action.value.id]: action.value.kudos
                }
            };
        case INSTRUCTOR_SESSION_UPDATED:
            if (action.value.change === FIRESTORE_REMOVED) {
                return {
                    ...state,
                    sessions: _.omit(state.sessions, action.value.id)
                };
            }
            return {
                ...state,
                sessions: {
                    ...state.sessions,
                    [action.value.id]: action.value.session
                }
            };
        case GET_INSTRUCTOR_PROFILE:
            return {
                ...state,
                getInstructorProfileAction: action
            };
        case ACTION_SUCCESS:
            if (action.action.type === GET_INSTRUCTOR_PROFILE) {
                return {
                    ...state,
                    getInstructorProfileResult: action.value
                };
            } else if (action.action.type === ACTIVATE_INSTRUCTOR_ACCOUNT) {
                return {
                    ...state,
                    activateAccountResult: { success: true, error: null }
                };
            }
            return state;
        case ACTION_FAILURE:
            if (action.action.type === ACTIVATE_INSTRUCTOR_ACCOUNT) {
                return {
                    ...state,
                    activateAccountResult: { success: false, error: action.error }
                };
            }
            return state;
        default:
            return state;
    }
};
