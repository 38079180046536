import * as React from 'react';
import './Error.css';

const Error = ({ children }: { children: any }) => {
    return (
        <div className="errorMessage">
            {children}
        </div>
    );
};

export default Error;
