export type UserSessionDictionary = { [sessionId: string]: UserSession };
export type SchoolSessionDictionary = { [sessionId: string]: SchoolSession };
export type SchoolSessionsDictionary = { [schoolId: string]: SchoolSessionDictionary };

export const SESSION_STATUS_DRAFT = 'draft';
export type SESSION_STATUS_DRAFT = typeof SESSION_STATUS_DRAFT;

export const SESSION_STATUS_PLANNED = 'planned';
export type SESSION_STATUS_PLANNED = typeof SESSION_STATUS_PLANNED;

export const SESSION_STATUS_CONFIRMED = 'confirmed';
export type SESSION_STATUS_CONFIRMED = typeof SESSION_STATUS_CONFIRMED;

export const SESSION_STATUS_COMPLETED = 'completed';
export type SESSION_STATUS_COMPLETED = typeof SESSION_STATUS_COMPLETED;

export const SESSION_STATUS_CANCELLED = 'cancelled';
export type SESSION_STATUS_CANCELLED = typeof SESSION_STATUS_CANCELLED;

export const SESSION_TYPE_LESSONS = 'lessons';
export type SESSION_TYPE_LESSONS = typeof SESSION_TYPE_LESSONS;

export const SESSION_TYPE_SUPERVISION = 'supervision';
export type SESSION_TYPE_SUPERVISION = typeof SESSION_TYPE_SUPERVISION;

export const SESSION_TYPE_GUIDING = 'guiding';
export type SESSION_TYPE_GUIDING = typeof SESSION_TYPE_GUIDING;

export const SESSION_TYPE_RENTAL = 'rental';
export type SESSION_TYPE_RENTAL = typeof SESSION_TYPE_RENTAL;

export const SESSION_FORMAT_PRIVATE = 'private';
export type SESSION_FORMAT_PRIVATE = typeof SESSION_FORMAT_PRIVATE;

export const SESSION_FORMAT_SEMI_PRIVATE = 'semi-private';
export type SESSION_FORMAT_SEMI_PRIVATE = typeof SESSION_FORMAT_SEMI_PRIVATE;

export const SESSION_FORMAT_GROUP = 'group';
export type SESSION_FORMAT_GROUP = typeof SESSION_FORMAT_GROUP;

export type SessionStatus =
    | SESSION_STATUS_DRAFT
    | SESSION_STATUS_PLANNED
    | SESSION_STATUS_CONFIRMED
    | SESSION_STATUS_COMPLETED
    | SESSION_STATUS_CANCELLED;

export const SessionStatusArray = [
    SESSION_STATUS_DRAFT,
    SESSION_STATUS_PLANNED,
    SESSION_STATUS_CONFIRMED,
    SESSION_STATUS_COMPLETED,
    SESSION_STATUS_CANCELLED
];

export const LessonFormats = [
    SESSION_FORMAT_PRIVATE,
    SESSION_FORMAT_SEMI_PRIVATE,
    SESSION_FORMAT_GROUP,
];

export const SessionTypes = [
    {
        name: SESSION_TYPE_LESSONS,
        formats: LessonFormats,
    },
    {
        name: SESSION_TYPE_SUPERVISION,
        formats: [],
    },
    {
        name: SESSION_TYPE_GUIDING,
        formats: [],
    },
    {
        name: SESSION_TYPE_RENTAL,
        formats: [],
    }
];

export const OFFER_PRICE_PER_PERSON_HOUR = '/Person/Hour';
export const OFFER_PRICE_PER_PERSON_DAY = '/Person/Day';
export const OFFER_PRICE_PER_PERSON = '/Person';
export const OFFER_PRICE_PER_HOUR = '/Hour';
export const OFFER_PRICE_PER_DAY = '/Day';
export const OFFER_PRICE_PER_FIXED = 'Fixed';

export const OfferPriceOptions = [
    OFFER_PRICE_PER_PERSON_HOUR,
    OFFER_PRICE_PER_PERSON_DAY,
    OFFER_PRICE_PER_PERSON,
    OFFER_PRICE_PER_HOUR,
    OFFER_PRICE_PER_DAY,
    OFFER_PRICE_PER_FIXED,
];

export const getSessionFormats = (type: string | null) => {
    if (!type) {
        return [];
    }
    const sessionType = SessionTypes.find(st => st.name === type);
    if (!sessionType) {
        return [];
    }
    return sessionType.formats;
};

export const getNumInstructors = ({lessonFormat, people}: { lessonFormat: string, people: Array<any> }) => {
    if (lessonFormat === LessonFormats[0]) {
        return people.length;
    } else if (lessonFormat === LessonFormats[1]) {
        return Math.ceil(people.length / 2);
    } else if (lessonFormat === LessonFormats[2]) {
        return 1;
    }
    return 0;
};

export interface SchoolSession {
    sessionId: string;
    schoolId: string;
    instructorEmails: string[];
    activity: string;
    format: string;
    type: string;
    bookingMembers: SessionBookingMember[];
    start: string;
    durationHours: number;
    completedHours: number;
    spot: SessionSpot | null;
    comments: string;
    status: SessionStatus;
}

export interface SessionSpot {
    spotId: string;
    name: string;
    lat: number;
    lng: number;
}

export interface SchoolSessionMember {
    bookingId: string;
    bookingRef: string;
    bookingType: string;
    bookingStatus: string;
    activity: string;
    start: string;
    end: string;
    userId: string;
    userName: string;
    memberEmail: string;
    memberName: string;
    memberSkill: string;
}

export interface SessionBookingMember {
    bookingId: string;
    memberEmail: string;
    memberName: string;
}

export interface UserSession {
    bookingId: string;
    sessionId: string;
    userId: string;
    userEmail: string;
    userName: string;
    schoolId: string;
    schoolName: string;
    instructorNames: string[];
    activity: string;
    format: string;
    type: string;
    memberNames: string[];
    start: string;
    durationHours: number;
    completedHours: number;
    spot: SessionSpot | null;
    comments: string;
    status: SessionStatus;
}