import * as React from 'react';

interface BlurContainerProps {
    className: string;
    blur: boolean;
    children?: any;
}

export const BlurContainer = ({ children, blur, className }: BlurContainerProps) => {
    return (
        <div
            className={className}
            style={{
                filter: blur ? 'blur(5px)' : '',
                height: '100%',
                width: '100%',
            }}
        >
            {children}
        </div>
    );
};