import * as React from 'react';
import { Suspense, lazy } from 'react';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { createEpicMiddleware } from 'redux-observable';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Switch, Redirect } from 'react-router';
import { Route } from 'react-router-dom';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import './App.css';
import './stripe.css';
import createRootReducer from './reducers';
import { createBrowserHistory } from 'history';
import epics from './epics';
import Header from './components/Header';
import { NotFound, Loading } from './components/common';
import AuthenticatedRoute from './containers/common/AuthenticatedRoute';
import { i18n } from './i18n';
import { I18nextProvider, NamespacesConsumer } from 'react-i18next';
import CookieBanner from './containers/CookieBanner';
import { PrivacyPage } from './components/policy/PrivacyPolicyPage';
import { CookiePolicyPage } from './components/policy/CookiePolicyPage';
import { TermsPolicyPage } from './components/policy/TermsPolicyPage';
import { Footer } from './components/Footer';

const epicMiddleware = createEpicMiddleware();
const composeEnhancers = composeWithDevTools({ name: 'Outdr' });

const history = createBrowserHistory();

const store = createStore(
  createRootReducer(history),
  undefined,
  composeEnhancers(
    applyMiddleware(
      routerMiddleware(history),
      epicMiddleware
    )
  )
);

epicMiddleware.run(epics);

const SearchPage = lazy(() => import(
  /* webpackChunkName: 'SearchPage' */
  /* webpackPrefetch: true */
  './containers/SearchPage'));
const SchoolPage = lazy(() => import(/* webpackChunkName: 'SchoolPage' */ './containers/SchoolPage'));
const BookingPage = lazy(() => import(/* webpackChunkName: 'BookingPage' */ './containers/BookingPage'));
const DestinationPage = lazy(() => import(/* webpackChunkName: 'DestinationPage' */ './containers/DestinationPage'));
const ActivityPage = lazy(() => import(/* webpackChunkName: 'ActivityPage' */ './containers/ActivityPage'));
const AccountPage = lazy(() => import(/* webpackChunkName: 'AccountPage' */ './containers/AccountPage'));
const BookingsPage = lazy(() => import(/* webpackChunkName: 'BookingsPage' */ './containers/BookingsPage'));
const CheckoutPage = lazy(() => import(/* webpackChunkName: 'CheckoutPage' */ './containers/CheckoutPage'));
const SchoolBookingsPage = lazy(() => import(/* webpackChunkName: 'SchoolBookingsPage' */ './containers/schools/SchoolBookingsPage'));
const SchoolSettingsPage = lazy(() => import(/* webpackChunkName: 'SchoolSettingsPage' */ './containers/schools/SchoolSettingsPage'));
const AboutPage = lazy(() => import(/* webpackChunkName: 'AboutPage' */ './components/AboutPage'));
const SchoolListingPage = lazy(() => import(/* webpackChunkName: 'SchoolListingPage' */ './containers/schools/SchoolListingPage'));
const SchoolJobsPage = lazy(() => import(/* webpackChunkName: 'SchoolJobsPage' */ './containers/schools/SchoolJobsPage'));
const SignInPage = lazy(() => import(/* webpackChunkName: 'SignInPage' */  './containers/SignInPage'));
const SignOutPage = lazy(() => import(/* webpackChunkName: 'SignOutPage' */  './components/SignOutPage'));
const InstructorProfilePage = lazy(() => import(/* webpackChunkName: 'InstructorProfilePage' */  './containers/instructors/InstructorProfilePage'));
const InstructorDashboardPage = lazy(() => import(/* webpackChunkName: 'InstructorDashboardPage' */  './containers/instructors/InstructorDashboardPage'));
// const InstructorOnboardPage = lazy(() => import(/* webpackChunkName: 'InstructorOnboardPage' */  './containers/instructors/InstructorOnboardPage'));
const SchoolOnboardPage = lazy(() => import(/* webpackChunkName: 'SchoolOnboardPage' */  './containers/schools/SchoolOnboardPage'));
const GiveAwayPage = lazy(() => import(/* webpackChunkName: 'GiveAwayPage' */  './containers/GiveAwayPage'));
const ProviderTermsPolicyPageEU = lazy(() => import(/* webpackChunkName: 'ProviderTermsPolicyPageEU' */ './components/policy/ProviderTermsPolicyPage_eu'));
const ProviderTermsPolicyPageEU2 = lazy(() => import(/* webpackChunkName: 'ProviderTermsPolicyPageEU2' */ './components/policy/ProviderTermsPolicyPage_eu2'));
const ProviderTermsPolicyPageGlobal = lazy(() => import(/* webpackChunkName: 'ProviderTermsPolicyPageGlobal' */  './components/policy/ProviderTermsPolicyPage_global'));
const ProviderTermsPolicyPageGlobal2 = lazy(() => import(/* webpackChunkName: 'ProviderTermsPolicyPageGlobal2' */  './components/policy/ProviderTermsPolicyPage_global2'));
const ProviderTermsPolicyPageDefault = lazy(() => import(/* webpackChunkName: 'ProviderTermsPolicyPageDefault' */  './components/policy/ProviderTermsPolicyPage_default'));

import(
  /* webpackChunkName: 'FirebaseDependentEpics' */
  './epics/FirebaseDependentEpics')
  .then(fde => {
    epicMiddleware.run(fde.default);
    console.log('firebase epics module loaded');
  });

const BrowserDetection = ({ children }: { children: JSX.Element }) => {
  const isIE = !!(document as any).documentMode;
  // const isEdge = !isIE && !!(window as any).StyleMedia;

  if (!isIE) {
    return children;
  }

  return (
    <div style={{ background: 'black', color: 'white', padding: '20px', margin: '20px' }}>
      <h1>
        {
          isIE
            ? 'Please use a modern browser '
            : 'Please use another browser '
        }
        😏
        </h1>
      <p>
        Sorry, we do not support Internet Explorer, please try another browser.
        </p>
      <span style={{ display: 'inline' }}>
        <a style={{ background: 'black', color: 'white', fontSize: '16px' }} href="https://www.mozilla.org/firefox/download/">Firefox</a>
        &nbsp;|&nbsp;
          <a style={{ background: 'black', color: 'white', fontSize: '16px' }} href="https://www.google.com/chrome/">Chrome</a>
        &nbsp;|&nbsp;
          <a style={{ background: 'black', color: 'white', fontSize: '16px' }} href="https://www.opera.com/download/">Opera</a>
      </span>
    </div>
  );
};

const App = () => (
  <BrowserDetection>
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <NamespacesConsumer ns="">
          {
            () =>
              <ConnectedRouter history={history}>
                <div className="App">
                  <Switch>
                    <Route exact={false} path="/manage/school/:schoolId" component={Header} />
                    <Route exact={false} path="/" component={Header} />
                  </Switch>
                  <Suspense fallback={<Loading />}>
                    <Switch>
                      <Route exact={true} path="/" component={SearchPage} />
                      <Route exact={true} path="/about" component={AboutPage} />
                      <Route exact={true} path="/school/:schoolId" component={SchoolPage} />
                      <Route exact={true} path="/school/:schoolId/book" component={SchoolPage} />
                      <Route exact={true} path="/school/:schoolId/onboard" component={SchoolOnboardPage} />
                      <Route exact={true} path="/destination/:nameOrId" component={DestinationPage} />
                      <Route exact={true} path="/destination/:nameOrId/book" component={DestinationPage} />
                      <Route exact={true} path="/activity/:activityId" component={ActivityPage} />
                      <Route exact={true} path="/activity/:activityId" component={ActivityPage} />
                      <Route exact={true} path="/giveaway/:giveAwayId" component={GiveAwayPage} />
                      <Route exact={true} path="/booking/:bookingId/:email" component={BookingPage} />
                      <Route path="/signin" component={SignInPage} />
                      <Route exact={true} path="/signout" component={SignOutPage} />
                      <AuthenticatedRoute path="/checkout/:bookingId" component={CheckoutPage} />
                      <AuthenticatedRoute path="/account" component={AccountPage} />
                      <AuthenticatedRoute path="/bookings" component={BookingsPage} />
                      <AuthenticatedRoute exact={true} path="/manage/school/:schoolId" component={SchoolBookingsPage} schoolsOnly={true} />
                      <AuthenticatedRoute exact={true} path="/manage/school/:schoolId/settings" component={SchoolSettingsPage} schoolsOnly={true} />
                      <AuthenticatedRoute exact={true} path="/manage/school/:schoolId/listing" component={SchoolListingPage} schoolsOnly={true} />
                      <AuthenticatedRoute exact={true} path="/manage/school/:schoolId/jobs" component={SchoolJobsPage} schoolsOnly={true} />
                      <Route exact={true} path="/instructor/:accountName" component={InstructorProfilePage} />
                      <Route exact={false} path="/instructor/:accountName/onboard" component={InstructorProfilePage} />
                      <AuthenticatedRoute exact={false} path="/instructor/:accountName/dashboard" component={InstructorDashboardPage} />
                      <Route exact={true} path="/policy/privacy" component={PrivacyPage} />
                      <Route exact={true} path="/policy/cookies" component={CookiePolicyPage} />
                      <Route exact={true} path="/policy/terms" component={TermsPolicyPage} />
                      <Switch>
                        <AuthenticatedRoute exact={true} path="/policy/provider/terms/eu" component={ProviderTermsPolicyPageEU} />
                        <AuthenticatedRoute exact={true} path="/policy/provider/terms/eu2" component={ProviderTermsPolicyPageEU2} />
                        <AuthenticatedRoute exact={true} path="/policy/provider/terms/global" component={ProviderTermsPolicyPageGlobal} />
                        <AuthenticatedRoute exact={true} path="/policy/provider/terms/global2" component={ProviderTermsPolicyPageGlobal2} />
                        <AuthenticatedRoute exact={true} path="/policy/provider/terms/*" component={ProviderTermsPolicyPageDefault} />
                      </Switch>
                      <Redirect path="/ref" to="/" />
                      <Route path="*" component={NotFound} />
                    </Switch>
                  </Suspense>
                  <Switch>
                    <Route exact={false} path="/manage/school/:schoolId" component={() => null} />
                    <Route exact={false} path="/" component={Footer} />
                  </Switch>
                  <CookieBanner />
                </div>
              </ConnectedRouter>
          }
        </NamespacesConsumer>
      </I18nextProvider>
    </Provider>
  </BrowserDetection>
);

export default App;
