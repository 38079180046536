import { HasId } from '.';

export interface SchoolUserForm {
    name: string;
    email: string;
    initials: string;
    nickname: string;
    mobile: string;
    languages: string;
    active: boolean;
    activities: string;

    // NB: these fields aren't saved directly
    roles: string[];
    photoFile?: any;
}

export interface SchoolUserInfo extends SchoolUserForm {
    photoUrl: string;
}

export interface SchoolUser {
    info: SchoolUserInfo;
    permissions: SchoolUserPermissions;
}

export type SchoolUserDictionary = { [email: string]: SchoolUser };
export type SchoolUsersDictionary = { [schoolId: string]: SchoolUserDictionary };

export interface SchoolUserPermissions {
    isOwner: boolean | undefined;
    isAdmin: boolean | undefined;
    isInstructor: boolean | undefined;
}

export interface SchoolUserDocument extends SchoolUser, HasId {
    schoolId: string;
}

export const SchoolUserRoles = [ 'Owner', 'Admin', 'Instructor' ];