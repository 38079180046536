import * as React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { routerActions } from 'connected-react-router';
import './Header.css';
import { UserIcon } from './common';
import BookingsIcon from './common/icons/BookingsIcon';
import { StoreState } from '../types';
import { Trans } from 'react-i18next';
import { LanguagePicker } from './common/LanguagePicker';
import { t } from '../i18n';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

const Header = () => {
    const isLoggedIn = useSelector((s: StoreState) => !!s.auth.user);
    const schools = useSelector((s: StoreState) =>
        _.keys(s.auth.schools)
            .map(schoolId => s.search.schools[schoolId])
            .filter(school => !!school) || []);
    const match = useRouteMatch();
    const dispatch = useDispatch();
    const redirectToSignIn = () => dispatch(routerActions.push('/signin'));
    const redirectToSignOut = () => dispatch(routerActions.push('/signout'));

    const managingSchool = schools.find(s => s.id === match.params['schoolId']);
    // eslint-disable-next-line no-restricted-globals
    const pathname = location.pathname;
    const isDark = pathname.length > 1 &&
    (!pathname.startsWith('/school') || pathname.endsWith('onboard')) &&
    !pathname.startsWith('/giveaway') &&
    !pathname.startsWith('/destination') &&
    !pathname.startsWith('/activity');

    const schoolLinks =
    schools.map(s => <Link style={{ padding: '5px' }} key={s.id} to={`/manage/school/${s.id}`}><span className="manageSchool hideSmall">{s.name}</span></Link>);

    const signInOutButton =
    isLoggedIn
        ? <button className="btnHeader signinButton hideSmall" onClick={redirectToSignOut}><Trans i18nKey="header.signout" /></button>
        : <button className="btnHeader signinButton" onClick={redirectToSignIn}><Trans i18nKey="header.signin" /></button>;

    return (
        <header className={`header ${isDark ? 'headerDark' : ''}`} >
            {
                !managingSchool
                    ?
                    <>
                        <Link className={`flex-row ${isDark ? 'headerLogoDark' : 'headerLogo'}`} to="/" aria-label="Link To Main Page" />
                        <div className="headerItems">
                            {isLoggedIn
                                ? <>
                                    {schoolLinks}
                                    <Link to="/bookings" aria-label="Link To Your Bookings"><BookingsIcon title={t('header.bookings-icon-title')} /></Link>
                                    <Link to="/account" aria-label="Link To Your Account Page"><UserIcon title={t('header.user-icon-title')} /></Link>
                                </>
                                : null
                            }
                            <LanguagePicker />
                            {signInOutButton}
                        </div>
                    </>
                    :
                    <>
                        <div className="flex-row">
                            <img
                                className="headerLogoSchool"
                                src={managingSchool.logoUrl}
                                alt={managingSchool.name}
                            />
                            <div className="headerLogoSchoolName">
                                <span>{managingSchool.name}</span>
                            </div>
                        </div>
                        <div className="headerItems flex-row flex-wrap">
                            <LanguagePicker />
                            {signInOutButton}
                        </div>
                    </>
            }
        </header>
    );
};

export default Header;