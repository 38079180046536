// background widths for each set, assumes files exist for each
const widths = [1920, 1600, 1366, 1024, 768, 640];
// each set of background image files include a tiny one (~1kb)
const tinyResSuffix = 'tiny';
// add new background image prefixes here
export const backgroundImagePrefixes = [
  'mountain_biking',
  'kite_surfing',
  'sup_tropical',
  'climbing_view',
  'diving',
  'surfing',
  'snowboarding',
  'windsurfing'
];

const raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

const chromeVersion = raw ? parseInt(raw[2], 10) : false;

const pickRandomImage = () => {
  return backgroundImagePrefixes[Math.floor(Math.random() * backgroundImagePrefixes.length)];
};

export const backgroundImagePrefix = pickRandomImage();

export const loadBackgroundImage = () => {
  loadTinyResImage();
  document.body.onload = () => loadCorrectResImage();
};

const loadTinyResImage = () => {
  const src = imgSrc(backgroundImagePrefix, tinyResSuffix);
  loadImageAsync(src);
};

const loadCorrectResImage = () => {
  const src = imgSrc(backgroundImagePrefix, getScreenWidthForImage());
  loadImageAsync(src);
};

const loadImageAsync = (src: string) => {
  const img = new Image();
  img.src = src;
  img.onload = () => setBackgroundImage(img);
};

const setBackgroundImage = (img: HTMLImageElement) => {
  console.log('setBackgroundImage', img.src);
  document.body.style.backgroundImage = getImageWithGradient(img.src);
};

const getImageWithGradient = (src: string) => {
  const darkenHeader = 'linear-gradient(rgba(0, 0, 0, 0.6) 0px, rgba(0, 0, 0, 0.0) 200px)';
  return `${darkenHeader}, url(${src})`;
};

const isScreenWidth = (width: number) => {
  return window.matchMedia(`screen and (min-device-width: ${width}px)`).matches;
};

export const getScreenWidthForImage = () => {
  for (let width of widths) {
    if (isScreenWidth(width)) {
      return width;
    }
  }
  return widths[widths.length - 1];
};

export const imgSrc = (prefix: string, width: any, subdir?: string) => {
  // webpack loader config is hidden for create-react-app
  // and hashes the images in the /build dir
  // but we can use require to get the image

  let dir = `./images`;
  if (subdir) {
    dir = `./images/${subdir}`;
  }

  if (!chromeVersion || chromeVersion < 23 || !!subdir) {
    return require(`${dir}/${prefix}_${width}.jpg`);
  } else {
    return require(`${dir}/${prefix}_${width}.webp`);
  }
};
