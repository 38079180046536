import * as React from 'react';
import './DropDown.css';

/// see https://www.w3schools.com/css/css_dropdowns.asp

export class DropDown extends React.Component<{header: JSX.Element}> {
    render() {
        return (
            <div className="dropdown">
                {this.props.header}
                <div className="dropdown-content">
                    {this.props.children}
                </div>
            </div>
        );   
    }
}