export type DestinationDictionary = { [destinationId: string]: DestinationDocument };
export type SeasonDictionary = { [activity: string]: Season };
export type DestinationImageUrlDictionary = { [fileName: string]: string };

export interface DestinationDocument {
    destinationId: string;
    name: string;
    summary: string;
    location: { lat: number, lng: number };
    images: DestinationImageUrlDictionary;
    seasons?: SeasonDictionary;
}

export interface Season {
    activity: string;
    months: number[];
    rank: number;
}

export const inSeason = (s: Season, month: number) => {
    if (!s || month < 0 || month > 11) {
        return false;
    }
    // default to true if no season months defined
    if (!s.months || s.months.length < 12) {
        return true; 
    }
    // return true if rating for month is more than 0
    return s.months[month] > 0; 
};

export const getDestinationIdFromLocationName = (name: string) => 
    name.toLowerCase().split(',').map(s => s.trim()).join('-').replace(/ /g, '-');