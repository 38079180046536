import _ from 'lodash';
import { SCHOOL_USER_FORM_FIELD_CHANGED, SchoolUserAction, SCHOOL_USER_UPDATED } from '../actions/SchoolUserActions';
import { SchoolUsersStoreState } from '../types';
import { FIRESTORE_REMOVED } from '../constants';

const initialState = {
    newUserForm: {
        email: '',
        name: '',
        languages: '',
        mobile: '',
        initials: '',
        nickname: '',
        activities: '',
        active: true,
        roles: []
    },
    users: {}
} as SchoolUsersStoreState;

export default (state: SchoolUsersStoreState = initialState, action: SchoolUserAction): SchoolUsersStoreState => {
    switch (action.type) {
        case SCHOOL_USER_FORM_FIELD_CHANGED:
            return { ...state, newUserForm: { ...state.newUserForm, [action.value.field]: action.value.value } };
        case SCHOOL_USER_UPDATED:
            if (action.value.change === FIRESTORE_REMOVED) {
                return { ...state, users: _.omit(state.users, action.value.data.id) };
            } else {
                if (!action.value.data.info) {
                    return state;
                }
                return { ...state, 
                    users: { 
                        ...state.users, 
                        [action.value.data.schoolId]: {
                            ...state.users[action.value.data.schoolId],
                            [action.value.data.id]:  action.value.data
                        }
                    } 
                };
            }
        default:
            return state;
    }
};