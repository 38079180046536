import moment from 'moment';
import _ from 'lodash';
import { SeasonDictionary } from './DestinationTypes';

export type UserBookingDictionary = { [id: string]: UserBooking };
export type SchoolBookingDictionary = { [id: string]: SchoolBooking };
export type BookingRequestDictionary = { [id: string]: BookingRequest };
export type BookingRequestFormDictionary = { [activity: string]: RequestForm };
export type BookingOfferDictionary = { [id: string]: BookingOffer };
export type BookingItineraryDictionary = { [id: string]: BookingItinerary };

export const BOOKING_STATUS_CREATED = 'created';
export type BOOKING_STATUS_CREATED = typeof BOOKING_STATUS_CREATED;

export const BOOKING_STATUS_CANCELLED = 'cancelled';
export type BOOKING_STATUS_CANCELLED = typeof BOOKING_STATUS_CANCELLED;

export const BOOKING_STATUS_DECLINED = 'declined';
export type BOOKING_STATUS_DECLINED = typeof BOOKING_STATUS_DECLINED;

export const BOOKING_STATUS_OFFERS = 'offers';
export type BOOKING_STATUS_OFFERS = typeof BOOKING_STATUS_OFFERS;

export const BOOKING_STATUS_ACCEPTED = 'accepted';
export type BOOKING_STATUS_ACCEPTED = typeof BOOKING_STATUS_ACCEPTED;

export const BOOKING_STATUS_EXPIRED = 'expired';
export type BOOKING_STATUS_EXPIRED = typeof BOOKING_STATUS_EXPIRED;

export const BOOKING_STATUS_CONFIRMED = 'confirmed';
export type BOOKING_STATUS_CONFIRMED = typeof BOOKING_STATUS_CONFIRMED;

export const BOOKING_STATUS_MISSED = 'missed';
export type BOOKING_STATUS_MISSED = typeof BOOKING_STATUS_MISSED;

export const BOOKING_STATUS_MISSED_ALT = 'missed_alt';
export type BOOKING_STATUS_MISSED_ALT = typeof BOOKING_STATUS_MISSED_ALT;

export const BOOKING_STATUS_OFFERS_OTHER = 'offers_other';
export type BOOKING_STATUS_OFFERS_OTHER = typeof BOOKING_STATUS_OFFERS_OTHER;

export const BOOKING_SOURCE_OUTDR = 'outdr';
export type BOOKING_SOURCE_OUTDR = typeof BOOKING_SOURCE_OUTDR;

export const BOOKING_SOURCE_DIRECT = 'direct';
export type BOOKING_SOURCE_DIRECT = typeof BOOKING_SOURCE_DIRECT;

export const BOOKING_SOURCE_WIDGET = 'widget';
export type BOOKING_SOURCE_WIDGET = typeof BOOKING_SOURCE_WIDGET;

export const BOOKING_COMMISSION_PAYMENT_NONE = 'none';
export type BOOKING_COMMISSION_PAYMENT_NONE = typeof BOOKING_COMMISSION_PAYMENT_NONE;

export const BOOKING_COMMISSION_PAYMENT_STRIPE = 'stripe';
export type BOOKING_COMMISSION_PAYMENT_STRIPE = typeof BOOKING_COMMISSION_PAYMENT_STRIPE;

export const BOOKING_COMMISSION_PAYMENT_STRIPE_CONNECT = 'stripe_connect';
export type BOOKING_COMMISSION_PAYMENT_STRIPE_CONNECT = typeof BOOKING_COMMISSION_PAYMENT_STRIPE_CONNECT;

export const BOOKING_COMMISSION_PAYMENT_BANK_TRANSFER = 'bank_transfer';
export type BOOKING_COMMISSION_PAYMENT_BANK_TRANSFER = typeof BOOKING_COMMISSION_PAYMENT_BANK_TRANSFER;

export type BookingSource =
    BOOKING_SOURCE_OUTDR |
    BOOKING_SOURCE_WIDGET |
    BOOKING_SOURCE_DIRECT;

export type BookingStatus =
    BOOKING_STATUS_CREATED |
    BOOKING_STATUS_OFFERS |
    BOOKING_STATUS_ACCEPTED |
    BOOKING_STATUS_CONFIRMED |
    BOOKING_STATUS_CANCELLED |
    BOOKING_STATUS_DECLINED |
    BOOKING_STATUS_EXPIRED;

export type SchoolBookingStatus =
    BookingStatus |
    BOOKING_STATUS_OFFERS_OTHER |
    BOOKING_STATUS_MISSED |
    BOOKING_STATUS_MISSED_ALT;

export interface UserBooking {
    userId: string;
    bookingId: string;
    bookingRef: string;
    requests: BookingRequestDictionary;
    instantOffers: InstantOffer[];
    createdAt: Date;
    status: BookingStatus;
    invoices: BookingInvoiceDictionary | null;

    messages: { [schoolId: string]: BookingMessage[] };
}

export interface SchoolBooking {
    bookingId: string;
    bookingRef: string;
    schoolId: string;
    userId: string;
    userName: string;
    userEmail: string;
    userTel: string;
    requests: BookingRequestDictionary;
    instantOffers: InstantOffer[];
    start: string | null;
    end: string | null;
    createdAt: Date;
    status: SchoolBookingStatus;
    invoices: BookingInvoice[];
    source: BookingSource;
    isExclusive: boolean;
    messages: BookingMessage[];
    commissionModel: BookingCommissionModel;
}

export interface BookingMessage {
    messageId: string;
    userId: string;
    userName: string;
    sentAt: Date; // Firebase timestamp instead?
    isSchool: boolean;
    message: string;
}

export interface SchoolInfo {
    name: string;
    location: {
        name: string,
        lat: number,
        lng: number
    };
    address: string;
    destinationId: string;
    activities: string;
    phone: string;
    email: string;
    website: string;
    countryCode: string;
}

export interface School {
    id: string;
    info: SchoolInfo;
    stripe: {
        stripe_user_id: string;
    };
    commissionModels: BookingSourceCommissionModels;
    termsOfService: TermsOfService;
}

export interface SchoolOfferSettings {
    currency?: string;
    depositMin?: number;
    depositMax?: number;

    includedItems: { [includedType: string]: SchoolOfferIncludedItem };
    instantBooking: SchoolInstantBookingSettings;
}

export interface SchoolSettings {
    offer: SchoolOfferSettings;
    color: SchoolColorSettings;
    calendar: SchoolCalendarSettings;
}

export type SchoolSettingsDictionary = { [schoolId: string]: SchoolSettings };

export interface SchoolInstantBookingSettings {
    enabled: boolean;
    options: { [option: string]: SchoolInstantBookingOption };
}

export interface SchoolInstantBookingOption {
    key: string;
    currency: string;
    price: number;
    pricePer: string;
    activity: string;
    type: string;
    format: string;
    enabled: boolean;
    hoursPerDay: number;
    minPeople: number;
    maxPeople: number;
}

export interface SchoolOfferIncludedItem {
    type: string;
    details: { [lang: string]: string };
    defaultOfferTypes: string[];
    defaultOfferFormats: string[];
}

export const SchoolOfferIncludedItemTypes = [
    'Gear',
    'Instruction',
    'Supervision',
    'Certification',
    'Insurance',
    'Safety',
    'Food',
    'Drinks',
    'Photos',
    'Videos',
    'Transport',
    'Transfer',
    'Accommodation'
];

export interface SchoolColorSettings {
    bookingStatus: { [status: string]: ColorSetting };
}

export interface SchoolCalendarSettings {
    accessToken: string;
}

export interface ColorSetting {
    border: string;
    color: string;
    backgroundColor: string;
}

export const BookingStatusStyles = {
    [BOOKING_STATUS_CREATED]: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#8AFCF9',
        backgroundColor: 'white',
        color: 'black'
    },
    [BOOKING_STATUS_OFFERS]: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#8AFCF9',
        backgroundColor: '#8AFCF9',
        color: 'black'
    },
    [BOOKING_STATUS_OFFERS_OTHER]: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#8AFCF9',
        backgroundColor: 'rgba(138, 252, 248, 0.3)',
        color: 'black'
    },
    [BOOKING_STATUS_ACCEPTED]: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#D8FF72',
        backgroundColor: 'white',
        color: 'black'
    },
    [BOOKING_STATUS_CONFIRMED]: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#D8FF72',
        backgroundColor: '#D8FF72',
        color: 'black'
    },
    [BOOKING_STATUS_MISSED_ALT]: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'rgba(138, 252, 248, 0.3)',
        backgroundColor: 'rgba(138, 252, 248, 0.3)',
        color: 'black',
        boxShadow: 'none'
    },
    [BOOKING_STATUS_MISSED]: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'rgba(138, 252, 248, 0.3)',
        backgroundColor: 'white',
        color: 'black',
        boxShadow: 'none'
    },
    [BOOKING_STATUS_DECLINED]: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#FE6F81',
        backgroundColor: 'white',
        color: 'black',
        boxShadow: 'none',
    },
    [BOOKING_STATUS_EXPIRED]: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#F7F7F7',
        backgroundColor: '#F7F7F7',
        color: 'black',
        boxShadow: 'none'
    },
    [BOOKING_STATUS_CANCELLED]: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#F7F7F7',
        backgroundColor: '#F7F7F7',
        color: 'black',
        boxShadow: 'none'
    }
};

export const getBookingStatusStyle = (settings: SchoolColorSettings, s: string) =>
    settings && settings.bookingStatus && settings.bookingStatus[s]
        ? { ...BookingStatusStyles[s], ...settings.bookingStatus[s] }
        : BookingStatusStyles[s];

export interface RequestForm {
    days: number;
    start: string | null;
    end: string | null;
    language: string | null;
    activity: string | null;
    type: string | null;
    group: NewBookingGroupMember[];
}

export interface NewBookingGroupMember {
    name: string;
    email: string;
    tel: string;
    skill: string;
    isSelected: boolean;
}

export interface NewBookingForm {
    name: string;
    email: string;
    tel: string; // currently only set from school site widget bookings
    requests: BookingRequestFormDictionary;

    skip: {
        who: boolean;
        whoActivity: { [activity: string]: boolean };
        type: { [activity: string]: boolean };
    };

    // loaded from store
    schools: NewBookingFormSchool[];
    instantBookingSettings: { [schoolId: string]: SchoolInstantBookingSettings };
    commissionModels: { [schoolId: string]: BookingCommissionModel };
    destinationSeasons: { [destinationId: string]: SeasonDictionary };
    
    // user inputs
    preferences: InstantBookingPreferences;

    // generated
    recommended: InstantBookingRecommended;
    instantOffers: InstantOffer[];
}

export interface InstantBookingRecommended {
    destinationId: string | null;
    dates: {
        startDate: moment.Moment | null,
        endDate: moment.Moment | null,
    };
    schools: { [activity: string]: string };
}

export interface InstantBookingPreferences {
    destinationId: string | null;
    dates: {
        startDate: moment.Moment | null,
        endDate: moment.Moment | null,
    };
    people: {
        name: string;
    }[];
    activities: string[];
    skills: { [activity: string]: string };
    schools: { [activity: string]: string };
}

export interface NewBookingFormSchool {
    schoolId: string;
    name: string;
    email: string;
    activities: string;
    bookingDisabled: boolean;
    destinationId: string;
    destinationName: string;
}

export interface InstantOffer {
    offerId: string;
    schoolId: string;
    destinationId: string;
    start: string;
    end: string;
    activity: string;
    type: string;
    format: string;
    group: NewBookingGroupMember[];
    days: number;
    currency: string;
    price: number;
    total: number;
    pricePer: string;
    deposit: number;
    fee: number;
    hoursPerDay: number;
    instructors: number;
    recommendation: boolean;
    language: string;
}

export interface BookingRequest extends RequestForm {
    requestId: string;
    schoolId: string;
    schoolName: string;
    offers: BookingOfferDictionary;
    declined: BookingDeclined | null;
}

export interface SchoolBookingForms extends SchoolBooking {
    offerForm: OfferForm;
    declineForm: DeclineForm;
}

export interface OfferForm {
    comments: string;
    currency: string;
    price: number;
    deposit: number;
    availableDays: string[];
    totalDays: number;
    hoursPerDay: number;
    instructors: number;
    dayOption: string;
    payableBy: string;
    lessonFormat: string;
    people: NewBookingGroupMember[];
    included: SchoolOfferIncludedItem[];
    /**
     * @deprecated since Booking schema 1.0.1
     * and replaced by 'people' array field
     */
    skills?: string[];

    /**
     * @deprecated since Booking schema 1.0.1
     * and replaced by 'comments' field
     */
    offer?: string;
}

export interface DeclineForm {
    reason: string;
}

export interface BookingOffer extends OfferForm {
    offerId: string;
    schoolId: string;
    requestId: string;
    accepted: BookingOfferAccepted | null;
    rejected: BookingOfferRejected | null;
    withdrawn: BookingOfferWithdrawn | null;
}

export interface BookingDeclined extends DeclineForm {
    bookingId: string;
    schoolId: string;
}

export interface BookingOfferAccepted {
    offerId: string;
    userId: string;
    schoolId: string;
    requestId: string;
    acceptedDays: string[];
}

export interface BookingItinerary {
    bookingId: string;
    items: BookingItineraryItem[];
    validation: {
        isValid: boolean;
        invalidOfferIds: string[];
    };
    total: {
        currency: string;
        price: number;
        deposit: number;
        pricePer: string;
    };
}

export interface BookingItineraryItem {
    readonly bookingId: string;
    readonly schoolId: string;
    readonly offerId: string;
    readonly day: string;
}

export interface BookingOfferRejected {
    readonly offerId: string;
    readonly userId: string;
    readonly schoolId: string;
    readonly requestId: string;
    readonly reason: string;
}

export interface BookingOfferWithdrawn {
    offerId: string;
    userId: string;
    schoolId: string;
    requestId: string;
}

export interface BookingInvoice {
    invoiceId: string;
    date: Date;
    schoolId: string;
    schoolName: string;
    currency: string;
    items: BookingInvoiceItem[];
    subtotal: number;
    fee: number;
    total: number;
    paid: boolean;
}

export type BookingInvoiceDictionary = { [schoolId: string]: BookingInvoice };

export interface BookingInvoiceItem {
    schoolId: string;
    offerId: string;
    description: string;
    price: number;
}

export const offerPricePerPerson = ({ price, people }: OfferForm) => {
    const numPeople = people.filter(p => !!p.name && p.isSelected).length;
    return numPeople > 0 ? (price / numPeople) : price;
};

export const offerPricePerHour = ({ price, totalDays, hoursPerDay }: OfferForm) => {
    const totalHours = totalDays * hoursPerDay;
    return totalHours > 0 ? (price / totalHours) : price;
};

export const offerPricePerDay = ({ price, totalDays }: OfferForm) => {
    return totalDays > 0 ? (price / totalDays) : price;
};

export const offerPricePerPersonPerHour = (offer: OfferForm) => {
    const { totalDays, hoursPerDay } = offer;
    const totalHours = totalDays * hoursPerDay;
    const perPerson = offerPricePerPerson(offer);
    return totalHours > 0 ? (perPerson / totalHours) : perPerson;
};

export const offerPricePerPersonPerDay = (offer: OfferForm) => {
    const { totalDays } = offer;
    const perPerson = offerPricePerPerson(offer);
    return totalDays > 0 ? (perPerson / totalDays) : perPerson;
};

export const isBookingOpen = ({ status }: { status: SchoolBookingStatus }) => {
    return status === BOOKING_STATUS_CREATED
        || status === BOOKING_STATUS_OFFERS
        || status === BOOKING_STATUS_OFFERS_OTHER;
};

export const isBookingAcceptedOrConfirmed = ({ status }: { status: SchoolBookingStatus }) => {
    return status === BOOKING_STATUS_ACCEPTED || status === BOOKING_STATUS_CONFIRMED;
};

export const isBookingCancelledOrExpired = ({ status }: { status: SchoolBookingStatus }) => {
    return status === BOOKING_STATUS_CANCELLED || status === BOOKING_STATUS_EXPIRED;
};

export const BookingLanguages = [
    'en',
    'es',
    'fr',
    'de',
    'it'
];

export const BookingSkills = [
    'first time',
    'beginner',
    'intermediate',
    'advanced',
    'expert'
];

export const BookingSkillStatements = {
    'kitesurfing': {
        [BookingSkills[0]]: 'Never tried kitesurfing',
        [BookingSkills[1]]: 'Practicing water-starts',
        [BookingSkills[2]]: 'Ride upwind most of the time',
        [BookingSkills[3]]: 'Learning small tricks and jumps',
        [BookingSkills[4]]: 'Jumps, waves, and foils are fun'
    }
};

export const STRIPE_FEE_RATE = 0.029; // 2.9% for non-EU cards
export const STRIPE_FEE_BASE = 0.20; // £0.20 base

export const calcMinDeposit = (price: number, model: BookingCommissionModel) => {
    if (model.commissionPayment !== BOOKING_COMMISSION_PAYMENT_STRIPE &&
        model.commissionPayment !== BOOKING_COMMISSION_PAYMENT_STRIPE_CONNECT) {
        // for schools not using Stripe, there is no deposit
        return 0;
    }

    // min deposit needs to cover our commission
    const commission = (price * model.commissionRate);

    if (model.commissionPayment === BOOKING_COMMISSION_PAYMENT_STRIPE_CONNECT) {
        // if school has own stripe account, then they pay stripe fees
        return (commission + STRIPE_FEE_BASE) * (1.0 / (1.0 - STRIPE_FEE_RATE));
    }

    // otherwise we pay stripe fees out of our commission
    return commission;
};

export type BookingCommissionPaymentMethod =
    | BOOKING_COMMISSION_PAYMENT_NONE
    | BOOKING_COMMISSION_PAYMENT_STRIPE
    | BOOKING_COMMISSION_PAYMENT_STRIPE_CONNECT
    | BOOKING_COMMISSION_PAYMENT_BANK_TRANSFER;

export interface BookingCommissionModel {
    source: BookingSource;
    commissionRate: number;
    commissionPayment: BookingCommissionPaymentMethod;
    feeAmount: number;
    currency: string;
}

export type BookingSourceCommissionModels = { [source: string]: BookingCommissionModel };

export type TermsOfService = 'eu' | 'eu2' | 'global' | 'global2' | 'default';

export const isStripeConnectRequired = (school: School, booking?: SchoolBooking) => {
    let required = false;
    const connect = BOOKING_COMMISSION_PAYMENT_STRIPE_CONNECT;
    const connected = !!school.stripe && !!school.stripe.stripe_user_id;

    if (booking) {
        required = booking.commissionModel &&
            booking.commissionModel.commissionPayment === connect;
    } else {
        required = school.commissionModels &&
            _.filter(school.commissionModels, m => m.commissionPayment === connect).length > 0;
    }

    return required && !connected;
};
