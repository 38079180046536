import * as React from 'react';
import { PolicyPage } from './PolicyPage';

export const PrivacyPage = () => {
    return (
        <PolicyPage title={`Privacy Policy`}>
            <iframe
                src="https://www.iubenda.com/privacy-policy/44924335"
                style={{ flex: 1 }}
                frameBorder="0"
                scrolling="auto"
                title="Privacy Policy"
            />
        </PolicyPage>
    );
};