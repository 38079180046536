import * as React from 'react';
import './Spinner.css';
import { HTMLAttributes } from 'react';

// copied from http://tobiasahlin.com/spinkit/
const Spinner = (props: HTMLAttributes<HTMLElement>) => {
    return (
        <div className="sk-cube-grid" {...props}>
            <div className="sk-cube sk-cube1" />
            <div className="sk-cube sk-cube2" />
            <div className="sk-cube sk-cube3" />
            <div className="sk-cube sk-cube4" />
            <div className="sk-cube sk-cube5" />
            <div className="sk-cube sk-cube6" />
            <div className="sk-cube sk-cube7" />
            <div className="sk-cube sk-cube8" />
            <div className="sk-cube sk-cube9" />
        </div>
    );
};

export default Spinner;
