import { ActionResult } from './Common';
import { SchoolBooking, School, RequestForm, OfferForm, BookingInvoiceItem } from '../types';
import { FirestoreChangeType } from '../constants';
import { SendSchoolBookingMessage } from './BookingActions';

export const SCHOOL_BOOKING_UPDATED = 'SCHOOL_BOOKING_UPDATED';
export type SCHOOL_BOOKING_UPDATED = typeof SCHOOL_BOOKING_UPDATED;

export const SCHOOL_UPDATED = 'SCHOOL_UPDATED';
export type SCHOOL_UPDATED = typeof SCHOOL_UPDATED;

export const OFFER_FORM_FIELD_CHANGED = 'OFFER_FORM_FIELD_CHANGED';
export type OFFER_FORM_FIELD_CHANGED = typeof OFFER_FORM_FIELD_CHANGED;

export const DECLINE_FORM_FIELD_CHANGED = 'DECLINE_FORM_FIELD_CHANGED';
export type DECLINE_FORM_FIELD_CHANGED = typeof DECLINE_FORM_FIELD_CHANGED;

export const MAKE_OFFER = 'MAKE_OFFER';
export type MAKE_OFFER = typeof MAKE_OFFER;

export const WITHDRAW_OFFER = 'WITHDRAW_OFFER';
export type WITHDRAW_OFFER = typeof WITHDRAW_OFFER;

export const DECLINE_BOOKING = 'DECLINE_BOOKING';
export type DECLINE_BOOKING = typeof DECLINE_BOOKING;

export const IMPORT_BOOKING = 'IMPORT_BOOKING';
export type IMPORT_BOOKING = typeof IMPORT_BOOKING;

export const ADD_INVOICE = 'ADD_INVOICE';
export type ADD_INVOICE = typeof ADD_INVOICE;

export const MARK_INVOICE_PAID = 'MARK_INVOICE_PAID';
export type MARK_INVOICE_PAID = typeof MARK_INVOICE_PAID;

export interface OfferFormFieldChanged {
    type: OFFER_FORM_FIELD_CHANGED;
    value: {
        bookingId: string;
        schoolId: string;
        field: string;
        value: any;
    };
}

export interface DeclineFormFieldChanged {
    type: DECLINE_FORM_FIELD_CHANGED;
    value: {
        bookingId: string;
        schoolId: string;
        field: string;
        value: any;
    };
}

export interface MakeOffer {
    type: MAKE_OFFER;
    value: {
        bookingId: string;
        schoolId: string;
        requestId: string;
    };
}

export interface WithdrawOffer {
    type: WITHDRAW_OFFER;
    value: {
        bookingId: string;
        schoolId: string;
        requestId: string;
        offerId: string;
    };
}

export interface DeclineBookingRequest {
    type: DECLINE_BOOKING;
    value: {
        bookingId: string;
        schoolId: string;
    };
}

export interface SchoolBookingUpdated {
    type: SCHOOL_BOOKING_UPDATED;
    value: {
        id: string;
        change: FirestoreChangeType;
        data: SchoolBooking;
    };
}

export interface SchoolUpdated {
    type: SCHOOL_UPDATED;
    value: {
        id: string;
        data: School;
    };
}

export interface ImportBooking {
    type: IMPORT_BOOKING;
    value: {
        schoolId: string;
        request: RequestForm;
        offer: OfferForm;
    };
}

export interface AddInvoice {
    type: ADD_INVOICE;
    value: {
        bookingId: string;
        schoolId: string;
        items: BookingInvoiceItem[];
    };
}

export interface MarkInvoicePaid {
    type: MARK_INVOICE_PAID;
    value: {
        bookingId: string;
        schoolId: string;
        invoiceId: string;
    };
}

export type SchoolBookingAction =
    SchoolBookingUpdated |
    SchoolUpdated |
    MakeOffer |
    DeclineBookingRequest |
    OfferFormFieldChanged |
    DeclineFormFieldChanged |
    ImportBooking | 
    AddInvoice |
    MarkInvoicePaid |
    SendSchoolBookingMessage |
    ActionResult;

export function schoolBookingUpdated(change: firebase.firestore.DocumentChange): SchoolBookingUpdated {
    const rawSchoolBooking = change.doc.data();

    if (!rawSchoolBooking.invoices) {
        rawSchoolBooking.invoices = [];
    }

    if (rawSchoolBooking.invoice) {
        const invoice = rawSchoolBooking.invoice;
        delete rawSchoolBooking.invoice;
        rawSchoolBooking.invoices = [...rawSchoolBooking.invoices, invoice];
    }

    return {
        type: SCHOOL_BOOKING_UPDATED,
        value: {
            id: change.doc.id,
            change: change.type,
            data: rawSchoolBooking as SchoolBooking
        }
    };
}

export function schoolUpdated(snapshot: firebase.firestore.DocumentSnapshot): SchoolUpdated {
    return {
        type: SCHOOL_UPDATED,
        value: {
            id: snapshot.id,
            data: { ...snapshot.data(), id: snapshot.id } as School
        }
    };
}

export const makeOffer = (bookingId: string, schoolId: string, requestId: string): MakeOffer => {
    return {
        type: MAKE_OFFER,
        value: { 
            bookingId,
            schoolId,
            requestId
        }
    };
};

export const withdrawOffer = (bookingId: string, schoolId: string, requestId: string, offerId: string): WithdrawOffer => {
    return {
        type: WITHDRAW_OFFER,
        value: {
            bookingId, 
            schoolId,
            requestId,
            offerId
        }
    };
};

export const declineBookingRequest = (bookingId: string, schoolId: string): DeclineBookingRequest => {
    return {
        type: DECLINE_BOOKING,
        value: { 
            bookingId,
            schoolId
        }
    };
};

export function offerFormFieldChanged(bookingId: string, schoolId: string, field: string, value: any): OfferFormFieldChanged {
    return {        
        type: OFFER_FORM_FIELD_CHANGED,        
        value: { bookingId, schoolId, field, value }
    };
}

export function declineFormFieldChanged(bookingId: string, schoolId: string, field: string, value: any): DeclineFormFieldChanged {
    return {        
        type: DECLINE_FORM_FIELD_CHANGED,        
        value: { bookingId, schoolId, field, value }
    };
}

export const importBooking = (schoolId: string, request: RequestForm, offer: OfferForm): ImportBooking => {
    return {
        type: IMPORT_BOOKING,
        value: { 
            request,
            schoolId,
            offer
        }
    };
};

export const addInvoice = (bookingId: string, schoolId: string, items: BookingInvoiceItem[]): AddInvoice => {
    return {
        type: ADD_INVOICE,
        value: {
            bookingId: bookingId,
            schoolId: schoolId,
            items: items
        }
    };
};

export const addMarkInvoicePaid = (bookingId: string, schoolId: string, invoiceId: string): MarkInvoicePaid => {
    return {
        type: MARK_INVOICE_PAID,
        value: {
            bookingId: bookingId,
            schoolId: schoolId,
            invoiceId: invoiceId
        }
    };
};