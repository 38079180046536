import { ActivityDocument } from '../types';
import { FIRESTORE_REMOVED, FIRESTORE_ADDED, FIRESTORE_MODIFIED } from '../constants';

export const ACTIVITY_UPDATED = 'ACTIVITY_UPDATED';
export type ACTIVITY_UPDATED = typeof ACTIVITY_UPDATED;

export interface ActivityUpdated {
    type: ACTIVITY_UPDATED;
    value: {
        adds: ActivityDocument[];
        removes: ActivityDocument[];
    };
}

export type ActivityAction = ActivityUpdated;

export function activitiesUpdated(changes: firebase.firestore.DocumentChange[]): ActivityUpdated {
    const adds = changes
        .filter(c => c.type === FIRESTORE_ADDED || c.type === FIRESTORE_MODIFIED)
        .map(c => c.doc.data() as ActivityDocument);

    const removes = changes
        .filter(c => c.type === FIRESTORE_REMOVED)
        .map(c => c.doc.data() as ActivityDocument);

    return {
        type: ACTIVITY_UPDATED,
        value: {
            adds,
            removes
        }
    };
}