import * as React from 'react';
import { t } from '../i18n';

export interface CookieBannerStateProps {
    cookiesAccepted: boolean;
}

export interface CookieBannerDispatchProps {
    acceptCookies: () => void;
}

const CookieBanner = ({ cookiesAccepted, acceptCookies }: CookieBannerStateProps & CookieBannerDispatchProps) => {

    const okText = t('cookie.ok');
    const linkText = t('cookie.link');
    const messages = t('cookie.message').split(linkText);
    const messageBefore = messages[0];
    const messageAfter = messages.length > 1 ? messages[1] : null;

    if (cookiesAccepted) {
        return null;
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'fixed',
                height: 'auto',
                left: '0px',
                bottom: '0px',
                width: 'auto',
                margin: '5px',
                padding: '15px',
                background: 'white',
                color: 'black',
                boxShadow: '0 1px 3px 0 rgba(41, 70, 97, .2)'
            }}
        >
            <span style={{ marginBottom: '15px', width: '240px', fontSize: '12px' }}>
                {messageBefore}
                <b><a href="https://outdr.com/privacy">{linkText}</a></b>
                {messageAfter}
            </span>
            <button className="btn btn-primary" onClick={acceptCookies}>{okText}</button>
        </div>
    );
};

export default CookieBanner;