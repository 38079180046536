// eslint-disable-next-line @typescript-eslint/no-unused-vars
import _loadGA from './loadGA';

let globalLabel: string | undefined;

export const ga = (command: string, ...fields: any[]) => {
    const gaf = (window as any).ga;
    if (typeof gaf === 'function') {
        gaf(command, ...fields);
    } else {
        console.warn('window.ga not loaded yet');
    }
};

type HitType =
    | 'pageview'
    | 'screenview'
    | 'event'
    | 'transaction'
    | 'item'
    | 'social'
    | 'exception'
    | 'timing';

// https://developers.google.com/analytics/devguides/collection/analyticsjs/command-queue-reference#send
export const gaSend = (hitType: HitType, fields?: Record<string, any>) => {
    ga('send', { hitType: hitType, ...fields });
};

/** https://developers.google.com/analytics/devguides/collection/analyticsjs/pages */
export const gaPageView = (fields: { title?: string; location?: string; page?: string }) => {
    gaSend('pageview', fields);
};

/** https://developers.google.com/analytics/devguides/collection/analyticsjs/events */
export const gaEvent = (fields: { category: string; action: string; label?: string; value?: number }) => {
    getGlobalLabel();

    gaSend('event', {
        eventCategory: fields.category,
        eventAction: fields.action,
        eventLabel: fields.label || globalLabel,
        eventValue: fields.value
    });
};

/** https://developers.google.com/analytics/devguides/collection/analyticsjs/social-interactions */
export const gaSocial = (fields: { network?: string; action?: string; target?: string }) => {
    gaSend('social', {
        socialNetwork: fields.network,
        socialAction: fields.action,
        socialTarget: fields.target
    });
};

/** https://developers.google.com/analytics/devguides/collection/analyticsjs/user-timings */
export const gaTiming = (fields: { category: string; var: string; value: number; label?: string }) => {
    gaSend('timing', {
        timingCategory: fields.category,
        timingVar: fields.var,
        timingValue: fields.value,
        timingLabel: fields.label
    });
};

/** https://developers.google.com/analytics/devguides/collection/analyticsjs/exceptions */
export const gaException = (fields: { description?: string; fatal?: boolean }) => {
    gaSend('exception', {
        exDescription: fields.description,
        exFatal: fields.fatal
    });
};

/** https://developers.google.com/analytics/devguides/collection/analyticsjs/ecommerce */
export const gaAddTransaction = (fields: { id: string; affiliation?: string; revenue?: number; shipping?: number; tax?: number; currency?: string }) => {
    ga('ecommerce:addTransaction', fields);
};

/** https://developers.google.com/analytics/devguides/collection/analyticsjs/ecommerce */
export const gaAddItem = (fields: { id: string; name: string; sku?: string; category?: string; price?: number; quantity?: number; currency?: string }) => {
    ga('ecommerce:addItem', fields);
};

/** https://developers.google.com/analytics/devguides/collection/analyticsjs/ecommerce */
export const gaSendCart = () => {
    ga('ecommerce:send');
};

/** https://developers.google.com/analytics/devguides/collection/analyticsjs/ecommerce */
export const gaClearCart = () => {
    ga('ecommerce:clear');
};

const getGlobalLabel = () => {
    if (typeof globalLabel === undefined) {
        globalLabel = window.sessionStorage.getItem('gaLabel') || '';
        if (globalLabel) {
            console.log('gaLabel loaded', globalLabel);
        }
    }
};

export const gaSetGlobalLabel = (label: string) => {
    if (globalLabel !== label) {
        console.log('gaLabel set', label);
        window.sessionStorage.setItem('gaLabel', label);
        globalLabel = label;
    }
};
