import { Epic, combineEpics, ofType } from 'redux-observable';
import { Action } from 'redux';
import { StoreState } from '../types/StoreState';
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import { map, distinctUntilChanged, switchMap, take, combineLatest } from 'rxjs/operators';
import _loadGA from '../analytics/loadGA';

import { ga } from '../analytics';
import { COOKIE_POLICY_ACCEPTED, GOOGLE_ANALYTICS_LOADED, AnalyticsAction, GoogleAnalyticsLoaded, COOKIE_BANNER_OK_CLICKED, CookiePolicyAccepted, LOAD_GOOGLE_ANALYTICS } from '../actions/AnalyticsActions';
import { Observable, Observer, NEVER } from 'rxjs';
import { googleAnalyticsConfig } from '../analytics/config';

const COOKIE_NAME = 'cookie-consent';
const COOKIE_VALUE = 'true';
const COOKIE_EXPIRY_DAYS = 365;

const setCookie = () => {
    const expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() + (COOKIE_EXPIRY_DAYS * 24 * 60 * 60 * 1000));
    document.cookie = `${COOKIE_NAME}=${COOKIE_VALUE};expires=${expiryDate.toUTCString()};path=/`;
};

const getCookie = () => {
    const nameParam = `${COOKIE_NAME}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(nameParam) === 0) {
            return c.substring(nameParam.length, c.length);
        }
    }
    return '';
};

const haveCookieConsent = () => {
    return getCookie() === COOKIE_VALUE;
};

const loadGA = (): Observable<AnalyticsAction> =>
    Observable.create(
        (obs: Observer<GoogleAnalyticsLoaded>) => {
            const options: any = {
                ...googleAnalyticsConfig,
                loadedCallback: () => obs.next({ type: GOOGLE_ANALYTICS_LOADED } as GoogleAnalyticsLoaded),
                debug: true
            };
            _loadGA(options);
            return;
        }
    );

const cookieBannerClickedEpic: Epic<AnalyticsAction, AnalyticsAction, StoreState> =
    (action$, store$) =>
        action$.pipe(
            ofType(COOKIE_BANNER_OK_CLICKED),
            map(() => {
                setCookie();
                return { type: COOKIE_POLICY_ACCEPTED } as CookiePolicyAccepted;
            }),
            take(1)
        );

const checkConsentCookieEpic: Epic<AnalyticsAction, AnalyticsAction, StoreState> =
    (action$, store$) =>
        Observable.create((obs: Observer<AnalyticsAction>) => {
            if (haveCookieConsent()) {
                obs.next({ type: COOKIE_POLICY_ACCEPTED });
            }
            // always load GA
            obs.next({ type: LOAD_GOOGLE_ANALYTICS });
        });

const loadGaEpic: Epic<AnalyticsAction, AnalyticsAction, StoreState> =
    (action$, store$) =>
        action$.pipe(
            ofType(LOAD_GOOGLE_ANALYTICS),
            take(1),
            switchMap(loadGA)
        );

const gaPageViewEpic: Epic<Action, any, StoreState> =
    (action$, store$) => {
        action$.ofType<LocationChangeAction>(LOCATION_CHANGE)
        .pipe(
            // combineLatest will not emit an initial value until each observable emits at least one value
            combineLatest(action$.ofType<GoogleAnalyticsLoaded>(GOOGLE_ANALYTICS_LOADED)),
            map(([lc, ]) => lc.payload.location.pathname),
            distinctUntilChanged(),
        ).subscribe(path => {
            ga('set', 'page', path);
            ga('send', 'pageview');
            return false;
        });

        // listen only, doesn't raise any new actions
        return NEVER;
    };

export default combineEpics(
    checkConsentCookieEpic,
    loadGaEpic,
    cookieBannerClickedEpic,
    gaPageViewEpic,
);