import * as React from 'react';
import { PolicyPage } from './PolicyPage';
import { Link } from 'react-router-dom';

export const TermsPolicyPage = () => {
    return (
        <PolicyPage title={`Terms of Service`}>
            <section>
                <p style={{ textAlign: 'center' }}>
                    <i>Last updated: <time dateTime="2018-11-19 12:00:00">November 19, 2018</time>.</i>
                </p>
                <p>
                    Welcome to Outdr.
                </p>
                <address>
                    Outdr Ltd<br />
                    86-90 Paul Street<br />
                    London, EC2A 4NE<br />
                    United Kingdom<br />
                </address>
                <p>
                    Please read our Terms of Service <b>("terms", "agreement")</b> document carefully.
                    It is a legal agreement between Outdr Ltd <b>("we", "us", "Outdr")</b> and the person <b>("you", "the user")</b> using the Outdr website <b>("platform", "site")</b>.
                    This agreement describes under what terms and conditions we operate the site and that apply to your use of the services.
                    By accessing or using the site, you are agreeing to all the terms in full, 
                    including our <Link to="/policy/privacy">Privacy Policy</Link> and <Link to="/policy/cookies">Cookie Policy</Link>.
                </p>
                <p>
                    Outdr reserves the right to modify our terms at any time.
                    New terms of service will be published here, and
                    we will endeavour to give 30 days notice prior to updating them.
                </p>
            </section>

            <section>
                <h3>1. The Basics</h3>
                <p>
                    <b>1.1</b> Outdr operates as an online booking platform, connecting the user to independent outdoor activity providers.
                    You can search for destinations and providers, and book activities.
                </p>
                <p>
                    <b>1.2</b> We verify and curate all activity providers we add to the site, in an effort to provide you with the best online experience.
                    However, we are not the activity provider, and therefore do not guarantee or take responsibility for the quality of the activity experience.
                </p>
                <p>
                    <b>1.3</b> Any booking made through the Outdr site is strictly a contract between you and the activity provider.
                    You agree to comply with the activity provider's own terms of service and cancellation policy.
                </p>
                <p>
                    <b>1.4</b> We endeavour to keep the site available 24 hours a day, 365 days a year, but
                    reserve the right to take the site down for maintenance and improvement work. The nature of the internet and cloud-hosting
                    means the site may become unreachable through forces beyond our control.
                </p>
            </section>

            <section>
                <h3>2. Eligible Users</h3>
                <p>
                    <b>2.1</b> All users are free to browse the Outdr web site, but must be at least 18 years old and a legal adult to book an activity through the site.
                </p>
                <p>
                    <b>2.2</b> You must be fit and healthy enough to undertake the activity, with no medical conditions which would prevent you from safely doing so.
                    If in doubt, please consult your local physician or contact the activity provider directly.
                </p>
                <p>
                    <b>2.3</b> When making a group booking, you agree to ensure all people in your group are eligible to participate in the selected activities.
                </p>
            </section>

            <section>
                <h3>3. Booking Process</h3>
                <p>
                    <b>3.1</b> You will be automatically notified of any updates or changes to the booking via the email address provided. 
                    You agree to provide an accurate name and email address during the booking process. Your name will be sent to the activity provider as part of the booking.
                </p>
                <p>
                    <b>3.2</b> To streamline the booking process, we do not require you to register a new account.
                    Bookings can be accessed via a link which will be sent to the email address you provide.
                    In lieu of you registering an account, your booking data will be securely stored on our servers under your email address.
                </p>
                <p>
                    <b>3.3</b> To ensure your booking remains secure, you agree to keep your booking reference private.
                </p>
                <p>
                    <b>3.4</b> Please assume any dates and times on bookings and sessions are in the local time of the activity provider, unless otherwise stated.
                    You are responsible for ensuring you and other members of your group are available for the activity at the correct place and time.
                </p>
            </section>

            <section>
                <h3>4. Payment and Refunds</h3>
                <p>
                    <b>4.1</b> Outdr does not store any credit card information.
                    We use a 3rd party payment provider, <a href="https://stripe.com" target="_blank" rel="noopener noreferrer">Stripe Payments Europe Ltd</a> <b>("Stripe")</b>, to securely process all credit card payments.
                </p>
                <p>
                    <b>4.2</b> During the automated Stripe payment process, your credit card will be charged directly by the activity provider. Outdr does not hold any money on behalf of the activity providers.
                </p>
                <p>
                    <b>4.3</b> Outdr reserves to the right to charge the user a small service fee when confirming a booking, to cover the cost of running the platform and providing support. This fee will be clearly stated.
                </p>
                <p>
                    <b>4.4</b> Refunds are given at the discretion of the activity provider, based on their own cancellation policies. Outdr is not responsible for ensuring deposits are refunded.
                </p>
                <p>
                    <b>4.5</b> Outdr agrees to refund any service fees if the deposit is refunded by the activity provider.
                </p>
                <p>
                    <b>4.6</b> Please let us know if something goes wrong. Outdr will refund the deposit if there is a technical fault during the booking process.
                    Any bookings created will be cancelled. 
                </p>
            </section>

            <section>
                <h3>5. Intellectual Property</h3>
                <p>
                    Unless otherwise stated, Outdr Ltd and/or it’s licensors own the intellectual property rights for
                    all material on Outdr Ltd. All intellectual property rights are reserved. You may view and/or print
                    pages from https://outdr.com for your own personal use subject to restrictions set in these terms of service.
                </p>
                <p>You must not:</p>
                <ul>
                    <li>Republish material from https://outdr.com</li>
                    <li>Sell, rent or sub-license material from https://outdr.com</li>
                    <li>Reproduce, duplicate or copy material from https://outdr.com</li>
                    <li>Redistribute content from Outdr Ltd (unless content is specifically made for redistribution).</li>
                </ul>

                <section>
                    <h3>6. User Comments</h3>
                    <p>
                        <b>6.1</b> Certain parts of this website offer the opportunity for users to post and exchange opinions, information,
                        material and data <b>("Comments")</b> in areas of the website. Outdr Ltd does not screen, edit, publish
                        or review Comments prior to their appearance on the website and Comments do not reflect the views or
                        opinions of Outdr Ltd, its agents or affiliates. Comments reflect the view and opinion of the
                        person who posts such view or opinion. To the extent permitted by applicable laws Outdr Ltd shall
                        not be responsible or liable for the Comments or for any loss cost, liability, damages or expenses caused
                        and or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this
                        website.
                    </p>
                    <p>
                        <b>6.2</b> Outdr Ltd reserves the right to monitor all Comments and to remove any Comments which it considers
                        in its absolute discretion to be inappropriate, offensive or otherwise in breach of these terms.
                        </p>
                    <p>
                        <b>6.3</b> You warrant and represent that:
                        <ul>
                            <li>You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;</li>
                            <li>The Comments do not infringe any intellectual property right, including without limitation copyright, patent or trademark, or other proprietary right of any third party;</li>
                            <li>The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material or material which is an invasion of privacy</li>
                            <li>The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.</li>
                        </ul>
                    </p>
                    <p>
                        <b>6.4</b> You hereby grant to Outdr Ltd a non-exclusive royalty-free license to use, reproduce,
                        edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats
                        or media.
                    </p>
                </section>

                <section>
                    <h3>7. Hyperlinking to Content</h3>
                    <p>
                        <b>7.1</b> The following organizations may link to our site without prior written approval:
                        <ol>
                            <li>Government agencies</li>
                            <li>Search engines</li>
                            <li>News organizations</li>
                        </ol>
                    </p>
                    <p>
                        <b>7.2</b> These organizations may link to our website information so long
                        as the link: (a) is not in any way misleading; (b) does not falsely imply sponsorship, endorsement or
                        approval of the linking party and its products or services; and (c) fits within the context of the linking
                        party's site.
                    </p>
                    <p>
                        <b>7.3</b> We may consider and approve in our sole discretion other link requests from other types of organizations.
                    </p>

                    <p>
                        <b>7.4</b> Approved organizations may hyperlink to our site as follows:
                        <ul>
                            <li>By use of our corporate name; or</li>
                            <li>By use of the uniform resource locator (Web address) being linked to; or</li>
                            <li>By use of any other description of our Web site or material being linked to that makes sense within the context and format of content on the linking party's site.</li>
                        </ul>
                    </p>
                    <p>
                        <b>7.5</b> No use of Outdr Ltd’s logo or other artwork will be allowed for linking absent a trademark license agreement.
                    </p>
                    <p>
                        <b>7.6</b> Without prior approval and express written permission, you may not create frames <b>("frames")</b> around our web pages or
                        use other techniques that alter in any way the visual presentation or appearance of our site.
                    </p>
                </section>

                <section>
                    <h3>8. Reservation of Rights</h3>
                    <p>
                        We reserve the right at any time and in its sole discretion to request that you remove all links or any particular
                        link to our Web site. You agree to immediately remove all links to our Web site upon such request. We also
                        reserve the right to amend these terms of service and its linking policy at any time. By continuing
                        to link to our Web site, you agree to be bound to and abide by these linking terms of service.
                    </p>
                    <p>
                        If you find any link on our Web site or any linked web site objectionable for any reason, you may contact
                        us about this. We will consider requests to remove links but will have no obligation to do so or to respond
                        directly to you.
                    </p>
                    <p>
                        Whilst we endeavour to ensure that the information on this website is correct, we do not warrant its completeness
                        or accuracy; nor do we commit to ensuring that the website remains available or that the material on the
                        website is kept up to date.
                    </p>
                    <p>
                        We shall have no responsibility or liability for any content appearing on your Web site. You agree to indemnify
                        and defend us against all claims arising out of or based upon your Website. No link(s) may appear on any
                        page on your Web site or within any context containing content or materials that may be interpreted as
                        libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or
                        other violation of, any third party rights.
                    </p>
                </section>

                <section>
                    <h3>9. Disclaimer</h3>
                    <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties of service relating to our website and the use of this website (including, without limitation, any warranties implied by law in respect of satisfactory quality, fitness for purpose and/or the use of reasonable care and skill). Nothing in this disclaimer will:</p>
                    <ul>
                        <li>limit or exclude our or your liability for death or personal injury resulting from negligence;</li>
                        <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
                        <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
                        <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
                    </ul>
                    <p>
                        The limitations and exclusions of liability set out in this Section and elsewhere in this disclaimer: (a)
                        are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer or
                        in relation to the subject matter of this disclaimer, including liabilities arising in contract, in tort
                        (including negligence) and for breach of statutory duty.
                    </p>
                    <p>
                        To the extent that the website and the information and services on the website are provided free of charge,
                        we will not be liable for any loss or damage of any nature.
                    </p>
                </section>
            </section>
        </PolicyPage >
    );
};