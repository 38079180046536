import {
    DestinationAction,
    DestinationsUpdated,
    DESTINATION_UPDATED,
    LOAD_DESTINATION_SCHOOLS,
    LOAD_DESTINATION_SCHOOLS_SUCCESS,
    LOAD_DESTINATION_SCHOOLS_FAILURE
} from '../actions/DestinationActions';
import { DestinationStoreState, DestinationDictionary } from '../types';
import _ from 'lodash';

const initialState: DestinationStoreState = {
    loadDestinationId: '',
    destinationSchools: {},
    destinationLoadErrors: {},
    destinations: {},
};

const getUpdatedState = (state: DestinationStoreState, action: DestinationsUpdated) => {
    const destinations = _.omitBy(state.destinations, dest =>
        !action.value.removes.find(rem =>
            rem.destinationId === dest.destinationId)) as DestinationDictionary;
    action.value.adds.forEach(add => destinations[add.destinationId] = add);
    return destinations;
};

export default (state: DestinationStoreState = initialState, action: DestinationAction): DestinationStoreState => {
    switch (action.type) {
        case DESTINATION_UPDATED:
            return { ...state, destinations: getUpdatedState(state, action) };
        case LOAD_DESTINATION_SCHOOLS:
            return { ...state, loadDestinationId: action.value };
        case LOAD_DESTINATION_SCHOOLS_SUCCESS:
            return {
                ...state,
                destinationLoadErrors: {
                    ...state.destinationLoadErrors,
                    [action.value.destinationId]: null
                },
                destinationSchools: {
                    ...state.destinationSchools,
                    [action.value.destinationId]: action.value.schools
                },
            };
        case LOAD_DESTINATION_SCHOOLS_FAILURE:
            return {
                ...state,
                destinationLoadErrors: {
                    ...state.destinationLoadErrors,
                    [action.value.destinationId]: action.value.error
                },
            };
        default:
            return state;
    }
};
