import { ActionResult } from './Common';
import { FirestoreChangeType } from '../constants';
import { SchoolOfferSettings, SchoolColorSettings, SchoolCalendarSettings } from '../types';

export const SCHOOL_SETTINGS_UPDATED = 'SCHOOL_SETTINGS_UPDATED';
export type SCHOOL_SETTINGS_UPDATED = typeof SCHOOL_SETTINGS_UPDATED;

export const SAVE_SCHOOL_OFFER_SETTINGS = 'SAVE_SCHOOL_OFFER_SETTINGS';
export type SAVE_SCHOOL_OFFER_SETTINGS = typeof SAVE_SCHOOL_OFFER_SETTINGS;

export const SAVE_SCHOOL_COLOR_SETTINGS = 'SAVE_SCHOOL_COLOR_SETTINGS';
export type SAVE_SCHOOL_COLOR_SETTINGS = typeof SAVE_SCHOOL_COLOR_SETTINGS;

export const SAVE_SCHOOL_CALENDAR_SETTINGS = 'SAVE_SCHOOL_CALENDAR_SETTINGS';
export type SAVE_SCHOOL_CALENDAR_SETTINGS = typeof SAVE_SCHOOL_CALENDAR_SETTINGS;

export interface SchoolSettingsUpdated {
    type: SCHOOL_SETTINGS_UPDATED;
    value: {
        schoolId: string;
        id: string;
        change: FirestoreChangeType;
        data: any;
    };
}

export interface SaveSchoolOfferSettings {
    type: SAVE_SCHOOL_OFFER_SETTINGS;
    value: {
        schoolId: string;
        settings: SchoolOfferSettings;
    };
}

export interface SaveSchoolCalendarSettings {
    type: SAVE_SCHOOL_CALENDAR_SETTINGS;
    value: {
        schoolId: string;
        settings: SchoolCalendarSettings;
    };
}

export interface SaveSchoolColorSettings {
    type: SAVE_SCHOOL_COLOR_SETTINGS;
    value: {
        schoolId: string;
        settings: SchoolColorSettings;
    };
}

export type SchoolSettingsAction =
    | SchoolSettingsUpdated
    | SaveSchoolOfferSettings
    | SaveSchoolColorSettings
    | SaveSchoolCalendarSettings
    | ActionResult;

export const schoolSettingsUpdated = (change: firebase.firestore.DocumentChange): SchoolSettingsUpdated => {
    const parentDoc = change.doc.ref.parent.parent;
    if (!parentDoc) {
        throw new Error('Expected parent doc');
    }
    console.log('schoolSettingsUpdated', change.doc.data());
    const schoolId = parentDoc.id;
    return {
        type: SCHOOL_SETTINGS_UPDATED,
        value: {
            schoolId: schoolId,
            id: change.doc.id,
            change: change.type,
            data: {
                ...change.doc.data()
            }
        }
    };
};

export const saveSchoolOfferSettings = (schoolId: string, settings: SchoolOfferSettings): SaveSchoolOfferSettings => {
    return {
        type: SAVE_SCHOOL_OFFER_SETTINGS,
        value: {
            schoolId: schoolId,
            settings: settings
        }
    };
};

export const saveSchoolColorSettings = (schoolId: string, settings: SchoolColorSettings): SaveSchoolColorSettings => {
    return {
        type: SAVE_SCHOOL_COLOR_SETTINGS,
        value: {
            schoolId: schoolId,
            settings: settings
        }
    };
};

export const saveSchoolCalendarSettings = (schoolId: string, settings: SchoolCalendarSettings): SaveSchoolCalendarSettings => {
    return {
        type: SAVE_SCHOOL_CALENDAR_SETTINGS,
        value: {
            schoolId: schoolId,
            settings: settings
        }
    };
};