import _i18n from 'i18next';
import { TranslationFunction } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { reactI18nextModule } from 'react-i18next';

import moment from 'moment';
import 'moment/locale/en-au';
import 'moment/locale/en-ca';
import 'moment/locale/en-gb';
import 'moment/locale/en-ie';
import 'moment/locale/en-il';
import 'moment/locale/en-nz';
import 'moment/locale/es';
import 'moment/locale/es-us';
import 'moment/locale/es-do';
import 'moment/locale/fr';
import 'moment/locale/fr-ca';
import 'moment/locale/fr-ch';

import * as keyTypes from './translations/__types__.json';

type _Translations = typeof keyTypes;
export type TranslationKey = keyof _Translations;



_i18n.on('languageChanged', (lng: string) => {
    console.log('changing datetime locale', lng);
    moment.locale(lng);
});

export const i18n = 
    _i18n
    .use(LanguageDetector)
    .use(reactI18nextModule)
    .init(
        {
            fallbackLng: 'en',
            debug: true,
            resources: {
                en: { translation: require('./translations/en.json') },
                es: { translation: require('./translations/es.json') },
                fr: { translation: require('./translations/fr.json') },
            }
        }, 
        function(err: Error) {
            if (err) {
                console.error('error loading translations', err);
            } else {
                console.info('translations loaded successfully');
            }
        }
    );

/** statically typed translation lookup */
export const t = i18n.t.bind(i18n) as TranslationFunction<any, object, keyof _Translations>;

/** dynamically typed translation lookup for when you don't know the key at compile time */
export const dynamicT = t as TranslationFunction<any, object, string>;