export type ActivityDictionary = { [activityId: string]: ActivityDocument };

export interface ActivityDocument {
    activityId: string;
    name: string;
    summary: string;
}

export const selectableActivties = [
    'kitesurfing',
    'windsurfing',
    'surfing',
    'paddleboarding',
    'sailing',
    'scubadiving',
    'freediving',
    'skydiving',
    'paragliding',
    'mountainbiking',
    'climbing',
    'kayaking',
    'snowboarding',
    'skiing',
    'skitouring',
    'splitboarding'
];

/** Maps activity ids to the main background image prefixes */
export const activityImages = {
    mountainbiking: 'mountain_biking',
    kitesurfing: 'kite_surfing',
    paddleboarding: 'sup_tropical',
    climbing: 'climbing_view',
    surfing: 'surfing',
    windsurfing: 'windsurfing',
    scubadiving: 'diving',
};

export const getActivityArray = ({ activities }: { activities: string }) =>
    (activities.split(',').map(a => a.trim()));
