import { UserSession } from '../types';
import { FirestoreChangeType } from '../constants';
import { ActionResult } from './Common';

export const USER_SESSION_UPDATED = 'USER_SESSION_UPDATED';
export type USER_SESSION_UPDATED = typeof USER_SESSION_UPDATED;

export interface UserSessionUpdated {
    type: USER_SESSION_UPDATED;
    value: {
        id: string;
        change: FirestoreChangeType;
        data: UserSession;
    };
}

export type UserSessionAction =
    UserSessionUpdated |
    ActionResult;

export function userSessionUpdated(change: firebase.firestore.DocumentChange): UserSessionUpdated {
    return {
        type: USER_SESSION_UPDATED,
        value: {
            id: change.doc.id,
            change: change.type,
            data: change.doc.data() as UserSession
        }
    };
}