import { ActionResult } from './Common';
import { SchoolSpotDocument } from '../types/SchoolSpotTypes';
import { FirestoreChangeType } from '../constants';

export const SPOT_FORM_FIELD_CHANGED = 'SPOT_FORM_FIELD_CHANGED';
export type SPOT_FORM_FIELD_CHANGED = typeof SPOT_FORM_FIELD_CHANGED;

export const ADD_SPOT = 'ADD_SPOT';
export type ADD_SPOT = typeof ADD_SPOT;

export const SCHOOL_SPOT_UPDATED = 'SCHOOL_SPOT_UPDATED';
export type SCHOOL_SPOT_UPDATED = typeof SCHOOL_SPOT_UPDATED;

export interface SpotFormFieldChanged {
    type: SPOT_FORM_FIELD_CHANGED;
    value: {
        schoolId: string;
        field: string;
        value: any;
    };
}

export interface AddSpot {
    type: ADD_SPOT;
    value: {
        schoolId: string;
    };
}

export interface SchoolSpotUpdated {
    type: SCHOOL_SPOT_UPDATED;
    value: {
        id: string;
        change: FirestoreChangeType;
        data: SchoolSpotDocument;
    };
}

export type SchoolSpotAction = AddSpot | SpotFormFieldChanged | SchoolSpotUpdated | ActionResult;

export const spotFormFieldChanged = (schoolId: string, field: string, value: any): SpotFormFieldChanged => {
    return {
        type: SPOT_FORM_FIELD_CHANGED,
        value: {
            schoolId,
            field,
            value
        }
    };
};

export const addSpot = (schoolId: string): AddSpot => {
    return {
        type: ADD_SPOT,
        value: {
            schoolId
        }
    };
};

export const schoolSpotUpdated = (change: firebase.firestore.DocumentChange): SchoolSpotUpdated => {
    const parentDoc = change.doc.ref.parent.parent;
    if (!parentDoc) {
        throw new Error('Expected parent doc');
    }
    const schoolId = parentDoc.id;
    return {
        type: SCHOOL_SPOT_UPDATED,
        value: {
            id: change.doc.id,
            change: change.type,
            data: {
                id: change.doc.id,
                schoolId: schoolId,
                ...change.doc.data() 
            } as SchoolSpotDocument
        }
    };
};