import { USER_SIGNED_IN, USER_SIGNED_OUT, AuthAction, SCHOOL_PERMISSIONS_UPDATED, USER_TOKEN_UPDATED, USER_DOC_UPDATED } from '../actions/AuthActions';
import { AuthStoreState, SchoolClaimsDictionary } from '../types';
import { ACTION_SUCCESS } from '../actions/Common';
import { LOCATION_CHANGE } from 'connected-react-router';

const initialState = {
    firebaseAuthLoaded: false,
    user: null,
    tokenResult: null,
    schools: {},
    userSessionId: null,
    userDoc: null,
    currentlyManagingSchoolId: null,
    permissionsLoaded: false,
    _schoolIdFromUrl: null,
    _schoolPermsLoaded: false,
} as AuthStoreState;

const getCurrentlyManagedSchoolFromUrl = (_schoolIdFromUrl: string | null, schoolClaims: SchoolClaimsDictionary) => {
    const claimsForSchool =
        _schoolIdFromUrl
            ? schoolClaims[_schoolIdFromUrl]
            : { isAdmin: false, isInstructor: false };

    const currentlyManagingSchoolId =
        (claimsForSchool && claimsForSchool.isAdmin)
            ? _schoolIdFromUrl
            : null;

    return {
        _schoolIdFromUrl,
        currentlyManagingSchoolId
    };
};

const applyAction = (state: AuthStoreState, action: AuthAction): AuthStoreState => {
    switch (action.type) {
        case LOCATION_CHANGE:
            const match = /\/manage\/school\/([^/]+)/.exec(action.payload.location.pathname);
            const _schoolIdFromUrl = match ? match[1] : null;
            return {
                ...state,
                ...getCurrentlyManagedSchoolFromUrl(_schoolIdFromUrl, state.schools)
            };
        case USER_SIGNED_IN:
            return {
                ...state,
                // override the default 'firebase' providerId with the one used to sign-in
                user: { ...action.value.user, providerId: action.value.providerId },
                firebaseAuthLoaded: true
            };
        case USER_SIGNED_OUT:
            return { ...initialState, firebaseAuthLoaded: true };
        case USER_TOKEN_UPDATED:
            return { ...state, tokenResult: action.value };
        case USER_DOC_UPDATED:
            return { ...state, userDoc: action.value };
        case SCHOOL_PERMISSIONS_UPDATED:
            const schools = action.value;
            return {
                ...state,
                schools: action.value,
                _schoolPermsLoaded: true,
                ...getCurrentlyManagedSchoolFromUrl(state._schoolIdFromUrl, schools)
            };
        case ACTION_SUCCESS:
            if (action.action.type === USER_SIGNED_IN) {
                return { ...state, userSessionId: action.value };
            }
            return state;
        default:
            return state;
    }
};

export default (state: AuthStoreState = initialState, action: AuthAction): AuthStoreState => {
    const newState = applyAction(state, action);

    return {
        ...newState,
        permissionsLoaded: (newState.user !== null && newState.userDoc !== null && newState._schoolPermsLoaded)
    };
};
