import { combineReducers, Reducer, AnyAction } from 'redux';
import SearchReducer from './SearchReducer';
import SchoolReducer from './SchoolReducer';
import BookingReducer from './BookingReducer';
import SchoolBookingReducer from './SchoolBookingReducer';
import AuthReducer from './AuthReducer';
import SchoolUserReducer from './SchoolUserReducer';
import SchoolSpotReducer from './SchoolSpotReducer';
import SchoolSettingsReducer from './SchoolSettingsReducer';
import SchoolSessionReducer from './SchoolSessionReducer';
import SchoolJobsReducer from './SchoolJobsReducer';
import DestinationReducer from './DestinationReducer';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import SignInReducer from './SignInReducer';
import AnalyticsReducer from './AnalyticsReducer';
import { gaEvent } from '../analytics';
import ActivityReducer from './ActivityReducer';
import SessionReducer from './SessionReducer';
import InstructorReducer from './InstructorReducer';

const withoutType = ({ type, ...rest }: { type: string }) => rest;

export default (history: History) => combineReducers({
    router: connectRouter(history) as Reducer<any>,
    search: SearchReducer as Reducer<any>,
    school: SchoolReducer as Reducer<any>,
    booking: BookingReducer as Reducer<any>,
    sessions: SessionReducer as Reducer<any>,
    auth: AuthReducer as Reducer<any>,
    analytics: AnalyticsReducer as Reducer<any>,
    schoolBooking: SchoolBookingReducer as Reducer<any>,
    schoolUsers: SchoolUserReducer as Reducer<any>,
    schoolSettings: SchoolSettingsReducer as Reducer<any>,
    schoolSpots: SchoolSpotReducer as Reducer<any>,
    schoolSessions: SchoolSessionReducer as Reducer<any>,
    schoolJobs: SchoolJobsReducer as Reducer<any>,
    destination: DestinationReducer as Reducer<any>,
    activity: ActivityReducer as Reducer<any>,
    signin: SignInReducer as Reducer<any>,
    instructors: InstructorReducer as Reducer<any>,
    logger: (s = {}, a: AnyAction) => {
        if (a.sensitive || (a.action && a.action.sensitive)) {
            console.log(a.type, '***');
        } else {
            console.log(a.type, a.value || withoutType(a));
        }
        return s;
    },
    ga: (s = {}, a: AnyAction) => { gaEvent({ category: 'Redux', action: a.type }); return s; }
});
