import {
    ActivityAction,
    ActivityUpdated,
    ACTIVITY_UPDATED
} from '../actions/ActivityActions';
import { ActivityStoreState, ActivityDictionary } from '../types';
import _ from 'lodash';

const initialState: ActivityStoreState = {
    activities: {}
};

const getUpdatedState = (state: ActivityStoreState, action: ActivityUpdated) => {
    const destinations = _.omitBy(state.activities, dest =>
        !action.value.removes.find(rem => 
            rem.activityId === dest.activityId)) as ActivityDictionary;
    action.value.adds.forEach(add => destinations[add.activityId] = add);
    return destinations;
};

export default (state: ActivityStoreState = initialState, action: ActivityAction): ActivityStoreState => {
    switch (action.type) {
        case ACTIVITY_UPDATED:
            return { ...state, activities: getUpdatedState(state, action) };
        default:
            return state;
    }
};
