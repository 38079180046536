import _ from 'lodash';
import moment from 'moment';
import {
    BOOKING_REQUEST_FIELD_CHANGED,
    USER_BOOKING_UPDATED,
    BookingAction,
    UserBookingUpdated,
    ACCEPT_BOOKING_OFFERS,
    CREATE_BOOKING,
    LOAD_BOOKING,
    ADD_ITINERARY_ITEM,
    REMOVE_ITINERARY_ITEM,
    RemoveItineraryItem,
    BookingRequestFieldChanged,
    NEW_BOOKING_SCHOOLS_CHANGED,
    NewBookingSchoolsChanged,
    NewBookingInstantSettingsChanged,
    NEW_BOOKING_INSTANT_SETTINGS_CHANGED,
    NEW_BOOKING_INSTANT_OFFER_CHANGED,
    NewBookingInstantOfferChanged,
    NEW_BOOKING_DATES_CHANGED,
    NewBookingDatesChanged,
    ADD_NEW_BOOKING_SCHOOL_ACTIVITY,
    AddNewBookingSchoolActivity,
    NEW_BOOKING_PEOPLE_CHANGED,
    NewBookingPeopleChanged,
    ADD_NEW_BOOKING_ACTIVITY,
    AddNewBookingActivity,
    RemoveNewBookingActivity,
    REMOVE_NEW_BOOKING_ACTIVITY,
    addNewBookingActivity,
    CREATE_INSTANT_BOOKING,
    CHANGE_NEW_BOOKING_ACTIVITY_SKILL,
    ChangeNewBookingActivitySkill,
} from '../actions/BookingActions';
import {
    BookingStoreState,
    RequestForm,
    BookingItinerary,
    UserBookingDictionary,
    UserBooking,
    BookingItineraryItem,
    BookingSkills,
    SessionTypes,
    offerPricePerPersonPerDay,
    InstantOffer,
    SESSION_FORMAT_PRIVATE,
    SESSION_FORMAT_SEMI_PRIVATE,
    SESSION_FORMAT_GROUP,
    SchoolInstantBookingSettings,
    NewBookingForm,
    getActivityArray,
    SchoolInstantBookingOption,
    NewBookingGroupMember,
    OFFER_PRICE_PER_PERSON_DAY,
    inSeason,
    Season,
    InstantBookingRecommended,
} from '../types';
import { FIRESTORE_REMOVED } from '../constants';
import { ACTION_SUCCESS } from '../actions/Common';
import { USER_SIGNED_IN } from '../actions/AuthActions';
import { GetBookingResult } from '../api/Commands';
import { i18n } from '../i18n';
import { DESTINATION_UPDATED, DestinationAction, DestinationsUpdated } from '../actions/DestinationActions';

const createNewRequestForm = (activity: string) => {
    return {
        activity: activity,
        type: '',
        days: 0,
        start: '',
        end: '',
        language: '',
        group: []
    } as RequestForm;
};

const initialState: BookingStoreState = {
    newBooking: {
        name: '',
        email: '',
        tel: '',
        requests: {},
        skip:
        {
            who: false,
            whoActivity: {},
            type: {}
        },
        schools: [],
        commissionModels: {},
        instantBookingSettings: {},
        destinationSeasons: {},
        recommended: {
            destinationId: null,
            dates: { startDate: null, endDate: null },
            schools: {},
        },
        preferences: {
            destinationId: null,
            dates: { startDate: null, endDate: null },
            people: [],
            activities: [],
            skills: {},
            schools: {},
        },
        instantOffers: [],
    },
    newBookingResult: null,
    getBookingRequest: null,
    getBookingResult: null,
    bookings: {},
    acceptingBookingId: '',
    itineraries: {},
};

export default (state: BookingStoreState = initialState, action: BookingAction | DestinationAction): BookingStoreState => {
    switch (action.type) {
        case BOOKING_REQUEST_FIELD_CHANGED:
            return updateRequestForm(action, state);
        case NEW_BOOKING_SCHOOLS_CHANGED:
            return updateRequestFormSchools(action, state);
        case NEW_BOOKING_INSTANT_SETTINGS_CHANGED:
            return updateRequestFormInstantBookingSettings(action, state);
        case NEW_BOOKING_INSTANT_OFFER_CHANGED:
            return updateRequestFormInstantOffer(action, state);
        case ADD_NEW_BOOKING_SCHOOL_ACTIVITY:
            return updateRequestFormAddSchoolActivity(action, state);
        case CHANGE_NEW_BOOKING_ACTIVITY_SKILL:
            return updateRequestFormChangeActivitySkill(action, state);
        case ADD_NEW_BOOKING_ACTIVITY:
            return updateRequestFormAddActivity(action, state);
        case REMOVE_NEW_BOOKING_ACTIVITY:
            return updateRequestFormRemoveActivity(action, state);
        case NEW_BOOKING_DATES_CHANGED:
            return updateNewBookingDates(action, state);
        case NEW_BOOKING_PEOPLE_CHANGED:
            return updateNewBookingPeople(action, state);
        case DESTINATION_UPDATED:
            return updateDestinationSeasons(action, state);
        case USER_BOOKING_UPDATED:
            return updateBooking(action, state);
        case ACCEPT_BOOKING_OFFERS:
            return { ...state, acceptingBookingId: action.value.bookingId };
        case ACTION_SUCCESS:
            if (action.action.type === CREATE_BOOKING ||
                action.action.type === CREATE_INSTANT_BOOKING) {
                return { ...state, newBookingResult: action.value };
            }
            if (action.action.type === LOAD_BOOKING) {
                const result = action.value as GetBookingResult;
                if (!result.booking) {
                    return {
                        ...state,
                        getBookingResult: result
                    };
                }
                const newState = {
                    ...state,
                    bookings: {
                        ...state.bookings,
                        [result.booking.bookingId]: result.booking
                    }
                };
                return {
                    ...newState,
                    itineraries: {
                        ...newState.itineraries,
                        [result.booking.bookingId]: buildItineraryFromOffers(result.booking, newState)
                    }
                };
            }
            if (action.action.type === USER_SIGNED_IN) {
                return { ...state, newBookingResult: null, getBookingResult: null };
            }
            return state;
        case ADD_ITINERARY_ITEM:
            return {
                ...state,
                itineraries: {
                    ...state.itineraries,
                    [action.value.bookingId]: addToItinerary(action.value, state.itineraries[action.value.bookingId], state.bookings)
                }
            };
        case REMOVE_ITINERARY_ITEM:
            return {
                ...state,
                itineraries: {
                    ...state.itineraries,
                    [action.value.bookingId]: removeFromItinerary(action, state)
                }
            };
        case LOAD_BOOKING:
            return {
                ...state,
                getBookingRequest: action
            };
        default:
            return state;
    }
};

const removeFromItinerary = (action: RemoveItineraryItem, state: BookingStoreState) => {
    const newItinerary = {
        ...state.itineraries[action.value.bookingId],
        bookingId: action.value.bookingId,
        items: _.filter(
            state.itineraries[action.value.bookingId]
                ? state.itineraries[action.value.bookingId].items
                : [],
            i => i.offerId !== action.value.offerId || i.day !== action.value.day),
    };

    newItinerary.validation = validateItinerary(newItinerary, state.bookings);
    newItinerary.total = getItineraryPrice(newItinerary, state.bookings);

    return newItinerary;
};

const addToItinerary = (item: BookingItineraryItem, itinerary: BookingItinerary, bookings: UserBookingDictionary) => {
    const existingItems = itinerary ? itinerary.items : [];
    const isNotItem = (i: BookingItineraryItem) => i.offerId !== item.offerId || i.day !== item.day;
    const newItinerary = {
        ...itinerary,
        bookingId: item.bookingId,
        items: [
            ...existingItems.filter(isNotItem),
            item
        ]
    };

    newItinerary.validation = validateItinerary(newItinerary, bookings);
    newItinerary.total = getItineraryPrice(newItinerary, bookings);

    return newItinerary;
};

const getItineraryPrice = (itinerary: BookingItinerary, bookings: UserBookingDictionary) => {
    const result = {
        currency: '',
        price: 0,
        pricePer: '',
        deposit: 0
    };

    const deposits: { [offerId: string]: number } = {};

    const booking = bookings[itinerary.bookingId];

    _.forEach(itinerary.items, i => {
        _.forEach(booking.requests, r => {
            const offer = r.offers[i.offerId];
            if (offer && offer.totalDays) {
                result.price += offerPricePerPersonPerDay(offer);
                result.pricePer = OFFER_PRICE_PER_PERSON_DAY;
                result.currency = offer.currency; // assumes they are all the same...
                deposits[offer.offerId] = offer.deposit;
            }
        });
    });

    _.forEach(booking.instantOffers, io => {
        result.price += io.total;
        result.pricePer = '';
        result.currency = io.currency; // assumes they are all the same...
        deposits[io.offerId] = io.deposit;
    });

    return { ...result, deposit: _.sum(_.values(deposits)) };
};

const validateItinerary = (itinerary: BookingItinerary, bookings: UserBookingDictionary) => {
    let isValid = true;
    let invalidOfferIds: string[] = [];

    if (!itinerary || !itinerary.bookingId || !itinerary.items) {
        return {
            isValid: false,
            invalidOfferIds
        };
    }

    const booking = bookings[itinerary.bookingId];

    if (!booking) {
        return {
            isValid: false,
            invalidOfferIds
        };
    }

    if (!itinerary.items.length) {
        return {
            isValid: false,
            invalidOfferIds
        };
    }

    isValid = true;
    _.forEach(booking.requests, r => {
        _.forEach(r.offers, o => {
            const items = itinerary.items.filter(i =>
                i.schoolId === o.schoolId &&
                i.offerId === o.offerId);

            const tooFewDays = items.length > 0 && items.length < o.totalDays;
            const tooManyDays = items.length > 0 && items.length > o.totalDays;

            if (tooFewDays || tooManyDays) {
                isValid = false;
                invalidOfferIds.push(o.offerId);
            }
        });
    });

    return {
        isValid,
        invalidOfferIds
    };
};

const buildItineraryFromOffers = (booking: UserBooking, state: BookingStoreState) => {
    let itinerary = state.itineraries[booking.bookingId];

    _.forEach(booking.requests, r => {
        _.forEach(r.offers, o => {
            if (o.accepted) {
                _.forEach(o.accepted.acceptedDays, acceptedDay =>
                    itinerary = addToItinerary(
                        {
                            bookingId: booking.bookingId,
                            schoolId: o.schoolId,
                            offerId: o.offerId,
                            day: acceptedDay
                        },
                        itinerary,
                        state.bookings));
            }
        });
    });

    _.forEach(booking.instantOffers, o => {
        const dayIterator = moment(o.start);
        const numDays = moment(o.end).diff(dayIterator, 'd') + 1;
        const daySequence = Array.from(Array(numDays).keys());

        _.forEach(daySequence, () => {
            const strVal = dayIterator.format('YYYY-MM-DD');
            dayIterator.add(1, 'day');
            itinerary = addToItinerary(
                {
                    bookingId: booking.bookingId,
                    schoolId: o.schoolId,
                    offerId: o.offerId,
                    day: strVal
                },
                itinerary,
                state.bookings);
        });
    });

    return itinerary;
};

const updateRequestFormSchools = (action: NewBookingSchoolsChanged, state: BookingStoreState): BookingStoreState => {
    let newState: BookingStoreState = {
        ...state,
        newBooking: {
            ...state.newBooking,
            schools: action.value.schools,
        },
        newBookingResult: null
    };

    if (newState.newBooking.preferences.activities.length === 0) {
        const allActivities = _.flatMap(newState.newBooking.schools.map(getActivityArray));
        const activityCount = _.map(_.countBy(allActivities, a => a), (v, k) => ({ activity: k, count: v }));
        const mostPopular = _.maxBy(activityCount, ac => ac.count);

        if (mostPopular) {
            newState = updateRequestFormAddActivity(addNewBookingActivity(mostPopular.activity), newState);
        }
    }

    return newState;
};

const activityInSeason = (season: Season, { startDate, endDate }: { startDate: moment.Moment | null, endDate: moment.Moment | null }) => {
    return season && startDate && endDate &&
        inSeason(season, startDate.month()) &&
        inSeason(season, endDate.month());
};

const updateNewBookingDates = (action: NewBookingDatesChanged, state: BookingStoreState): BookingStoreState => {

    const updatedBooking: NewBookingForm = {
        ...state.newBooking,
        preferences: {
            ...state.newBooking.preferences,
            dates: { ...action.value },
        },
        instantOffers: []
    };

    return {
        ...state,
        newBooking: addRecommendedOffers(updatedBooking),
        newBookingResult: null
    };
};

const updateDestinationSeasons = (action: DestinationsUpdated, state: BookingStoreState): BookingStoreState => {
    // keep track of the destination seasons
    const updatedSeasons = { ...state.newBooking.destinationSeasons };
    action.value.adds.forEach(d => {
        updatedSeasons[d.destinationId] = d.seasons || {};
    });
    action.value.removes.forEach(d => {
        updatedSeasons[d.destinationId] = {};
    });

    // filter out offers for activities which aren't in season
    const filteredOffers = state.newBooking.instantOffers.filter(o => {
        const seasons = updatedSeasons[o.destinationId] || {};
        const season = seasons[o.activity];
        return !season || activityInSeason(season, state.newBooking.preferences.dates);
    });

    return {
        ...state,
        newBooking: {
            ...state.newBooking,
            instantOffers: filteredOffers,
            destinationSeasons: updatedSeasons
        },
    };
};

const getNewBookingActivitySkill = (activity: string, booking: NewBookingForm) => {
    return booking.preferences.skills[activity] || BookingSkills[0];
};

const updateNewBookingPeople = (action: NewBookingPeopleChanged, state: BookingStoreState): BookingStoreState => {
    const offers = [...state.newBooking.instantOffers];
    const { people } = action.value;

    // update number of people on newBooking
    let updatedNewBooking: NewBookingForm = {
        ...state.newBooking,
        instantOffers: offers,
        preferences: {
            ...state.newBooking.preferences,
            people: [...people],
        },
    };

    // update people's names on each offer
    _.forEach(offers, o => {
        o.group = people.map((p, i) => {
            if (i >= o.group.length) {
                return {
                    ...p,
                    isSelected: i === 0,
                    email: '',
                    skill: getNewBookingActivitySkill(o.activity, updatedNewBooking),
                    tel: ''
                };
            }
            return {
                ...o.group[i],
                ...p
            };
        });

        updateDerivedOfferFields(o, state.newBooking);
    });

    return {
        ...state,
        newBooking: addRecommendedOffers(updatedNewBooking),
        newBookingResult: null
    };
};

const updateRequestFormInstantOffer = (action: NewBookingInstantOfferChanged, state: BookingStoreState): BookingStoreState => {
    const { newBooking } = state;
    let pinnedOffer = { ...action.value.offer, recommendation: false };

    const otherOffers = _.filter(newBooking.instantOffers, o => o.offerId !== pinnedOffer.offerId);

    let updatedNewBooking: NewBookingForm = {
        ...newBooking,
        instantOffers: otherOffers,
    };

    // remove offer if no people
    const numSelectedPeople = _.filter(pinnedOffer.group, p => p.isSelected).length;
    const removeOffer = numSelectedPeople === 0;

    if (!removeOffer) {
        const settings = newBooking.instantBookingSettings[pinnedOffer.schoolId];
        if (settings) {
            // update option if they have changed num of people on offer
            const options = getRecommendedOptions(settings, numSelectedPeople, pinnedOffer.activity);
            if (options.length > 0) {
                const opt = options[0];
                pinnedOffer = { ...pinnedOffer, ...opt };
            }
        }
        updateDerivedOfferFields(pinnedOffer, newBooking);
        updatedNewBooking.instantOffers.push(pinnedOffer);
    }

    return {
        ...state,
        newBooking: addRecommendedOffers(updatedNewBooking),
        newBookingResult: null
    };
};

const getRecommendedOptions = (settings: SchoolInstantBookingSettings, numPeople: number, activity: string): SchoolInstantBookingOption[] => {
    const options = _.filter(settings.options, o =>
        o.enabled &&
        o.activity === activity &&
        !!o.format);

    const semiPrivateOption = _.find(options, o =>
        o.format === SESSION_FORMAT_SEMI_PRIVATE);

    const privateOption = _.find(options, o =>
        o.format === SESSION_FORMAT_PRIVATE);

    const groupOption = _.find(options, o =>
        o.format === SESSION_FORMAT_GROUP);

    if (numPeople === 1) {
        if (privateOption) {
            return [privateOption];
        }
        if (groupOption && groupOption.minPeople >= numPeople) {
            return [groupOption];
        }
    } else if (numPeople === 2) {
        if (semiPrivateOption) {
            return [semiPrivateOption];
        }
        if (groupOption && groupOption.minPeople >= numPeople) {
            return [groupOption];
        }
        if (privateOption) {
            return [privateOption, privateOption];
        }
    } else if (numPeople >= 3) {
        if (groupOption) {
            if (groupOption.maxPeople >= numPeople) {
                return [groupOption];
            }
            // if the max group size is less than number of people, 
            // then recursively call this function for the leftover people
            const numLeftOverPeople = numPeople % groupOption.maxPeople;
            const numGroups = (numPeople - numLeftOverPeople) / groupOption.maxPeople;
            const groups = _.range(1, numGroups + 1).map(() => groupOption);
            const leftOverOptions = getRecommendedOptions(settings, numLeftOverPeople, activity);
            return [...groups, ...leftOverOptions];
        }
        if (semiPrivateOption) {
            if (numPeople % 2 === 0) {
                const numLessons = (numPeople) / 2;
                return _.range(1, numLessons + 1).map(() => semiPrivateOption);
            } else {
                // if there's an odd number of people, then the last person will get 
                // a private lesson if available, or a semi-private lesson with only them
                const numLessons = (numPeople - 1) / 2;
                const semiPrivateOptions = _.range(1, numLessons + 1).map(() => semiPrivateOption);
                const lastPersonOption = privateOption ? privateOption : semiPrivateOption;
                return [...semiPrivateOptions, lastPersonOption];
            }
        }
        if (privateOption) {
            return _.range(1, numPeople + 1).map(() => privateOption);
        }
    }
    return [];
};

const updateRequestFormChangeActivitySkill = (action: ChangeNewBookingActivitySkill, state: BookingStoreState): BookingStoreState => {
    const { newBooking } = state;
    const { skills: activitySkill } = newBooking.preferences;
    const { activity, skill } = action.value;

    return {
        ...state,
        newBooking: {
            ...newBooking,
            preferences: {
                ...newBooking.preferences,
                skills: {
                    ...activitySkill,
                    [activity]: skill
                },
            },
        },
    };
};

const updateRequestFormAddActivity = (action: AddNewBookingActivity, state: BookingStoreState): BookingStoreState => {
    const { newBooking } = state;
    const { activities, skills: activitySkill } = newBooking.preferences;
    const { activity } = action.value;

    if (activities.indexOf(activity) >= 0) {
        // activity already added
        return state;
    }

    let updatedNewBooking: NewBookingForm = {
        ...newBooking,
        preferences: {
            ...newBooking.preferences,
            activities: [
                ...activities,
                activity
            ],
            skills: {
                ...activitySkill,
                [activity]: activitySkill[activity] || BookingSkills[0], // default to 'first time'
            }
        },
    };

    return {
        ...state,
        newBooking: addRecommendedOffers(updatedNewBooking),
        newBookingResult: null
    };
};

const getSeasonActivities = (newBooking: NewBookingForm) => {
    const { schools, preferences } = newBooking;
    const { activities, dates } = preferences;

    const destinationIds = _.uniq(_.map(schools, s => s.destinationId));
    const seasons = _.filter(newBooking.destinationSeasons, (d, id) => destinationIds.indexOf(id) >= 0);

    return activities.filter(a =>
        _.some(seasons, s => activityInSeason(s[a], dates)));
};

/* TODO more intelligently recommend a school */
const getRecommendedSchoolId = (activity: string, newBooking: NewBookingForm) => {
    const { preferences, recommended } = newBooking;
    const { activities } = preferences;

    // get pinned offers for school
    const numPinnedOffers = _.countBy(newBooking.instantOffers, o => o.schoolId && !o.recommendation);

    // get schools with instant booking settings, for preferred or recommended destination
    const destinationId = preferences.destinationId || recommended.destinationId;
    const schools = newBooking.schools.filter(s =>
        !!newBooking.instantBookingSettings[s.schoolId] &&
        !!newBooking.instantBookingSettings[s.schoolId].enabled &&
        (!destinationId || s.destinationId === destinationId));

    const schoolCriteria = _.map(schools, school => {
        const { schoolId } = school;
        const settings = newBooking.instantBookingSettings[schoolId];
        const options = _.filter(settings.options, opt => opt.enabled);
        const activitiesOffered = _.uniq(options.map(opt => opt.activity));
        return {
            schoolId,
            numPinned: numPinnedOffers[schoolId] || 0,
            numOptions: _.filter(options, opt => opt.activity === activity).length,
            coverage: _.sumBy(activities, a => activitiesOffered.indexOf(a) >= 0 ? 1 : 0),
        };
    });

    const recommendedSchool = _.maxBy(schoolCriteria, s =>
        (100 * s.coverage) + // if school offers most of the selected activities
        (10 * s.numPinned) + // if there are existing pinned offers for this school
        (Math.random() * s.numOptions) // otherwise pick school with most options for this activity (private, group, etc) with random multiplier
    );

    if (recommendedSchool) {
        console.log('recommendedSchool', activity, recommendedSchool);
        return recommendedSchool.schoolId;
    }

    return '';
};

const updateRequestFormRemoveActivity = (action: RemoveNewBookingActivity, state: BookingStoreState): BookingStoreState => {
    const { newBooking } = state;
    const { activities } = newBooking.preferences;
    const { activity } = action.value;

    if (activities.indexOf(activity) < 0) {
        return state;
    }

    return {
        ...state,
        newBooking: {
            ...newBooking,
            preferences: {
                ...state.newBooking.preferences,
                activities: activities.filter(a => a !== activity),
            },
            instantOffers: _.filter(newBooking.instantOffers, o => o.activity !== activity),
        },
        newBookingResult: null
    };
};

const updateRequestFormAddSchoolActivity = (action: AddNewBookingSchoolActivity, state: BookingStoreState): BookingStoreState => {
    const { newBooking } = state;
    const { schoolId, activity } = action.value;

    let updatedNewBooking: NewBookingForm = {
        ...newBooking,
        preferences: {
            ...newBooking.preferences,
            schools: {
                ...newBooking.preferences.schools,
                [activity]: schoolId
            }
        }
    };

    return {
        ...state,
        newBooking: addRecommendedOffers(updatedNewBooking),
        newBookingResult: null
    };
};

// return the mininum start date where all selected activities are in-season
const getInstantBookingRecommended = (newBooking: NewBookingForm): InstantBookingRecommended => {
    const { recommended, preferences, destinationSeasons, schools } = newBooking;
    const { activities } = preferences;

    const today = moment();
    const preferredStart = preferences.dates.startDate || today;

    const activityScores = _.flatMapDeep(destinationSeasons, (seasons, id) => {

        // get coverage of activites for this destination
        const coverage = _.sumBy(_.keys(seasons), (da => (activities.indexOf(da) >= 0 ? 1 : 0)));

        // find best start date for each activity
        return _.map(seasons, (s, a) => {
            let date: moment.Moment | null = null;
            s.months.forEach((rating, month) => {
                if (rating > 0) {
                    if (month > preferredStart.month()) {
                        // season starts later this year
                        if (!date || date.year() > preferredStart.year()) {
                            date = moment().year(preferredStart.year()).month(month).date(2).endOf('isoWeek').subtract(1, 'days'); // first Saturday of month
                        }
                    } else if (month === preferredStart.month()) {
                        // season has started
                        date = moment().year(preferredStart.year()).month(month).date(preferredStart.date()).add(3, 'days').endOf('isoWeek').subtract(1, 'days'); // next Saturday
                    } else if (month < preferredStart.month()) {
                        // season starts next year
                        date = moment().year(preferredStart.year() + 1).month(month).date(2).endOf('isoWeek').subtract(1, 'days'); // first Saturday of month, next year
                    }
                }
            });

            if (!date) {
                console.error(`could not determine best start date for ${id} ${a} season`, s);
                return {
                    destinationId: id,
                    activity: a,
                    date: null,
                    score: 0
                };
            }

            const rankScore = (1 / (s.rank || 1)); // rank of 1 means this is top activity in destination
            const numSchools = schools.filter(school => id === school.destinationId && school.activities.includes(a)).length;
            const numDaysToWait = Math.abs(preferredStart.diff(date, 'days'));

            return {
                destinationId: id,
                activity: a,
                date: date,
                score: (1000 * coverage) + // does this destination cover all the selected activities?
                    (100 * rankScore) + // how good is the destination for this activity?
                    (100 * 1 / numDaysToWait) + // how long do they have to wait?
                    numSchools + // how many schools at the destination offer this activity?
                    Math.random() // so you dont always get same one
            };
        });
    });

    console.log('activityScores', activityScores);

    const best = _.maxBy(activityScores, a => a.score);

    const recommendedSchools = {};
    if (best) {
        activities.forEach(a => {
            recommendedSchools[a] = getRecommendedSchoolId(a, newBooking);
        });
    }

    return {
        ...recommended,
        destinationId: best?.destinationId || null,
        schools: recommendedSchools,
        dates: {
            startDate: preferences.dates.startDate || best?.date || null,
            endDate: preferences.dates.endDate || ((!!best && !!best.date) ? (best.date as unknown as moment.Moment).clone().add(4, 'days') : null),
        }
    };
};

const addRecommendedOffers = (newBooking: NewBookingForm): NewBookingForm => {
    let updatedBooking: NewBookingForm = {
        ...newBooking,
    };

    updatedBooking.recommended = getInstantBookingRecommended(updatedBooking);

    const { preferences, recommended, instantOffers } = updatedBooking;

    if (!preferences.dates.startDate) {
        preferences.dates.startDate = recommended.dates.startDate?.clone() || null;
        preferences.dates.endDate = recommended.dates.endDate?.clone() || null;
    }

    const pinnedOffers = _.filter(instantOffers, o => !o.recommendation);
    const seasonActivities = getSeasonActivities(newBooking);
    const isInSeason = (a: string) => seasonActivities.indexOf(a) >= 0;

    preferences.activities
        .filter(isInSeason)
        .forEach(activity => {
            let schoolId = preferences.schools[activity] || recommended.schools[activity];

            if (schoolId) {
                const otherActivities = _.filter(instantOffers, o => o.recommendation && o.activity !== activity);

                updatedBooking = {
                    ...updatedBooking,
                    instantOffers: [
                        ...pinnedOffers,
                        ...otherActivities,
                        ...getRecommendedOffers(schoolId, activity, updatedBooking),
                    ]
                };
            }
        });

    return updatedBooking;
};

const getRecommendedOffers = (schoolId: string, activity: string, newBooking: NewBookingForm) => {
    const { instantOffers, preferences, schools, instantBookingSettings, destinationSeasons } = newBooking;

    const school = schools.find(s => s.schoolId === schoolId);
    if (!school) {
        console.warn(`school ${schoolId} not found`);
        return [];
    }

    const settings = instantBookingSettings[schoolId];

    if (!settings) {
        console.warn(`school ${schoolId} instant booking settings not found`);
        return [];
    }

    const seasons = destinationSeasons[school.destinationId] || {};
    const season = seasons[activity];

    if (!season) {
        console.warn(`season for ${school.destinationId} ${activity} not found`);
        return [];
    }

    if (!inSeason(season, preferences.dates.startDate?.month() || -1)) {
        console.warn(`out of season for ${school.destinationId} ${activity}`);
        return [];
    }

    const pinnedOffers = _.filter(instantOffers, o => o.activity === activity && !o.recommendation);

    const alreadyDoingActivity = ({ name }: { name: string }) =>
        pinnedOffers.find(o => !!o.group.find(p => p.name === name && p.isSelected));

    const remainingGroup = preferences.people.map(p => ({
        name: p.name,
        email: '',
        isSelected: !alreadyDoingActivity(p),
        skill: getNewBookingActivitySkill(activity, newBooking),
        tel: ''
    }));

    const numPeople = remainingGroup.filter(p => p.isSelected).length;
    const options = getRecommendedOptions(settings, numPeople, activity);
    const offers = getOffersForOptions(options, remainingGroup, schoolId, school.destinationId, activity, newBooking, true);
    return offers;
};

const getOffersForOptions = (options: SchoolInstantBookingOption[], group: NewBookingGroupMember[], schoolId: string, destinationId: string, activity: string, newBooking: NewBookingForm, recommendation: boolean) => {
    const { dates, people } = newBooking.preferences;
    const offers: InstantOffer[] = [];
    const selectedGroup = group.filter(m => m.isSelected);

    let groupOffset = 0;
    options.forEach(opt => {

        const selectedForOffer = selectedGroup.slice(groupOffset, groupOffset + opt.maxPeople);
        groupOffset += selectedForOffer.length;

        const groupForOffer = people.map(p => ({
            name: p.name,
            isSelected: !!selectedForOffer.find(m => m.name === p.name),
            tel: '',
            skill: getNewBookingActivitySkill(activity, newBooking),
            email: '',
        }));

        const idSuffix = groupForOffer
            .map((p, i) => (p.isSelected ? `${i + 1}` : `0`)).join('-');

        let offer: InstantOffer = {
            ...opt,
            offerId: `${schoolId}-${activity}-${idSuffix}`,
            activity,
            schoolId,
            destinationId,
            deposit: 0,
            fee: 0,
            start: dates.startDate && dates.endDate ? dates.startDate.format('YYYY-MM-DD HH:mm') : '',
            end: dates.startDate && dates.endDate ? moment.min(moment(dates.startDate).add(2, 'd'), dates.endDate).format('YYYY-MM-DD HH:mm') : '',
            instructors: 1,
            group: groupForOffer,
            recommendation,
            total: 0,
            days: 0,
            language: i18n.language?.toLowerCase(), // default browser language
        };

        updateDerivedOfferFields(offer, newBooking);

        offers.push(offer);
    });

    return offers;
};

const updateDerivedOfferFields = (offer: InstantOffer, { commissionModels }: NewBookingForm) => {
    offer.days = offer.start && offer.end ? Math.max(1, moment(offer.end).diff(moment(offer.start), 'd') + 1) : 1;
    offer.total = getOfferTotal(offer);
    offer.deposit = roundUpToTen(commissionModels[offer.schoolId]?.commissionRate * offer.total);
    offer.fee = commissionModels[offer.schoolId]?.feeAmount;
};

const roundUpToTen = (n: number) => Math.ceil(n / 10) * 10;

const getOfferTotal = ({ group, start, end, hoursPerDay, price, pricePer, days }: InstantOffer) => {
    let total = price;

    if (pricePer.toLowerCase().includes('/person')) {
        total *= group.filter(m => m.isSelected).length;
    }

    if (start && end) {
        if (pricePer.toLowerCase().includes('/hour')) {
            total *= (days * hoursPerDay);
        } else if (pricePer.toLowerCase().includes('/day')) {
            total *= days;
        }
    }

    return total;
};

const updateRequestFormInstantBookingSettings = (action: NewBookingInstantSettingsChanged, state: BookingStoreState): BookingStoreState => {
    let updatedNewBooking = {
        ...state.newBooking,
        instantBookingSettings: action.value.settings,
        commissionModels: action.value.commissionModels,
    };

    return {
        ...state,
        newBooking: addRecommendedOffers(updatedNewBooking),
        newBookingResult: null
    };
};

const updateRequestForm = (action: BookingRequestFieldChanged, state: BookingStoreState) => {
    const { activity, field, value } = action.value;

    if (!activity) {
        return {
            ...state,
            newBooking: {
                ...state.newBooking,
                [field]: value
            },
            newBookingResult: null
        };
    }

    const newState = {
        ...state,
        newBooking: {
            ...state.newBooking,
            requests: {
                ...state.newBooking.requests,
                [activity]: {
                    ...(state.newBooking.requests[activity] || createNewRequestForm(activity)),
                    [field]: value
                }
            }
        },
        newBookingResult: null
    };

    const { newBooking } = newState;
    const { requests } = newBooking;
    const form = requests[activity];
    const isSelectedActivity = form.days > 0;
    const isGroupSizeSet = form.group.length > 0;

    if (field === 'days') {
        const oldEndDate = moment(form.end || form.start || '');
        const newEndDate = moment(form.start || '');
        newEndDate.add(form.days || 0, 'd');
        form.end = moment.max(oldEndDate, newEndDate).format('YYYY-MM-DD');
    } else if (field === 'start') {
        const newEndDate = moment(form.start || '');
        newEndDate.add(form.days || 0, 'd');
        form.end = newEndDate.format('YYYY-MM-DD');
    } else if (field === 'end') {
        const newEndDate = moment(form.end || '');
        form.days = Math.max(1, newEndDate.diff(moment(form.start || '', 'd'))) || 1;
    }

    // auto-select everyone if only one person or only one activity
    const singlePerson = isSelectedActivity && form.group.length === 1;
    const singleActivity = _.filter(requests, r => r.days > 0 && r.group.length > 0).length === 1;
    if (singlePerson || singleActivity) {
        _.forEach(form.group, m => m.isSelected = true);
    }

    if (isSelectedActivity && isGroupSizeSet) {
        const bookingPerson = form.group[0];
        newState.newBooking.name = bookingPerson.name;
        newState.newBooking.email = bookingPerson.email;
        newState.newBooking.tel = bookingPerson.tel;
        newState.newBooking.skip.who = singlePerson;
        newState.newBooking.skip.whoActivity[activity] = singlePerson || singleActivity;

        // default to 'lessons' for 'first time' or 'beginner'
        const selectedGroup = form.group.filter(m => m.isSelected && !!m.skill);
        const minSkill = _.min(selectedGroup.map(m => BookingSkills.indexOf(m.skill)));
        if (minSkill === 0 || minSkill === 1) {
            form.type = SessionTypes[0].name;
            newState.newBooking.skip.type[activity] = true;
        }
    }

    return newState;
};

const updateBooking = (action: UserBookingUpdated, state: BookingStoreState) => {
    const bookingId = action.value.id;
    if (action.value.change === FIRESTORE_REMOVED) {
        return {
            ...state,
            bookings: _.omit(state.bookings, bookingId),
            itineraries: _.omit(state.itineraries, bookingId)
        };
    } else {
        const newState = {
            ...state,
            bookings: {
                ...state.bookings,
                [bookingId]: action.value.data
            }
        };
        return {
            ...newState,
            itineraries: {
                ...newState.itineraries,
                [bookingId]: buildItineraryFromOffers(action.value.data, newState)
            }
        };
    }
};
