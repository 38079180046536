import * as React from 'react';
import { LocationIconSvg } from '../SvgImports';

const LocationIcon = ({ styleClass }: { styleClass?: string }) => {
    return (
        <img className={styleClass || 'locationIcon'} src={LocationIconSvg} alt="Location icon" />
    );
};

export default LocationIcon;
