import * as React from 'react';
import './NotFound.css';

const NotFound = ({ children }: { children?: any }) => {
    return (
        <div className="notFound flex-col">
            {children ? children : <label>Not Found</label>}
        </div>
    );
};

export default NotFound;
