import * as React from 'react';
import './LanguagePicker.css';
import { DropDown } from './DropDown';
import { i18n, dynamicT } from '../../i18n';
import _ from 'lodash';

const LanguageChoice = (props: { langCode: string }) => {
    return (
        <p
            className="langChoice"
            onClick={() => i18n.changeLanguage(props.langCode)}
        >
            {dynamicT(`languages.${props.langCode}` , { lng: props.langCode })}
        </p>
    );
};

const LanguageHeader = () => {
    return (
        <span style={{ padding: '5px' }}>
            {i18n.language.toUpperCase()}
        </span>
    );
};

export class LanguagePicker extends React.Component {
    render() {
        return (
            <DropDown header={LanguageHeader()}>
                {_.map(i18n.options.resources || {}, (res, langCode) =>
                    <LanguageChoice key={langCode} langCode={langCode} />
                )}
            </DropDown>
        );
    }
}