import * as React from 'react';
import { PaperPlaneIconSvg } from '../SvgImports';

const BookingsIcon = (props: {title?: string, fill?: string}) => {
    return (
        <img className="svgIcon bookingsIcon" src={PaperPlaneIconSvg} alt="Bookings icon" title={props.title} />
    );
};

export default BookingsIcon;
