import { LOAD_SCHOOL, SCHOOL_LOAD_SUCCESS, SchoolAction, SCHOOL_LOAD_FAILURE } from '../actions/SchoolActions';
import { SchoolStoreState } from '../types';

const initialState = {
    loadSchoolId: '',
    loadError: null,
    loadedSchools: {}
} as SchoolStoreState;

export default (state: SchoolStoreState = initialState, action: SchoolAction): SchoolStoreState => {
    switch (action.type) {
        case LOAD_SCHOOL:
            return { ...state, loadSchoolId: action.value };
        case SCHOOL_LOAD_SUCCESS:
            return { 
                ...state, 
                loadedSchools: {
                    ...state.loadedSchools,
                    [action.value.id]: action.value,
                },
                loadError: null
            };
        case SCHOOL_LOAD_FAILURE:
            return { ...state, loadError: action.value };
        default:
            return state;
    }
};
