import * as React from 'react';
import { Link } from 'react-router-dom';
import './PolicyPage.css';

export const PolicyPage = ({ title, children }: { title: string, children?: any }) => {
    return (
        <div className="pageBody flex-col">
            <div className="flex-row flex-center">
                <Link style={{ padding: '10px ' }} to="/policy/terms">Terms of Service</Link>
                <Link style={{ padding: '10px ' }} to="/policy/privacy">Privacy Policy</Link>
                <Link style={{ padding: '10px ' }} to="/policy/cookies">Cookie Policy</Link>
            </div>
            <div className="flex-row flex-center">
                <h2>{title}</h2>
            </div>
            <div className="policyPageBody flex-col">
            {
                children
            }
            </div>
        </div>
    );
};