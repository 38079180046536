import _ from 'lodash';
import { SPOT_FORM_FIELD_CHANGED, SchoolSpotAction, SCHOOL_SPOT_UPDATED } from '../actions/SchoolSpotActions';
import { SchoolSpotsStoreState } from '../types';
import { FIRESTORE_REMOVED } from '../constants';

const initialState = {
    newSpotForm: {
        id: '',
        name: '',
        description: '',
        lat: 0,
        lng: 0,
        amenities: []
    },
    spots: {}
} as SchoolSpotsStoreState;

export default (state: SchoolSpotsStoreState = initialState, action: SchoolSpotAction): SchoolSpotsStoreState => {
    switch (action.type) {
        case SPOT_FORM_FIELD_CHANGED:
            return { ...state, newSpotForm: { ...state.newSpotForm, [action.value.field]: action.value.value } };
        case SCHOOL_SPOT_UPDATED:
            if (action.value.change === FIRESTORE_REMOVED) {
                return {
                    ...state,
                    spots: {
                        ...state.spots,
                        [action.value.data.schoolId]: _.omit(state.spots[action.value.data.schoolId], action.value.data.id)
                    }
                };
            } else {
                if (!action.value.data) {
                    return state;
                }
                return {
                    ...state,
                    spots: {
                        ...state.spots,
                        [action.value.data.schoolId]: {
                            ...state.spots[action.value.data.schoolId],
                            [action.value.data.id]: action.value.data
                        }
                    }
                };
            }
        default:
            return state;
    }
};