// this is .js to get around ts module loader error
export { default as PoweredByStripeLogo } from '../../images/svg/powered_by_stripe.svg'
export { default as UserIconSvg } from '../../images/svg/user.svg'
export { default as MessageIconSvg } from '../../images/svg/message.svg'
export { default as WhatsAppIconSvg } from '../../images/svg/whatsapp.svg'
export { default as LocationIconSvg } from '../../images/svg/placeholder.svg'
export { default as PaperPlaneIconSvg } from '../../images/svg/paper-plane.svg'
export { default as PaperPlaneBlackIconSvg } from '../../images/svg/paper-plane-black.svg'
export { default as RobotoIconSvg } from '../../images/svg/roboto-logo-white.svg'
export { default as CameraIconSvg } from '../../images/svg/photo-camera.svg'
export { default as InternetIconSvg } from '../../images/svg/internet.svg'
export { default as PhoneCallIconSvg } from '../../images/svg/phone-call.svg'
export { default as TripAdvisorIconSvg } from '../../images/svg/tripadvisor-logotype.svg'
export { default as BackIconSvg } from '../../images/svg/back.svg'
export { default as CopyIconSvg } from '../../images/svg/copy.svg'
export { default as LanguageIconSvg } from '../../images/svg/languages.svg'
export { default as SunIconSvg } from '../../images/svg/sun.svg'
export { default as CheckedIconSvg } from '../../images/svg/checked.svg'
export { default as SendIconSvg } from '../../images/svg/send.svg'
export { default as EditIconSvg } from '../../images/svg/edit.svg'
export { default as UncheckedIconSvg } from '../../images/svg/unchecked.svg'
export { default as LikeIconSvg } from '../../images/svg/like.svg'
export { default as UndoIconSvg } from '../../images/svg/undo.svg'
export { default as ShareIconSvg } from '../../images/svg/share.svg'
export { default as YouTubeIconSvg } from '../../images/svg/youtube.svg'
export { default as InstagramIconSvg } from '../../images/svg/instagram.svg'
export { default as CalendarIconSvg } from '../../images/svg/calendar.svg'
export { default as SuitcaseIconSvg } from '../../images/svg/suitcase.svg'
export { default as LayoutIconSvg } from '../../images/svg/layout.svg'
export { default as FlashIconSvg } from '../../images/svg/flash.svg'

// amenities icons
export { default as SeatingIconSvg } from '../../images/svg/picnic-table.svg'
export { default as WheelchairIconSvg } from '../../images/svg/wheelchair-access.svg'
export { default as ParkingIconSvg } from '../../images/svg/parking.svg'
export { default as PublicTransportIconSvg } from '../../images/svg/bus-stop.svg'
export { default as ToiletsIconSvg } from '../../images/svg/toilets.svg'
export { default as ShowerIconSvg } from '../../images/svg/shower.svg'
export { default as FoodIconSvg } from '../../images/svg/fork.svg'
export { default as DrinksIconSvg } from '../../images/svg/cup.svg'
export { default as FamilyIconSvg } from '../../images/svg/family.svg'
export { default as PetsIconSvg } from '../../images/svg/pets.svg'
export { default as ShadeIconSvg } from '../../images/svg/umbrella.svg'
export { default as AccommodationIconSvg } from '../../images/svg/bed.svg'
export { default as CampingIconSvg } from '../../images/svg/tent.svg'
