import { AnalyticsStoreState } from '../types';
import { COOKIE_POLICY_ACCEPTED, AnalyticsAction, GOOGLE_ANALYTICS_LOADED } from '../actions/AnalyticsActions';

const initialState: AnalyticsStoreState = {
    cookiesAccepted: false,
    gaLoaded: false
};

export default (state: AnalyticsStoreState = initialState, action: AnalyticsAction): AnalyticsStoreState => {
    switch (action.type) {
        case COOKIE_POLICY_ACCEPTED:
            return { ...state, cookiesAccepted: true };
        case GOOGLE_ANALYTICS_LOADED:
            return { ...state, gaLoaded: true };
        default:
            return state;
    }
};
