import * as React from 'react';
import { SearchSchoolSpotDocument } from '../../types';

const MapLocationSchoolSpot = ({ size, highlighted, spot, lat, lng }: { size: number, highlighted: boolean, spot: SearchSchoolSpotDocument, lat: number, lng: number }) => {
    const style = { 
        height: `${size}px`, 
        width: `${size}px`, 
        left: `-${size / 2}px`,
        top: `-${size / 2}px`,
        display: 'inline-block',
        background: highlighted ? 'white' : 'black',
        border: '1px solid black',
        borderRadius: '50%',
        boxShadow: '1px 2px 3px rgba(0, 0, 0, 0.06)' 
    };

    return (
        <div className="mapLocationSpot" style={style} />
    );
};

export default MapLocationSchoolSpot;